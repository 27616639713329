import "date-fns";
import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Button,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TableContainer,
} from "@material-ui/core";
import Style from "../style.module.scss";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MuiTableCell: {
        root: {
          border: "1px solid #333",
          borderBottom: "1px solid #333",
          verticalAlign: "baseline",
          height: "100%",
          padding: "5px",
        },
        // body:{
        //   maxWidth: '600px',
        // },
      },

      MuiToolbar: {
        height: "40px",
      },
    },
  });
const StoreReport = props => {
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState(props.startDate);
  const [endDate, setEndDate] = useState(props.endDate);
  const [smallScreen, setSmallScreen] = useState(window.innerWidth <= 760);
  let columns = [];
    columns = [
      {
        name: "masdasdat",
        label: "Material Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "masadt",
        label: "Values",
        options: {
          filter: true,
          sort: true,
        },
      },
    ];


  useEffect(() => {
    const tempData = [];
 
    
      props.newWbs.forEach((elem, id) => {
        let row = [];
        console.log(props.newWbs)
        row.push(
          elem.material,
          <div>
            <MuiThemeProvider theme={getMuiTheme()}>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Opening</TableCell>
                      <TableCell>Purchase</TableCell>
                      <TableCell>Issued</TableCell>
                      <TableCell>Balance</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                   
                  {elem.activities.map(val => (
                       <TableRow
                       sx={{
                         "&:last-child td, &:last-child th": { border: 0 },
                       }}
                     >
                      <TableCell>{val.date}</TableCell>
                      <TableCell>{val.quantity1}</TableCell>
                      <TableCell>{val.quantity2}</TableCell>
                      <TableCell>{val.quantity3}</TableCell>
                      <TableCell>{val.quantity4}</TableCell>
                      
                    </TableRow>
              
                    ))}
                
                        
   
                      
                  </TableBody>
                </Table>
              </TableContainer>
            </MuiThemeProvider>
          </div>
        );


      tempData.push(row);
    });

    setData(tempData);
  }, []);

  return (
    <>
      <div className="container" id="download-section">
        <Grid container className={Style.header___top}>
          <Typography
            variant="h4"
            color="textSecondary"
            style={{ marginLeft: "0.5em", color: "#fff" }}
          >
            Enbuild
          </Typography>
        </Grid>
        <Grid container className={Style.container___info}>
          <Grid item xs={3}>
            <Typography
              variant="h6"
              color="textSecondary"
              style={{ marginLeft: "0.5em" }}
            >
              Generated By
            </Typography>
            <Typography variant="h6" style={{ marginLeft: "0.5em" }}>
              {global.config.secureStorage.getItem("user_fullname")}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography
              variant="h6"
              color="textSecondary"
              style={{ marginLeft: "0.5em" }}
            >
              Generated On
            </Typography>
            <Typography variant="h6" style={{ marginLeft: "0.5em" }}>
              {new Date(new Date()).toISOString().split("T")[0]}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="h6"
              color="textSecondary"
              style={{ marginLeft: "0.5em" }}
            >
              Start Date
            </Typography>
            <Typography variant="h6" style={{ marginLeft: "0.5em" }}>
              {startDate}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="h6"
              color="textSecondary"
              style={{ marginLeft: "0.5em" }}
            >
              End Date
            </Typography>
            <Typography variant="h6" style={{ marginLeft: "0.5em" }}>
              {endDate}
            </Typography>
          </Grid>
        </Grid>

        <Grid container className={Style.container___info}>
          <Grid item xs={12}>
            <Typography variant="h6" style={{ marginLeft: "0.5em" }}>
              Store Report
            </Typography>
            <Typography
              variant="p"
              color="textSecondary"
              style={{ marginLeft: "0.5em" }}
            >
              {/* This Report is from {props.startDate} to {props.endDate} */}
            </Typography>
          </Grid>
        </Grid>
        <hr />
        <div className={Style.table___box}>
          {props.newWbs !== undefined && (
            <MUIDataTable
              title={
                props.ReportFormat == "Labours Wise"
                  ? `Labour wise supplier report`
                  : "Supplier wise Labour report"
              }
              data={data}
              columns={columns}
              options={{
                selectableRows: "none",
                responsive: smallScreen ? "scroll" : "simple",
                pagination: false,
                download: false,
                viewColumns: false,
                filter: false,
                print: false,
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default StoreReport;
