import React, { Component } from "react";
import {
  Button,
  CardHeader,
  Typography,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import Loading from "../../Components/Loading";
import Material from "./Material";
import AddMaterial from "./AddMaterial";
import ModifyMaterial from "./ModifyMaterial";
import ViewDetails from "./ViewDetails";
// import { blue } from "@material-ui/core/colors";
import { Add, Check as Check,ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import Style from "./style.module.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  addMaterial,
  getMaterial,
  updateMaterial,
  deleteMaterial,
  addApproval,
  getStore,
  getMaterialBySuperProj
} from "../../Redux/materialRedux/materialAction";
import Notifications from "react-notification-system-redux";
import FileBase64 from "react-file-base64";
const { v4: uuidv4 } = require("uuid");
// import Typography from "material-ui/styles/typography";
// import { each } from "lodash";

class Display extends Component {
  constructor(props) {
    super(props);
    const role = global.config.secureStorage.getItem("role");
    const editRoles = ["purchase_manager", "project_manager", "super_admin"];
    const isEditable = editRoles.find(item => item == role) ? true : false;
    this.state = {
      materialReqName: "",
      materialName: "",
      expectedDate: "",
      expectedDateVal: "",
      editMaterialId: "",
      allMaterials: [],
      storeData: [],
      isCreateNewMaterial: false,
      isModifyMaterial: false,
      isEditable: isEditable,
      openModel: false,
      approvalMaterialId: "",
      approvalFile: "",
      viewDetails: false,
      viewId: "",
      quantityData: [],
    };
  }
  async componentDidMount() {
    let superProject=global.config.secureStorage.getItem("superProject")
    await this.props.getMaterialBySuperProj();
    await this.props.getStore();
    if (!this.props.loading) {
      this.setState({
        allMaterials: this.props.material.data,
        storeData: this.props.material.storeData,
      });
    }
  }
  handleChangeName = event => {
    this.setState({
      materialReqName: event.target.value,
    });
  };
  handleExpectedDate = date => {
    let newDate =
      date.getDate().toString().padStart(2, "0") +
      "-" +
      (parseInt(date.getMonth()) + 1).toString().padStart(2, "0") +
      "-" +
      date.getFullYear();
    this.setState({
      expectedDate: newDate,
      expectedDateVal: date,
    });
  };

  handleFile = async file => {
    this.setState({
      approvalFile: file,
    });
  };

  toggleViewDetails = id => {
    let mat = {};
    this.props.data.map((eachMaterial, index) => {
      if (eachMaterial.materialID === id) mat = eachMaterial;
    });

    this.setState({
      viewDetails: !this.state.viewDetails,
      viewId: mat,
    });
  };
  toggleApprovalModel = id => {
    this.setState({
      openModel: !this.state.openModel,
      approvalMaterialId: id,
      quantityData: [],
    });
  };

  handleApproval = id => {
    console.log(this.state.quantityData);
    if (this.state.approvalFile === undefined) {
      alert("How about putting a image Dude ? yes why not Dude");
      return;
    }
    const newMaterials = this.props.data.map(eachMaterial => {
      if (eachMaterial.materialID === id) {
        let change = {};
        let appDate =
          new Date().getDate().toString().padStart(2, "0") +
          "-" +
          (parseInt(new Date().getMonth()) + 1).toString().padStart(2, "0") +
          "-" +
          new Date().getFullYear();
        if (eachMaterial.approvalHistory) {
          change = {
            ...eachMaterial,
            approvalHistory: [
              ...eachMaterial.approvalHistory,
              {
                approvalFile: this.state.approvalFile,
                quantityData: this.state.quantityData,
                stage: eachMaterial.stage,
                approvalDate: appDate,
              },
            ],
            stage: eachMaterial.stage + 1,
          };
        } else {
          change = {
            ...eachMaterial,
            approvalHistory: [
              {
                approvalFile: this.state.approvalFile,
                quantityData: this.state.quantityData,
                stage: eachMaterial.stage,
                approvalDate: appDate,
              },
            ],
            stage: eachMaterial.stage + 1,
          };
        }

        console.log("change", change);
        this.props.addApproval(id, change);
        return change;
      }
      return eachMaterial;
    });
    this.setState({
      allMaterials: newMaterials,
      approvalFile: "",
      openModel: false,
      quantityData: [],
    });
  };
  toggleCreateNewMaterial = () => {
    this.setState({
      materialReqName: "",
      expectedDate: "",
      // allMaterials: newMaterials,
      isCreateNewMaterial: !this.state.isCreateNewMaterial,
    });
  };
  toggleModifyMaterialComponent = () => {
    this.setState({
      isModifyMaterial: !this.state.isModifyMaterial,
    });
  };
  editMaterial = id => {
    this.setState({
      editMaterialId: id,
    });
    const Material = this.state.allMaterials.find(Material => {
      return Material.materialID === id;
    });
    this.setState({
      materialName: Material.materialName,
      materialQuantity: Material.materialQuantity,
      materialLimit: Material.materialLimit,
      isModifyMaterial: !this.state.isModifyMaterial,
    });
  };

  removeMaterial = id => {
    const requiredMaterial = this.state.allMaterials.find(eachMaterial => {
      return eachMaterial.materialID === id;
    });
    this.props.deleteMaterial(requiredMaterial._id);
    const newMaterials = this.state.allMaterials.filter(
      eachMaterial => eachMaterial.materialID !== id
    );
    this.setState({
      allMaterials: newMaterials,
    });
  };

  updateMaterial = event => {
    event.preventDefault();
    const newMaterials = this.state.allMaterials.map(eachMaterial => {
      if (eachMaterial.materialID === this.state.editMaterialId) {
        const change = {
          ...eachMaterial,
          materialName: this.state.materialName || eachMaterial.materialName,
          materialQuantity:
            this.state.materialQuantity || eachMaterial.materialQuantity,
          materialLimit: this.state.materialLimit || eachMaterial.materialLimit,
        };
        this.props.updateMaterial(eachMaterial.materialID, change);
        return change;
      }
      return eachMaterial;
    });
    this.setState({
      allMaterials: newMaterials,
      isModifyMaterial: !this.state.isModifyMaterial,
    });
  };
  handleRemark = async (remark, id) => {
    //
    const newMaterials = this.state.allMaterials.map(eachMaterial => {
      if (eachMaterial.materialID === id) {
        const change = {
          ...eachMaterial,
          remark: remark,
        };
        this.props.updateMaterial(eachMaterial.materialID, change);
        return change;
      }
      return eachMaterial;
    });
    //
    this.setState({
      allMaterials: newMaterials,
    });
  };
  saveMaterial = materialReq => {
    if (
      this.state.expectedDate === undefined ||
      this.state.materialReqName === undefined
    ) {
      alert("Please fill all fields");
      return;
    }
    let id = this.props.data.length + 1;
    id = ("0" + id).slice(-2);
    const finalMaterial = {};
    finalMaterial.data = materialReq;
    finalMaterial.materialReqName = this.state.materialReqName;
    finalMaterial.materialID = "MR" + id;
    finalMaterial.materialReqDate =
      new Date().getDate().toString().padStart(2, "0") +
      "-" +
      (parseInt(new Date().getMonth()) + 1).toString().padStart(2, "0") +
      "-" +
      new Date().getFullYear();
    finalMaterial.expectedDate = this.state.expectedDate;
    finalMaterial.stage = 0;
    let superProject=global.config.secureStorage.getItem("superProject")
    finalMaterial.superProject=superProject
    // let newMaterials = [...this.state.allMaterials, finalMaterial];

    this.props.addMaterial(finalMaterial);
    this.setState({
      materialReqName: "",
      expectedDate: "",
      // allMaterials: newMaterials,
      isCreateNewMaterial: !this.state.isCreateNewMaterial,
    });
    window.location.reload();
  };

  stages = [
    {
      mName: "Requision Approval",
      quantity: true,
      approval: true,
      upload: true,
    },
    {
      mName: "PO Generation",
      quantity: true,
      approval: true,
      upload: true,
    },
    {
      mName: "Received Requision",
      quantity: true,
      approval: true,
      upload: true,
    },
    {
      mName: "Delivered Requision",
      quantity: false,
      approval: false,
      upload: false,
    },
  ];

  showQuantity = () => {
    console.log("this.props.data", this.props.data);
    // let getCurrentMaterial = this.props.data ? this.props.data.filter(item => {
    //       return item.materialID === this.state.approvalMaterialId;
    //     })[0] : [];
    const { data } = this.props;
    if (!Array.isArray(data)) {
      console.error("Data is not an array:", data);
      return null; // or appropriate rendering for data not being an array
    }
    const getCurrentMaterial = data.find(item => item.materialID === this.state.approvalMaterialId);
    if (!getCurrentMaterial) {
      console.error("No material found with the specified ID:", this.state.approvalMaterialId);
      return null; // or appropriate rendering when material is not found
    }
    if (
      getCurrentMaterial !== undefined &&
      this.stages[getCurrentMaterial.stage].quantity
    ) {
      let quantityData = [...this.state.quantityData];
      return (
        <div style={{ marginBottom: "30px" }}>
          <Typography variant="h5" style={{ marginBottom: "20px" }}>
            Material {this.stages[getCurrentMaterial.stage].mName} Quantity:
          </Typography>
          {getCurrentMaterial.data.map((eachMaterial, index) => {
            return (
              <Grid container>
                <Grid xs={6}>
                  <Typography variant="h5">
                    {eachMaterial.materialName}
                  </Typography>
                </Grid>
                <Grid xs={6}>
                  <TextField
                    name="materialQuantity"
                    id="materialQuantity"
                    margin="dense"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    onChange={event => {
                      quantityData[index] = {
                        materialName: eachMaterial.materialName,
                        quantity: event.target.value,
                      };

                      this.setState({
                        quantityData: quantityData,
                      });
                    }}
                    label="Quantity"
                    type="number"
                    required
                    fullWidth
                  />
                </Grid>
              </Grid>
            );
          })}
        </div>
      );
    } else {
      return "";
    }
  };
  render() {
    const { isCreateNewMaterial, isModifyMaterial, viewDetails } = this.state;
    if (isCreateNewMaterial) {
      return (
        <AddMaterial
          materialReqName={this.state.materialReqName}
          expectedDate={this.state.expectedDateVal}
          storeData={this.props.storeData}
          handleExpectedDate={this.handleExpectedDate}
          handleChangeName={this.handleChangeName}
          saveMaterial={this.saveMaterial}
          toggleCreateNewMaterial={this.toggleCreateNewMaterial}
        />
      );
    } else if (isModifyMaterial) {
      return (
        <ModifyMaterial
          materialName={this.state.materialName}
          materialQuantity={this.state.materialQuantity}
          materialLimit={this.state.materialLimit}
          updateMaterial={this.updateMaterial}
          handleChange={this.handleChange}
          toggleModifyMaterialComponent={this.toggleModifyMaterialComponent}
        />
      );
    } else if (viewDetails) {
      return (
        <ViewDetails
          materialDetails={this.state.viewId}
          toggleViewDetails={this.toggleViewDetails}
          stages={this.stages}
        />
      );
    }

    return (
      <div>
        <Dialog
          open={this.state.openModel}
          fullScreen={false}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Upload File</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div className="container" style={{ marginTop: "20px" }}>
                <Grid item xs={12}>
                  {this.showQuantity()}
                </Grid>
                <Grid item xs={12}>
                  <FileBase64
                    multiple={false}
                    className="btn"
                    value={this.state.approvalFile}
                    onDone={file => {
                      // files.forEach(ele => ele.base64);
                      this.handleFile(file.base64);
                    }}
                  />
                </Grid>
              </div>
            </DialogContentText>
            <DialogActions style={{ justifyContent: "center" }}>
              <Button
                color="secondary"
                onClick={() => this.toggleApprovalModel("")}
              >
                Close
              </Button>
              <Button
                startIcon={<Check />}
                onClick={() =>
                  this.handleApproval(this.state.approvalMaterialId)
                }
                color="primary"
              >
                Approve
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
        {this.props.notifications && (
          <Notifications notifications={this.props.notifications} />
        )}
        {this.state.isEditable && (
          <section className={Style.button__display}>
            <Button
              onClick={this.toggleCreateNewMaterial}
              startIcon={<Add />}
              size="large"
            >
              Add Material
            </Button>
          </section>
        )}
        {this.props.loading ? (
          <Loading loading={this.props.loading} />
        ) : !this.props.loading && !this.props.data.length ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100vw",
              height: "80vh",
            }}
          >
            <Typography
              variant="h4"
              color="textSecondary"
              style={{ marginLeft: "0.5em", textAlign: "center" }}
            >
              No Materials Found!
            </Typography>
          </div>
        ) : (
          <>
            {this.stages.map((eachStage, i) => {
              return (
                <div>
                  <Accordion style={{ width: "90%", margin: "20px auto" }}>
                    <AccordionSummary
                     expandIcon={
                      <ExpandMoreIcon
                        fontSize="large"
                        color="secondary"
                      />
                    }
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      style={{
                        paddingTop: 10,
                        paddingBottom: 10,
                      }}
                    >
                      <Grid
                        container
                        spacing={0}
                        alignItems="center"
                        justify="space-between"
                      >
                        <Typography variant="h6">{eachStage.mName}</Typography>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <section className={Style.all__material}>
                        {/* {this.props.data.filter(item => item.stage === i).length === 0 ? (
                          <Typography
                            variant="h5"
                            color="textSecondary"
                            style={{ marginLeft: "0.5em" }}
                          >
                            No Pending Orders available
                          </Typography>
                        ) : (
                          <>
                            <Grid container xs={12} spacing={2} className="ss">
                              {this.props.data.map((eachMaterial, index) => {
                                return eachMaterial.stage === i ? (
                                  <Grid item sm={4} key={index}>
                                    <Material
                                      approval={eachStage.approval}
                                      isEditable={this.state.isEditable}
                                      materialReqName={
                                        eachMaterial.materialReqName
                                      }
                                      materialReqDate={
                                        eachMaterial.materialReqDate
                                      }
                                      id={eachMaterial.materialID}
                                      materialRemark={eachMaterial.remark}
                                      showActions={true}
                                      expectedDate={eachMaterial.expectedDate}
                                      editMaterial={this.editMaterial}
                                      deleteMaterial={this.removeMaterial}
                                      addRemark={this.handleRemark}
                                      toggleApprovalModel={
                                        eachStage.upload
                                          ? this.toggleApprovalModel
                                          : this.handleApproval
                                      }
                                      toggleViewDetails={this.toggleViewDetails}
                                    />
                                  </Grid>
                                ) : (
                                  <div key={index}></div>
                                );
                              })}
                            </Grid>
                          </>
                        )} */}
                        {Array.isArray(this.props.data) ?
                          this.props.data.filter(item => item.stage === i).length === 0 ?
                              <Typography
                                variant="h5"
                                color="textSecondary"
                                style={{ marginLeft: "0.5em" }}
                              >
                                No Pending Orders available
                              </Typography>
                          :
                              <Grid container xs={12} spacing={2} className="ss">
                                {this.props.data.map((eachMaterial, index) => {
                                  return eachMaterial.stage === i ? (
                                    <Grid item sm={4} key={index}>
                                      <Material
                                        approval={eachStage.approval}
                                        isEditable={this.state.isEditable}
                                        materialReqName={
                                          eachMaterial.materialReqName
                                        }
                                        materialReqDate={
                                          eachMaterial.materialReqDate
                                        }
                                        id={eachMaterial.materialID}
                                        materialRemark={eachMaterial.remark}
                                        showActions={true}
                                        expectedDate={eachMaterial.expectedDate}
                                        editMaterial={this.editMaterial}
                                        deleteMaterial={this.removeMaterial}
                                        addRemark={this.handleRemark}
                                        toggleApprovalModel={
                                          eachStage.upload
                                            ? this.toggleApprovalModel
                                            : this.handleApproval
                                        }
                                        toggleViewDetails={this.toggleViewDetails}
                                      />
                                    </Grid>
                                  ) : (
                                    <div key={index}></div>
                                  );
                                })}
                              </Grid>
                        :
                            <Typography
                              variant="h5"
                              color="textSecondary"
                              style={{ marginLeft: "0.5em" }}
                            >
                              Data is not an array.
                            </Typography>
                        }
                      </section>
                      <div className={Style.material__container_2}>
                        <Divider />
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              );
            })}
          </>
        )}
      </div>
    );
  }
}

Display.propTypes = {
  material: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  //
  return {
    material: state.materialReducer,
    notifications: state.notifications,
    success: state.materialReducer.success,
    loading: state.materialReducer.loading,
    data: state.materialReducer.data,
    storeData: state.materialReducer.storeData,
  };
};

export default connect(mapStateToProps, {
  addMaterial,
  getMaterial,
  updateMaterial,
  deleteMaterial,
  addApproval,
  getMaterialBySuperProj,
  getStore,
})(Display);

// import React, { Component } from "react";
// import {
//   Button,
//   CardHeader,
//   Typography,
//   Grid,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
//   Divider,
//   TextField,
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
// } from "@material-ui/core";
// import Loading from "../../Components/Loading";
// import Material from "./Material";
// import AddMaterial from "./AddMaterial";
// import ModifyMaterial from "./ModifyMaterial";
// import ViewDetails from "./ViewDetails";
// // import { blue } from "@material-ui/core/colors";
// import { Add, Check as Check,ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
// import Style from "./style.module.scss";
// import PropTypes from "prop-types";
// import { connect } from "react-redux";
// import {
//   addMaterial,
//   getMaterial,
//   updateMaterial,
//   deleteMaterial,
//   addApproval,
//   getStore,
//   getMaterialBySuperProj
// } from "../../Redux/materialRedux/materialAction";
// import Notifications from "react-notification-system-redux";
// import FileBase64 from "react-file-base64";
// const { v4: uuidv4 } = require("uuid");
// // import Typography from "material-ui/styles/typography";
// // import { each } from "lodash";

// class Display extends Component {
//   constructor(props) {
//     super(props);
//     const role = global.config.secureStorage.getItem("role");
//     const editRoles = ["purchase_manager", "project_manager", "super_admin"];
//     const isEditable = editRoles.find(item => item == role) ? true : false;
//     this.state = {
//       materialReqName: "",
//       expectedDate: "",
//       expectedDateVal: "",
//       editMaterialId: "",
//       allMaterials: [],
//       storeData: [],
//       isCreateNewMaterial: false,
//       isModifyMaterial: false,
//       isEditable: isEditable,
//       openModel: false,
//       approvalMaterialId: "",
//       approvalFile: "",
//       viewDetails: false,
//       viewId: "",
//       quantityData: [],
//     };
//   }
//   async componentDidMount() {
//     let superProject=global.config.secureStorage.getItem("superProject")
//     await this.props.getMaterialBySuperProj();
//     await this.props.getStore();
//     if (!this.props.loading) {
//       this.setState({
//         allMaterials: this.props.material.data,
//         storeData: this.props.material.storeData,
//       });
//     }
//   }
//   handleChangeName = event => {
//     this.setState({
//       materialReqName: event.target.value,
//     });
//   };
//   handleExpectedDate = date => {
//     let newDate =
//       date.getDate().toString().padStart(2, "0") +
//       "-" +
//       (parseInt(date.getMonth()) + 1).toString().padStart(2, "0") +
//       "-" +
//       date.getFullYear();
//     this.setState({
//       expectedDate: newDate,
//       expectedDateVal: date,
//     });
//   };

//   handleFile = async file => {
//     this.setState({
//       approvalFile: file,
//     });
//   };

//   toggleViewDetails = id => {
//     let mat = {};
//     this.props.data.map((eachMaterial, index) => {
//       if (eachMaterial.materialID === id) mat = eachMaterial;
//     });

//     this.setState({
//       viewDetails: !this.state.viewDetails,
//       viewId: mat,
//     });
//   };
//   toggleApprovalModel = id => {
//     this.setState({
//       openModel: !this.state.openModel,
//       approvalMaterialId: id,
//       quantityData: [],
//     });
//   };

//   handleApproval = id => {
//     console.log(this.state.quantityData);
//     if (this.state.approvalFile === undefined) {
//       alert("How about putting a image Dude ? yes why not Dude");
//       return;
//     }
//     const newMaterials = this.props.data.map(eachMaterial => {
//       if (eachMaterial.materialID === id) {
//         let change = {};
//         let appDate =
//           new Date().getDate().toString().padStart(2, "0") +
//           "-" +
//           (parseInt(new Date().getMonth()) + 1).toString().padStart(2, "0") +
//           "-" +
//           new Date().getFullYear();
//         if (eachMaterial.approvalHistory) {
//           change = {
//             ...eachMaterial,
//             approvalHistory: [
//               ...eachMaterial.approvalHistory,
//               {
//                 approvalFile: this.state.approvalFile,
//                 quantityData: this.state.quantityData,
//                 stage: eachMaterial.stage,
//                 approvalDate: appDate,
//               },
//             ],
//             stage: eachMaterial.stage + 1,
//           };
//         } else {
//           change = {
//             ...eachMaterial,
//             approvalHistory: [
//               {
//                 approvalFile: this.state.approvalFile,
//                 quantityData: this.state.quantityData,
//                 stage: eachMaterial.stage,
//                 approvalDate: appDate,
//               },
//             ],
//             stage: eachMaterial.stage + 1,
//           };
//         }

//         this.props.addApproval(id, change);
//         return change;
//       }
//       return eachMaterial;
//     });
//     this.setState({
//       allMaterials: newMaterials,
//       approvalFile: "",
//       openModel: false,
//       quantityData: [],
//     });
//   };
//   toggleCreateNewMaterial = () => {
//     this.setState({
//       materialReqName: "",
//       expectedDate: "",
//       // allMaterials: newMaterials,
//       isCreateNewMaterial: !this.state.isCreateNewMaterial,
//     });
//   };
//   toggleModifyMaterialComponent = () => {
//     this.setState({
//       isModifyMaterial: !this.state.isModifyMaterial,
//     });
//   };
//   editMaterial = id => {
//     this.setState({
//       editMaterialId: id,
//     });
//     const Material = this.state.allMaterials.find(Material => {
//       return Material.materialID === id;
//     });
//     this.setState({
//       materialName: Material.materialName,
//       materialQuantity: Material.materialQuantity,
//       materialLimit: Material.materialLimit,
//       isModifyMaterial: !this.state.isModifyMaterial,
//     });
//   };

//   removeMaterial = id => {
//     const requiredMaterial = this.state.allMaterials.find(eachMaterial => {
//       return eachMaterial.materialID === id;
//     });
//     this.props.deleteMaterial(requiredMaterial._id);
//     const newMaterials = this.state.allMaterials.filter(
//       eachMaterial => eachMaterial.materialID !== id
//     );
//     this.setState({
//       allMaterials: newMaterials,
//     });
//   };

//   updateMaterial = event => {
//     event.preventDefault();
//     const newMaterials = this.state.allMaterials.map(eachMaterial => {
//       if (eachMaterial.materialID === this.state.editMaterialId) {
//         const change = {
//           ...eachMaterial,
//           materialName: this.state.materialName || eachMaterial.materialName,
//           materialQuantity:
//             this.state.materialQuantity || eachMaterial.materialQuantity,
//           materialLimit: this.state.materialLimit || eachMaterial.materialLimit,
//         };
//         this.props.updateMaterial(eachMaterial.materialID, change);
//         return change;
//       }
//       return eachMaterial;
//     });
//     this.setState({
//       allMaterials: newMaterials,
//       isModifyMaterial: !this.state.isModifyMaterial,
//     });
//   };
//   handleRemark = async (remark, id) => {
//     //
//     const newMaterials = this.state.allMaterials.map(eachMaterial => {
//       if (eachMaterial.materialID === id) {
//         const change = {
//           ...eachMaterial,
//           remark: remark,
//         };
//         this.props.updateMaterial(eachMaterial.materialID, change);
//         return change;
//       }
//       return eachMaterial;
//     });
//     //
//     this.setState({
//       allMaterials: newMaterials,
//     });
//   };
//   saveMaterial = materialReq => {
//     if (
//       this.state.expectedDate === undefined ||
//       this.state.materialReqName === undefined
//     ) {
//       alert("Please fill all fields");
//       return;
//     }
//     let id = this.props.data.length + 1;
//     id = ("0" + id).slice(-2);
//     const finalMaterial = {};
//     finalMaterial.data = materialReq;
//     finalMaterial.materialReqName = this.state.materialReqName;
//     finalMaterial.materialID = "MR" + id;
//     finalMaterial.materialReqDate =
//       new Date().getDate().toString().padStart(2, "0") +
//       "-" +
//       (parseInt(new Date().getMonth()) + 1).toString().padStart(2, "0") +
//       "-" +
//       new Date().getFullYear();
//     finalMaterial.expectedDate = this.state.expectedDate;
//     finalMaterial.stage = 0;
//     let superProject=global.config.secureStorage.getItem("superProject")
//     finalMaterial.superProject=superProject
//     // let newMaterials = [...this.state.allMaterials, finalMaterial];

//     this.props.addMaterial(finalMaterial);
//     this.setState({
//       materialReqName: "",
//       expectedDate: "",
//       // allMaterials: newMaterials,
//       isCreateNewMaterial: !this.state.isCreateNewMaterial,
//     });
//     window.location.reload();
//   };

//   stages = [
//     {
//       mName: "Requision Approval",
//       quantity: true,
//       approval: true,
//       upload: true,
//     },
//     {
//       mName: "PO Generation",
//       quantity: true,
//       approval: true,
//       upload: true,
//     },
//     {
//       mName: "Received Requision",
//       quantity: true,
//       approval: true,
//       upload: true,
//     },
//     {
//       mName: "Delivered Requision",
//       quantity: false,
//       approval: false,
//       upload: false,
//     },
//   ];

//   showQuantity = () => {
//     let getCurrentMaterial = this.props.data
//       ? this.props.data.filter(item => {
//           return item.materialID === this.state.approvalMaterialId;
//         })[0]
//       : [];
//     if (
//       getCurrentMaterial !== undefined &&
//       this.stages[getCurrentMaterial.stage].quantity
//     ) {
//       let quantityData = [...this.state.quantityData];
//       return (
//         <div style={{ marginBottom: "30px" }}>
//           <Typography variant="h5" style={{ marginBottom: "20px" }}>
//             Material {this.stages[getCurrentMaterial.stage].mName} Quantity:
//           </Typography>
//           {getCurrentMaterial.data.map((eachMaterial, index) => {
//             return (
//               <Grid container>
//                 <Grid xs={6}>
//                   <Typography variant="h5">
//                     {eachMaterial.materialName}
//                   </Typography>
//                 </Grid>
//                 <Grid xs={6}>
//                   <TextField
//                     name="materialQuantity"
//                     id="materialQuantity"
//                     margin="dense"
//                     variant="outlined"
//                     InputLabelProps={{ shrink: true }}
//                     onChange={event => {
//                       quantityData[index] = {
//                         materialName: eachMaterial.materialName,
//                         quantity: event.target.value,
//                       };

//                       this.setState({
//                         quantityData: quantityData,
//                       });
//                     }}
//                     label="Quantity"
//                     type="number"
//                     required
//                     fullWidth
//                   />
//                 </Grid>
//               </Grid>
//             );
//           })}
//         </div>
//       );
//     } else {
//       return "";
//     }
//   };
//   render() {
//     const { isCreateNewMaterial, isModifyMaterial, viewDetails } = this.state;
//     if (isCreateNewMaterial) {
//       return (
//         <AddMaterial
//           materialReqName={this.state.materialReqName}
//           expectedDate={this.state.expectedDateVal}
//           storeData={this.props.storeData}
//           handleExpectedDate={this.handleExpectedDate}
//           handleChangeName={this.handleChangeName}
//           saveMaterial={this.saveMaterial}
//           toggleCreateNewMaterial={this.toggleCreateNewMaterial}
//         />
//       );
//     } else if (isModifyMaterial) {
//       return (
//         <ModifyMaterial
//           materialName={this.state.materialName}
//           materialQuantity={this.state.materialQuantity}
//           materialLimit={this.state.materialLimit}
//           updateMaterial={this.updateMaterial}
//           handleChange={this.handleChange}
//           toggleModifyMaterialComponent={this.toggleModifyMaterialComponent}
//         />
//       );
//     } else if (viewDetails) {
//       return (
//         <ViewDetails
//           materialDetails={this.state.viewId}
//           toggleViewDetails={this.toggleViewDetails}
//           stages={this.stages}
//         />
//       );
//     }

//     return (
//       <div>
//         <Dialog
//           open={this.state.openModel}
//           fullScreen={false}
//           aria-labelledby="form-dialog-title"
//         >
//           <DialogTitle id="form-dialog-title">Upload File</DialogTitle>
//           <DialogContent>
//             <DialogContentText>
//               <div className="container" style={{ marginTop: "20px" }}>
//                 <Grid item xs={12}>
//                   {this.showQuantity()}
//                 </Grid>
//                 <Grid item xs={12}>
//                   <FileBase64
//                     multiple={false}
//                     className="btn"
//                     value={this.state.approvalFile}
//                     onDone={file => {
//                       // files.forEach(ele => ele.base64);
//                       this.handleFile(file.base64);
//                     }}
//                   />
//                 </Grid>
//               </div>
//             </DialogContentText>
//             <DialogActions style={{ justifyContent: "center" }}>
//               <Button
//                 color="secondary"
//                 onClick={() => this.toggleApprovalModel("")}
//               >
//                 Close
//               </Button>
//               <Button
//                 startIcon={<Check />}
//                 onClick={() =>
//                   this.handleApproval(this.state.approvalMaterialId)
//                 }
//                 color="primary"
//               >
//                 Approve
//               </Button>
//             </DialogActions>
//           </DialogContent>
//         </Dialog>
//         {this.props.notifications && (
//           <Notifications notifications={this.props.notifications} />
//         )}
//         {this.state.isEditable && (
//           <section className={Style.button__display}>
//             <Button
//               onClick={this.toggleCreateNewMaterial}
//               startIcon={<Add />}
//               size="large"
//             >
//               Add Material
//             </Button>
//           </section>
//         )}
//         {this.props.loading ? (
//           <Loading loading={this.props.loading} />
//         ) : !this.props.loading && !this.props.data.length ? (
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               width: "100vw",
//               height: "80vh",
//             }}
//           >
//             <Typography
//               variant="h4"
//               color="textSecondary"
//               style={{ marginLeft: "0.5em", textAlign: "center" }}
//             >
//               No Materials Found!
//             </Typography>
//           </div>
//         ) : (
//           <>
//             {this.stages.map((eachStage, i) => {
//               return (
//                 <div>
//                   <Accordion style={{ width: "90%", margin: "20px auto" }}>
//                     <AccordionSummary
//                      expandIcon={
//                       <ExpandMoreIcon
//                         fontSize="large"
//                         color="secondary"
//                       />
//                     }
//                       aria-controls="panel1d-content"
//                       id="panel1d-header"
//                       style={{
//                         paddingTop: 10,
//                         paddingBottom: 10,
//                       }}
//                     >
//                       <Grid
//                         container
//                         spacing={0}
//                         alignItems="center"
//                         justify="space-between"
//                       >
//                         <Typography variant="h6">{eachStage.mName}</Typography>
//                       </Grid>
//                     </AccordionSummary>
//                     <AccordionDetails>
//                       <section className={Style.all__material}>
//                         {this.props.data.filter(item => item.stage === i)
//                           .length === 0 ? (
//                           <Typography
//                             variant="h5"
//                             color="textSecondary"
//                             style={{ marginLeft: "0.5em" }}
//                           >
//                             No Pending Orders available
//                           </Typography>
//                         ) : (
//                           <>
//                             <Grid container xs={12} spacing={2} className="ss">
//                               {this.props.data.map((eachMaterial, index) => {
//                                 return eachMaterial.stage === i ? (
//                                   <Grid item sm={4} key={index}>
//                                     <Material
//                                       approval={eachStage.approval}
//                                       isEditable={this.state.isEditable}
//                                       materialReqName={
//                                         eachMaterial.materialReqName
//                                       }
//                                       materialReqDate={
//                                         eachMaterial.materialReqDate
//                                       }
//                                       id={eachMaterial.materialID}
//                                       materialRemark={eachMaterial.remark}
//                                       showActions={true}
//                                       expectedDate={eachMaterial.expectedDate}
//                                       editMaterial={this.editMaterial}
//                                       deleteMaterial={this.removeMaterial}
//                                       addRemark={this.handleRemark}
//                                       toggleApprovalModel={
//                                         eachStage.upload
//                                           ? this.toggleApprovalModel
//                                           : this.handleApproval
//                                       }
//                                       toggleViewDetails={this.toggleViewDetails}
//                                     />
//                                   </Grid>
//                                 ) : (
//                                   <div key={index}></div>
//                                 );
//                               })}
//                             </Grid>
//                           </>
//                         )}
//                       </section>
//                       <div className={Style.material__container_2}>
//                         <Divider />
//                       </div>
//                     </AccordionDetails>
//                   </Accordion>
//                 </div>
//               );
//             })}
//           </>
//         )}
//       </div>
//     );
//   }
// }

// Display.propTypes = {
//   material: PropTypes.object.isRequired,
// };

// const mapStateToProps = state => {
//   //
//   return {
//     material: state.materialReducer,
//     notifications: state.notifications,
//     success: state.materialReducer.success,
//     loading: state.materialReducer.loading,
//     data: state.materialReducer.data,
//     storeData: state.materialReducer.storeData,
//   };
// };

// export default connect(mapStateToProps, {
//   addMaterial,
//   getMaterial,
//   updateMaterial,
//   deleteMaterial,
//   addApproval,
//   getMaterialBySuperProj,
//   getStore,
// })(Display);