import React from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import _ from "lodash";
import { DeleteOutlined } from "@material-ui/icons";
import { AddCircleOutline } from "@material-ui/icons";
import Swal from 'sweetalert2';

const Step2 = props => {
  return (
    <div className="container mt-2" style={{ marginBottom: "1em" }}>
      {_.times(props.numberOfProjects, i => (
        <React.Fragment key={i}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="subProjectName"
                variant="outlined"
                required
                fullWidth
                value={
                  props.subProjectName.length !== 0
                    ? props.subProjectName[i]
                    : ""
                }
                onChange={event => props.handleSecondStep(event, i)}
                id="projectName"
                label="Subproject Name"
                error={Boolean(props.subProjectError)}
                helperText={props.subProjectError ? "please Enter Subproject Name!" : ""}
              />
            </Grid>
          </Grid>
          <div className="my-3">
            <Grid
              direction="row"
              justify="flex-end"
              alignItems="center"
              container
            >
              <div onClick={() => {

                  if(props.numberOfProjects === 1){
                    Swal.fire({
                      icon:"info",
                      text:"Assige atleast one Subproject"
                    })
                  }
                  else{
                    Swal.fire({
                      title: 'Are you sure?',
                      text: "You won't be able to revert this!",
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                      if (result.isConfirmed) {
                        Swal.fire(
                          'Deleted!',
                          'Your file has been deleted.',
                          'success'
                        )
                        props.deleteProject(i)
                      }
                    })
                  }
                } 
                }>
                {/* <Grid
                  item
                  className="mb-1"
                  style={{ color: "red", cursor: "pointer" }}
                >
                  <DeleteOutlined fontSize="default" />
                  <span>Delete Subproject</span>
                </Grid> */}
              </div>
              <div
                style={{
                  backgroundColor: "#D0D0D0",
                  height: "0.1px",
                  width: "100%",
                }}
              ></div>
            </Grid>
          </div>
        </React.Fragment>
      ))}
      {/* <div>
        <Grid item style={{ marginLeft: "2px" }}>
          <Button
            onClick={() => props.addNewProject()}
            variant="outlined"
            startIcon={<AddCircleOutline />}
          >
            Add New Subproject
          </Button>
        </Grid>
      </div> */}
    </div>
  );
};

export default Step2;
