import React from "react";
import {
  makeStyles,
  Accordion,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { Edit,Delete } from "@material-ui/icons";
import Swal from 'sweetalert2';

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function ActivityList(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {props.iterList && props.iterList.length !== 0 ? (
        props.iterList.map((data, index) => {
          return (
            <div style={{ display: "flex", boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)", borderRadius: "4px", marginBottom: "10px" }}>
              <Link
                style={{ textDecoration: "none", width: "100%" }}
                to={`/checklist/${props.path}/${data.id}`}
                key={index}
              >
                <Accordion className="my-2" style={{boxShadow: "none"}}>
                  <AccordionSummary
                    aria-controls={"panelContent" + index}
                    id={"panelHeader" + index}
                  >
                     <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                            flexGrow: 1,
                          }}
                        >
                    <Typography className={classes.heading}>
                      {data.title}
                    </Typography>
                    <Typography className={classes.heading}>
                      updated on: {data.updatedDate}
                    </Typography>
                    </div>
                    
                  </AccordionSummary>
                </Accordion>
              </Link>
                          <IconButton
                      aria-label="settings"
                      color="primary"
                      onClick={() => {
                        props.handleEditModalOpen(index);
                      }}
                      style={{
                        width: 45,
                      }}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      aria-label="settings"
                      color="secondary"
                      onClick={() => {
                        
                        Swal.fire({
                          title: 'Are you sure?',
                          text: "You won't be able to revert this!",
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonColor: '#3085d6',
                          cancelButtonColor: '#d33',
                          confirmButtonText: 'Yes, delete it!'
                        }).then((result) => {
                          if (result.isConfirmed) {
                            Swal.fire(
                              'Deleted!',
                              'Your file has been deleted.',
                              'success'
                              )
                            props.deleteCheckList(index);
                          }
                        });
                      }}
                      style={{
                        width: 45,
                      }}
                    >
                      <Delete />
                    </IconButton>
            </div>
          );
        })
      ) : (
        <Typography
          className="mt-5 d-flex justify-content-center align-items-center"
          variant="h4"
          color="textSecondary"
          style={{ textAlign: "center" }}
        >
          No Checklists Available !
        </Typography>
      )}
    </div>
  );
}
