import React, { Component, useEffect, useState } from "react";
import {
  Grid,
  Typography, InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Button,
  FormControl,
} from "@material-ui/core";
import { Group, CheckBox, FilterList, ErrorRounded, AccountCircle } from "@material-ui/icons";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

import Loading from "../../Components/Loading";
import BarchartData from "./Barchart";
import VerticleList from "./VerticleList";
import {
  getActivities,
} from "../../Redux/monitoringRedux/monitoringAction";
import { getDefaultActivities } from "../../Redux/activityRedux/activityAction";
import { getAllUpdatesDetails } from "../../Redux/overviewRedux/overviewAction";
import axios from "axios";
import { Link } from "react-router-dom";
import dprs from "../../image/dprs.png"
import qualityCheck from "../../image/quality-check.png"
import toDo from "../../image/to-do-list.png"
import saftyCheck from "../../image/checklist.png"
import actualTime from "../../image/actual-time.png"
import actualCost from "../../image/actual-cost.png"
import costSummary from "../../image/costsummary.png"
import progressGraph from "../../image/progressgraph.png"
import plannedActive from "../../image/PlannedActive.png"
import delayedActivity from "../../image/delayedactivity.png"
import ToDolist from "../../image/ToDoList.png"
import Todayreceive from "../../image/todayreceived.png"
import dailyMaterial from "../../image/dailyMaterial.png"
import johnFox from "../../image/johnFox.png"
import { BarChartHorizontal, BarChartVerticle, GroupChart, PVAC } from "./GroupBarChart";
import "./overview.css"
import { ApiGetMethod } from "../../Components/apiHelper/helper";
import TestPage from "../apitestpage";
// import constant from "../../Components/apiHelper/constant";


const columnsPlannedActivity = [
  {
    name: "name",
    label: "Name",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "activity",
    label: "Activity",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "taskCost",
    label: "Estimated Cost",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "taskEstimateStart",
    label: "Start Date",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        const date = new Date(value);
        const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
        return formattedDate;
      }
    },
  }
];

// const columnsDelayedActivity = [
//   {
//     name: "name",
//     label: "Task Name",
//     options: {
//       filter: true,
//       sort: false,
//     },
//   },
//   {
//     name: "activity",
//     label: "Activity",
//     options: {
//       filter: true,
//       sort: false,
//     },
//   },
//   {
//     name: "taskEstimateStart",
//     label: "Delayed",
//     options: {
//       filter: true,
//       sort: false,
//       customBodyRender: (value, tableMeta, updateValue) => {
//         const date = new Date(value);
//         const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
//         return formattedDate;
//       }
//     },
//   },
//   {
//     name: "status",
//     label: "Status",
//     options: {
//       filter: true,
//       sort: false,
//       display: true
//     },
//   },
// ];


const columnsDelayedActivity = [
  {
    name: "name",
    label: "Task Name",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        const status = tableMeta.rowData[3]; 
        let className = "";
        if (status == "In Progress") {
          className = "InProgress";
        }else if(status == "Completed"){
          className = "Completed"
        }else{
          className = "Pending"
        }
        return <div className={className}>{value}</div>;
      }
    },
  },
  {
    name: "activity",
    label: "Activity",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "taskEstimateStart",
    label: "Delayed",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        const date = new Date(value);
        const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
        return formattedDate;
      }
    },
  },
  {
    name: "status",
    label: "Status",
    options: {
      filter: true,
      sort: false,
      display: false
    },
  },
];


const columnsToDoList = [
  {
    name: "sn",
    label: "SN",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "taskTitle",
    label: "Task Name",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "deadline",
    label: "Target Date",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "assignUsers",
    label: "Assignee",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => (
        <div style={{width:"max-content"}}>
          {/* <img
            src={AccountCircle}
            alt="Assignee"
            style={{ height: 20, marginRight: 10 }}
          /> */}
          <AccountCircle style={{margin:"5px"}}/>
          <span style={{ color: "#345BAE" }}>{value}</span>
        </div>
      ),
    },
  },
];

const columnsTodayReceived = [
  {
    name: "materialName",
    label: "Material",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "materialQuantity",
    label: "Quantity",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "materialUnit",
    label: "Unit",
    options: {
      filter: true,
      sort: false,
    },
  },
];


const columnsDailyMaterial = [
  {
    name: "activity",
    label: "Activity",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "material",
    label: "Material",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "number",
    label: "Quantity",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "unit",
    label: "Units",
    options: {
      filter: true,
      sort: false,
    },
  },
];
class Overview extends Component {
  
  constructor(props) {
    // console.log(iterList);
    super(props);
    const role = global.config.secureStorage.getItem("role");
    const roles = ["project_manager", "chief_engineer", "super_admin"];
    // const isAddVisible = roles.find(item => item == role) ? true : false;
    console.log('projectId.....',global.config.secureStorage.getItem("projectId"));
    console.log('subprojectId.....',global.config.secureStorage.getItem("subprojectId"));

    this.state = {
      subProject: {},
      loading: true,
      activeActivityIndex: 0,
      percentage: [],
      accNames: [],
      projectType: "",
      projectName: "",
      activityDetails: [],
      safetyCheckList: 1,
      safetyIssue: 1,
      qualityCheckList: 1,
      qualityIssue: 1,
      drpDataCount: 1,
      todoData: 1,
      actualCompletionByPercent: [],
      estimatedCompletionByPercent: [],
      actualCompletionByCost: [],
      estimatedCompletionByCost: [],
      activityNames: [],
      subactivityNames: [],
      monitoring: [],
      selectedActivity: '',
      selectedActivityPVA: '',
      showHiddenSelectPVA: '',
      showHiddenSelectPVAC: '',
      selectedSubActivityPVA: '',
      selectedLibraryPVAC: '',
      selectedActivityPVAC: '',
      selectedActivityPPG: '',
      selectedSubActivity: '',
      selectedActivityObject:[],
      selectedActivityPVACObject:[],
      taskNames: [],
      apiData: {},
      toDoData: [],
      materialReceived: [],
      materialConsumption : [],
      plannedActive: [],
      DelayedActive: [],
      CostSummary: [],
      PVATime: [],
      PVACost: [],
      LatestUpdate: [],
      PPG: [],
      overallpercentage: "",
      overallCost: "",
      delayValue: "",
      costOverrun: "",
    };
  }
  getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MUIDataTable : {
        responsiveBase: {
          maxHeight : '300px'
        },
        tableRoot : {
          padding : '10px',
          boxShadow : 'none',
          border : 'none',
          borderCollapse: 'separate',
          borderSpacing: '0px 5px'
        },
        paper : {
          boxShadow : 'none'
        }
      },
      MUIDataTableHead : {
        root : {
          '@media (max-width : 599.95px)' : {
            display : 'table-header-group'
          }
        }
      },
      MUIDataTableHeadCell : {
        root : {
          padding : '5px',
          // borderStyle: 'none',
          fontWeight : 600
        }
      },
      MUIDataTableHeadRow : {
        root : {
          backgroundColor : '#ffffff',
          border : '1px solid #CECACA',
          borderRadius : '5px',
          margin : '5px'
        }
      },
      MUIDataTableBodyCell: {
        root: {
          padding : '5px'
        },
      },
      MUIDataTableBodyRow : {
        root : {
          backgroundColor : '#ffffff',
          border : '1px solid #CECACA',
          borderRadius : '5px'
        },
      },
      MuiTableCell: {
        root: {
          textAlign: 'center',
          borderTop : '1px solid rgba(224, 224, 224, 1)',
          '&:first-child': {
            borderLeft: '1px solid #CECACA',
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px'
          },
          '&:last-child': {
            borderRight: '1px solid #CECACA',
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px'
          },
        },
      },
    },
  });

  async componentDidMount() {
    try {
      this.props.getActivities();
      const response = await axios.get(
        `${global.config.backendURL}/overview/getAllStatisticalData/${global.config.secureStorage.getItem("projectId")}/${global.config.secureStorage.getItem("subprojectId")}`
      );
      response.data.forEach(data => {
        if (data.type === "checklist") {
          this.setState({
            safetyCheckList: data.safety,
            qualityCheckList: data.quality,
          });
        }
        if (data.type === "issue") {
          this.setState({
            safetyIssue: data.safety,
            qualityIssue: data.quality,
          });
        }
        if (data.type === "todoData") {
          this.setState({ todoData: data.total });
        }
        if (data.type === "dpr") {
          this.setState({ drpDataCount: data.count });
        }
      });

      await this.props.getDefaultActivities();
      await this.props.getAllUpdatesDetails(
        global.config.secureStorage.getItem("subprojectId")
      );

      let newPer = [];
      let newData = [];

      let DataAcc = this.props.percentage.accName;
      let DataList = this.props.percentage.per;
      DataList.forEach((element, i) => {
        if (element != 0) {
          newPer.push(element);
          newData.push(DataAcc[i]);
        }
      });
      if (this.props.activityDetails)
        this.setState({
          activityDetails: this.props.activityDetails,
        });
      this.setState({
        loading: !this.state.loading,
        percentage: newPer,
        accNames: newData,
      });

      const cost = [...this.props.cost]
      
      if (this.props.cost.length > 0) {
        let tempEstCost = []
        let tempActCost = []
        let tempAccNames = []
        cost.forEach((elem, id) => {
          if (elem.visibility) {

            tempEstCost.push((elem.estimatedcompletion_by_cost !== 'Not Available' ? elem.estimatedcompletion_by_cost : 0))

            tempActCost.push((elem.actualcompletion_by_cost !== 'Not Available' ? elem.actualcompletion_by_cost : 0))
            tempAccNames.push(elem.title)
          }
        })
        this.setState({
          estimatedCompletionByCost: tempEstCost,
          actualCompletionByCost: tempActCost,
          accNames: tempAccNames
        });
      console.log('acclength', this.accNames.length);

      }
      // this.fetchData();
    } catch (error) {
      //
    }
  }
  

  componentDidUpdate(pP, pS, sS) {
    try {
      const data = [...this.props.monitoring]



      if (this.props.monitoring && this.state.monitoring.length <= 0) {

        let activityArr = []

        data.forEach((elem, id) => {
          activityArr.push(elem.activity)
          let tempSub = []
          elem.subActivities.forEach((elem2, id) => {
            if (elem2 != null) {
              tempSub.push(elem2.title)
            }
          })

        })

        // this.setState({
        //   monitoring: this.props.monitoring,
        //   activityNames: activityArr,
        // });
      }

      if (pS.selectedActivity != this.state.selectedActivity) {
        let subactivityArr = []
        data.forEach((elem, id) => {
          if (this.state.selectedActivity == elem.activity) {
            elem.subActivities.forEach((elem2, id) => {
              if (elem2 != null) {
                subactivityArr.push(elem2.title)
              }
            })
          }
        })
        this.setState({
          subactivityNames: subactivityArr,
          actualCompletionByPercent: [],
          estimatedCompletionByPercent: [],
          taskNames: []
        });
      }

      if (pS.selectedSubActivity != this.state.selectedSubActivity) {
        this.setState({
          actualCompletionByPercent: [],
          estimatedCompletionByPercent: [],
          taskNames: []
        }, () => {
          data.forEach((elem, id) => {
            if (elem != null && this.state.selectedActivity == elem.activity) {
              elem.subActivities.forEach((elem2, id) => {
                if (elem2 != null && this.state.selectedSubActivity == elem2.title) {
                  this.setState({
                    actualCompletionByPercent: elem2.tasks.TaskDetails.Taskdata,
                    estimatedCompletionByPercent: elem2.tasks.TaskDetails.dateProgress,
                    taskNames: elem2.tasks.TaskDetails.TaskNames
                  });
                }
              })
            }
          })
        });
      }
    } catch (error) {

    }

  }
  componentDidMount(){
    var pId = global.config.secureStorage.getItem("projectId")
    this.state.projectName = global.config.secureStorage.getItem("projectName")
    console.log('ghghg....',pId);
      const formData = new FormData();
      let data = formData;
      let url = `${global.config.backendURL}/dashboard/getProjectCounts/` +pId;
      ApiGetMethod(url, data)
      .then((response) => {
        // console.log(response);
        // this.state.apiData = response
        this.setState({
          loading: !this.state.loading,
          apiData: response
        });
        // console.log(this.state.apiData);
      })

      let urlToDo =   `${global.config.backendURL}/dashboard/getProjectToDos/` +pId;
      ApiGetMethod(urlToDo, data)
      .then((response) => {
        // console.log('todo...', response);
        this.setState({
          toDoData: response
        })
      })

      let urlMaterialCon =   `${global.config.backendURL}/dashboard/getMaterialConsumption/` +pId;
      ApiGetMethod(urlMaterialCon, data)
      .then((response) => {
        // console.log('materialConsumption...', response);
        this.setState({
          materialConsumption: response
        })
      })

      let urlMaterialRece =   `${global.config.backendURL}/dashboard/getMaterialReceived/` +pId;
      ApiGetMethod(urlMaterialRece, data)
      .then((response) => {
        // console.log('materialReceived...', response);
        this.setState({
          materialReceived: response
        })
        // console.log('materialReceived...', this.state.materialReceived);
      })
      
      let urlPlannedActi =   `${global.config.backendURL}/dashboard/getPlannedActivities/` +pId;
      ApiGetMethod(urlPlannedActi, data)
      .then((response) => {
        // console.log('plannedActive...', response);
        this.setState({
          plannedActive: response
        })
      })

      let urlDelayedActi =   `${global.config.backendURL}/dashboard/getDelayedActivities/` +pId;
      ApiGetMethod(urlDelayedActi, data)
      .then((response) => {
        // console.log('DelayedActive...', response);
        this.setState({
          DelayedActive: response
        })
      })
      console.log("PID....", pId);
      let urlPVA =`${global.config.backendURL}/dashboard/GetPvaTime/` +pId;
      ApiGetMethod(urlPVA, data)
      .then((response1) => {
        console.log('urlPVA...', response1.subProjects[0].activities);
        this.setState({
          PVATime: response1.subProjects ? response1.subProjects[0].activities : ""
        })
        
        let plannedTimeData = this.state.PVATime.map((activity) => {
          let totalEstimatedStart = Infinity;
          let totalEstimatedEnd = 0;
          if (activity.subActivity && activity.subActivity.length > 0) {
            activity.subActivity.forEach((subActivity) => {
              subActivity.taskList.forEach((task) => {
                const taskEstimatedStart = new Date(task.taskEstimateStart).getTime();
                const taskEstimatedEnd = new Date(task.taskEstimateEnd).getTime();
                
                if (taskEstimatedStart < totalEstimatedStart) {
                  totalEstimatedStart = taskEstimatedStart;
                }
                
                if (taskEstimatedEnd > totalEstimatedEnd) {
                  totalEstimatedEnd = taskEstimatedEnd;
                }
              });
            });
          }
          
          const plannedDuration = totalEstimatedEnd - totalEstimatedStart;
          let plannedHours = plannedDuration / (1000 * 60 * 60);
          plannedHours = Math.max(0, plannedHours);
          
          return plannedHours;
        });
    
        let actualTimeData = this.state.PVATime.map((activity) => {
          let totalActualStart = Infinity;
          let totalActualEnd = 0;
          if (activity.subActivity && activity.subActivity.length > 0) {
            activity.subActivity.forEach((subActivity) => {
              subActivity.taskList.forEach((task) => {
                const taskActualStart = new Date(task.taskActualStart).getTime();
                const taskActualEnd = new Date(task.taskActualEnd).getTime();
                
                if (taskActualStart < totalActualStart) {
                  totalActualStart = taskActualStart;
                }
                
                if (taskActualEnd > totalActualEnd) {
                  totalActualEnd = taskActualEnd;
                }
              });
            });
          }
          
          const actualDuration = totalActualEnd - totalActualStart;
          let actualHours = actualDuration / (1000 * 60 * 60);
          actualHours = Math.max(0, actualHours);
          return actualHours;
        });
  
        console.log("plannedTimeData....", plannedTimeData);
        console.log("actualTimeData....", actualTimeData);
        
        let totalPlannedTime = plannedTimeData.reduce((total, time) => total + time, 0);
        let totalActualTime = actualTimeData.reduce((total, time) => total + time, 0);
        let differenceHours = totalActualTime - totalPlannedTime;
        let differenceDays = Math.ceil(differenceHours / 24);
        
        if (differenceDays <= 0) {
          this.setState({
            delayValue : "No Delay"
          })
          console.log("delayValue....", differenceDays);
        } else {
          this.setState({
            delayValue : differenceDays+ " days"
          })
        }
        console.log('urlPVAty...', this.state.PVATime);

      
      let urlCostSummary =   `${global.config.backendURL}/dashboard/getProjectCostSummary/` +pId;
      ApiGetMethod(urlCostSummary, data)
      .then((response) => {
        
        const updatedCostSummary = response.subProjects[0].activities.map((costActivity) => {
          const matchingPVATimeActivity = this.state.PVATime.find((pvaActivity) => {
            return pvaActivity.subActivity.some((subAct) => {
              return subAct.name === costActivity.activityName;
            });
          });
          
          if (matchingPVATimeActivity) {
            return {
              ...costActivity,
              mainActivity: matchingPVATimeActivity.activityName
            };
          } else {
            return {
              ...costActivity,
              mainActivity: "",
            };
          }
        });
        
        this.setState({
          CostSummary: updatedCostSummary
        });
        console.log('CostSummaryresponse...', updatedCostSummary);
        let totalCost = 0;
        
        for (let i = 0; i < this.state.CostSummary.length; i++) {
          totalCost += this.state.CostSummary[i].activityCount;
        }
        this.setState({
          overallCost: totalCost
        })
      })
    })

      

      let urlPVAC =   `${global.config.backendURL}/dashboard/getPvaCost/` +pId;
      ApiGetMethod(urlPVAC, data)
      .then((response) => {
        // console.log('urlPVA...', response.subProjects[0].activities);
        this.setState({
          PVACost: response.subProjects[0].activities
        })
        console.log('urlPVACost...', this.state.PVACost);
        let urlProjectPG =   `${global.config.backendURL}/dashboard/getProjectProgress/` +pId;
        ApiGetMethod(urlProjectPG, data)
        .then((resprogress) => {
          const updatedProgress = resprogress.subProjects[0].activities.map((progressActivity) => {
            const matchingPVATimeActivity = this.state.PVACost.find((pvaActivity) => {
              return pvaActivity.subActivity.some((subAct) => {
                return subAct.name === progressActivity.activityName;
              });
            });
      
            if (matchingPVATimeActivity) {
              return {
                ...progressActivity,
                mainActivity: matchingPVATimeActivity.activityName
              };
            } else {
              return {
                ...progressActivity,
                mainActivity: "",
              };
            }
          });
          this.setState({
            PPG: updatedProgress
          })
          console.log('urlProjectPG...', this.state.PPG);
          let totalPercentage = 0;
    
          for (let i = 0; i < this.state.PPG.length; i++) {
            totalPercentage += this.state.PPG[i].percentage;
          }
          let total = (totalPercentage / this.state.PPG.length)
          this.setState({
            overallpercentage: total
          })
        })
      })

      let plannedCost = this.state.PVACost.map((activity) =>
      activity.subActivity.reduce((sum, item) => sum + calculateTotalCost(item.estimated), 0)
    );

    let actualCost = this.state.PVACost.map((activity) =>
      activity.subActivity.reduce((sum, item) => sum + calculateTotalCost(item.actual), 0)
    );
    function calculateTotalCost(costObject) {
      const keys = Object.keys(costObject);
      let total = 0;
    
      for (let i = 0; i < keys.length; i++) {
        total += costObject[keys[i]];
      }
    
      return total;
    }

    let difference = actualCost.map((cost, index) => cost - plannedCost[index]);
    let totalDifference = difference.reduce((sum, value) => sum + value, 0);
    if (totalDifference <= 0) {
      this.setState({
        costOverrun : "No Cost Overrun"
      })
    } else {
      this.setState({
        costOverrun : "₹" +totalDifference
      })
    }


      let urlLatestUp =   `${global.config.backendURL}/dashboard/getLatestUpdates/` +pId;
      ApiGetMethod(urlLatestUp, data)
      .then((response) => {
        // console.log('urlPVA...', response.subProjects[0].activities);
        this.setState({
          LatestUpdate: response.allActivitiyUpdates
        })
        console.log('urlLatestUp...', this.state.LatestUpdate);
      })


  }

  render() {
    if (this.state.loading) {
      return <Loading loading={this.state.loading} />;
    }
    const { toDoData } = this.state;

    const toDoDataWithSN = toDoData.map((data, index) => ({
      ...data,
      sn: index + 1
    }));
    const { materialConsumption } = this.state;
    const { materialReceived } = this.state;
    const { apiData } = this.state;
    const isKeyPresentq = apiData.quality?.Approved !== undefined && "Approved" in apiData.quality;
    const isKeyPresents = apiData.safety?.Approved !== undefined && "Approved" in apiData.safety;
    const { plannedActive } = this.state;
    const { DelayedActive } = this.state;
    const { CostSummary } = this.state;
    const { PVATime } = this.state;
    const { LatestUpdate } = this.state;
    const { PPG } = this.state;
    const { overallpercentage } = this.state;

    // const PVAGraph = PVATime.map((data) => [
    //   data.activities
    // ])
    // const CostSumma = CostSummary.map((cost) => [
    //   cost[0].activities.map((summary) => summary.name)
    // ])
    console.log('CostSumma...', CostSummary);

    const dataMatr = materialConsumption.map((item) => [
      item.activity,
      item.material.map((materialItem) => materialItem.material).join(", "),
      item.material.map((materialItem) => materialItem.number).join(", "),
      item.material.map((materialItem) => materialItem.unit).join(", ")
    ]);
    const { delayValue } = this.state;
    const { costOverrun } = this.state;
    return (
      <div className="container" style={{ marginTop: "20px" }}>
        <Grid container spacing={2}>
          <Grid
            style={{
              backgroundColor: "#fff",
            }}
            item
            sm={8}
            xs={12}
          >
            <Grid container xs={12} md={12} className="justify-content-around">
              <Grid container xs={12} md={8} spacing={2} className="d-flex flex-wrap">
                <Grid className="mt-2" item sm={6} xs={6}>
                  <Grid
                    className="p-3 pl-4"
                    style={{
                      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
                      // textAlign: "center",
                      height: "100%",
                      border: "1px solid #ddd",
                      borderRadius : '32px',
                    }}
                  >
                    <Link to="/dpr">
                      {/* <IconButton aria-label="settings">
                        <Group fontSize="large" color="disabled" />
                      </IconButton> */}
                      <img src={dprs} alt="" className="pb-2" />
                    </Link>
                    <Typography variant="h6">
                      {apiData.dprsCount}
                    </Typography>
                    <Typography variant="h6" style={{color:"#345BAE"}}>DPRS</Typography>
                  </Grid>
                </Grid>

                <Grid className="mt-2" item sm={6} xs={6}>
                  <Grid
                    className="p-3 pl-4"
                    style={{
                      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
                      // textAlign: "center",
                      height: "100%",
                      border: "1px solid #ddd",
                      borderRadius : '32px',
                    }}
                  >
                    <Link to="/checklist/quality">
                      {/* <IconButton aria-label="settings">
                        <FilterList fontSize="large" color="disabled" />
                      </IconButton> */}
                      <img src={qualityCheck} alt="" className="pb-2" />
                    </Link>
                    <Typography variant="h6">
                    {apiData.qualityCheckListCount}
                    </Typography>
                    <Typography variant="h6" style={{color:"#345BAE"}}>Quality Checklists</Typography>
                  </Grid>
                </Grid>
                <Grid className="mt-2" item sm={6} xs={6}>
                  <Grid
                    className="p-3 pl-4"
                    style={{
                      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
                      // textAlign: "center",
                      height: "100%",
                      border: "1px solid #ddd",
                      borderRadius : '32px',
                    }}
                  >
                    <Link to="/todo">
                      {/* <IconButton aria-label="settings">
                        <Group fontSize="large" color="disabled" />
                      </IconButton> */}
                      <img src={toDo} alt="" className="pb-2" />
                    </Link>
                    <Typography variant="h6">{apiData.todosCount}</Typography>
                    <Typography variant="h6" style={{color:"#345BAE"}}>Todos</Typography>
                  </Grid>
                </Grid>
                <Grid className="mt-2" item sm={6} xs={6}>
                  <Grid
                    className="p-3 pl-4"
                    style={{
                      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
                      // textAlign: "center",
                      height: "100%",
                      border: "1px solid #ddd",
                      borderRadius : '32px',
                    }}
                  >
                    <Link to="/checklist/safety">
                      {/* <IconButton aria-label="settings">
                        <FilterList fontSize="large" color="disabled" />
                      </IconButton> */}
                      <img src={saftyCheck} alt="" className="pb-2" />
                    </Link>
                    <Typography variant="h6">
                      {apiData.safetyCheckListCount}
                    </Typography>
                    <Typography variant="h6" style={{color:"#345BAE"}}>Safety Checklists</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container xs={12} md={4} spacing={2} className="padding-top">
                  <Grid className="mt-2" item sm={12} xs={6} style={{padding:'8px'}}>
                    <Grid
                      className="p-2"
                      style={{
                        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
                        textAlign: "center",
                        height: "100%",
                        border: "1px solid #ddd",
                        borderRadius : '32px'
                      }}
                    >
                      {/* <Link to="/issues/quality">
                        <IconButton aria-label="settings">
                          <ErrorRounded fontSize="large" color="disabled" />
                        </IconButton>
                      </Link> */}
                      <Typography variant="h6" style={{color:"#345BAE"}}>Quality Issues</Typography>
                      <Typography variant="h6">
                      {(apiData.quality) ? apiData.quality.total : 0}
                      </Typography>
                      <Grid className="p-0 d-flex pt-3" sm={12}>
                        <Grid sm={6} style={{borderRight: "2px solid #000"}} className="solved">
                          <Typography variant="h6">Approved</Typography>
                          <Typography variant="h6">{(apiData.quality) ? (isKeyPresentq) ? apiData.quality.Approved + apiData.quality.solved : apiData.safety.solved : 0}</Typography>
                        </Grid>
                        <Grid sm={6} className="pendingIss">
                          <Typography variant="h6">Pending</Typography>
                          <Typography variant="h6">{(apiData.quality) ? apiData.quality.pending : 0}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid className="mt-2" item sm={12} xs={6} style={{padding:'8px'}}>
                    <Grid
                      className="p-2"
                      style={{
                        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
                        textAlign: "center",
                        height: "100%",
                        border: "1px solid #ddd",
                        borderRadius : '32px',
                      }}
                    >
                      {/* <Link to="/issues/safety">
                        <IconButton aria-label="settings">
                          <ErrorRounded fontSize="large" color="disabled" />
                        </IconButton>
                      </Link> */}
                      <Typography variant="h6" style={{color:"#345BAE"}}>Safety Issues</Typography>
                      <Typography variant="h6">{(apiData.safety) ? apiData.safety.total : 0}</Typography>
                      <Grid className="p-0 d-flex pt-3" sm={12}>
                        <Grid sm={6} style={{borderRight: "2px solid #000"}} className="solved">
                          <Typography variant="h6">Approved</Typography>
                          <Typography variant="h6">{(apiData.safety) ? (isKeyPresents) ? apiData.safety.Approved + apiData.safety.solved : apiData.safety.solved : 0}</Typography>
                        </Grid>
                        <Grid sm={6} className="pendingIss">
                          <Typography variant="h6">Pending</Typography>
                          <Typography variant="h6">{(apiData.safety) ? apiData.safety.pending : 0}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={4} xs={12}>
            {/* <VerticleList activityDetails={this.state.activityDetails} /> */}
            <Grid style={{marginTop : '9px', background : '#ffffff', borderRadius : '10px', padding : '10px', boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)'}}>
              <Typography variant="h5" className="font-weight-bold mb-2">Latest Updates</Typography>
              <Grid style={{maxHeight:"280px", overflow:"auto"}}>
                  {LatestUpdate.map((item, index) => (
                    <Grid key={index} className="d-flex justify-content-between align-items-center" style={{border: "1px solid #D9D9D9", borderRadius: "10px", padding: "10px", marginBottom: "10px"}}>
                      <Grid item xs={8} md={8}>
                        <Typography variant="p" className="" style={{color:"#345BAE"}}>
                          Updated by: {item.user}
                        </Typography>
                        <br />
                        <Typography variant="p" className="" style={{color:"#345BAE"}}>
                          Update Date : {item.date.replace(/\//g, "-")}
                        </Typography>
                        <br />
                        <Typography variant="p" className="" style={{color:"#345BAE"}}>
                          Activity Name: {item.activityName}
                        </Typography>
                        <br />
                        <Typography variant="p" className="" style={{color:"#345BAE"}}>
                          Update Percentage: {item.updatedPercentage}%
                        </Typography>
                      </Grid>
                      <Grid>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ padding: "5px" }}
                          onClick={() => window.open(item.full_panaroma_url, '_blank')}
                        >
                          <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M39.5833 6.25H10.4167C8.11548 6.25 6.25 8.11548 6.25 10.4167V39.5833C6.25 41.8845 8.11548 43.75 10.4167 43.75H39.5833C41.8845 43.75 43.75 41.8845 43.75 39.5833V10.4167C43.75 8.11548 41.8845 6.25 39.5833 6.25Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M17.7085 20.8333C19.4344 20.8333 20.8335 19.4342 20.8335 17.7083C20.8335 15.9825 19.4344 14.5833 17.7085 14.5833C15.9826 14.5833 14.5835 15.9825 14.5835 17.7083C14.5835 19.4342 15.9826 20.8333 17.7085 20.8333Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M43.7498 31.25L33.3332 20.8333L10.4165 43.75" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </Button>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className="mt-4" spacing={2}>
          <Grid container sm={12} xs={12}>
            <Grid item sm={6} xs={12} style={{padding : '10px 10px'}}>
              <div style={{fontWeight : 600, fontSize : '24px', paddingBottom:'10px', color:"#345BAE"}}>
                <span className="mr-2">
                  <img src={actualTime} alt="" />
                </span>
                <span>Planned vs Actual Time</span>
              </div>
                <div style={{textAlign : 'center', boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)', borderRadius : '10px', padding : '10px'}}>
                  <div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}> 
                    <div>
                      <Typography
                        style={{ fontSize: "1.1em"}}
                      >
                        <strong>Project Name: </strong>
                        {this.state.projectName}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        style={{ fontSize: "1.1em"}}
                      >
                        {delayValue && (
                            <strong>
                              Delayed Time: {delayValue}
                            </strong>
                          )}
                      </Typography>
                    </div>
                  </div>
                  <FormControl style={{ width: '45%', marginRight:"10px" }}>
                    <InputLabel id="activity" required>
                      Activity List
                    </InputLabel>
                    <Select
                      labelId="activity"
                      id="activity"
                      value={this.state.selectedActivityPVA}
                      label="activity"
                      onChange={event => {
                        const selectedValue = event.target.value;
                        const selectedActivity = PVATime.find(item => item.activityName === selectedValue);
                        this.setState({
                          selectedActivityPVA: selectedValue,
                          selectedSubactivityPVA: '',
                          selectedActivityObject: selectedActivity,
                          showHiddenSelectPVA: selectedValue !== "0"
                        });
                      }}
                    >
                      <MenuItem value="0">All</MenuItem>
                      
                      {PVATime ? PVATime.map((item, index) => (
                        <MenuItem key={index} value={item.activityName}>{item.activityName}</MenuItem>
                      )) : ""}
                    </Select>
                  </FormControl>

                  {this.state.showHiddenSelectPVA && this.state.selectedActivityPVA !== "0" && (
                    <FormControl style={{ width: '45%' }}>
                      <InputLabel id="hidden-select-label">Subactivity List</InputLabel>
                      <Select
                        labelId="hidden-select-label"
                        id="hidden-select"
                        value={this.state.selectedSubActivityPVA}
                        label="Subactivity List"
                        onChange={event => {
                          this.setState({
                            selectedSubActivityPVA: event.target.value
                          });
                        }}
                      >
                        {this.state.selectedActivityObject.subActivity.map((subactivity, index) => (
                          <MenuItem key={index} value={subactivity.name}>{subactivity.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                    {/* <TestPage/> */}
                  {PVATime !== null && <GroupChart apiresponse={PVATime} selectedActivityPVA={this.state.selectedActivityPVA} selectedSubActivityPVA={this.state.selectedSubActivityPVA} />}
              </div>
            </Grid>
            <Grid item sm={6} xs={12} style={{padding : '10px 10px'}}>
              <div style={{fontWeight : 600, fontSize : '24px', paddingBottom:'10px', color:"#345BAE"}}>
                <span className="mr-2">
                  <img src={actualCost} alt="" />
                </span>
                <span>Planned vs Actual Cost</span>
              </div>
                <div style={{textAlign : 'center', boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)', borderRadius : '10px', padding : '10px'}}>
                  <div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}> 
                    <div>
                      <Typography
                        style={{ fontSize: "1.1em"}}
                      >
                        <strong>Project Name: </strong>
                        {this.state.projectName}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        style={{ fontSize: "1.1em"}}
                      >
                        {costOverrun && (
                            <strong>
                             Cost Overrun: {costOverrun}
                            </strong>
                          )}
                      </Typography>
                    </div>
                  </div>
                  <FormControl style={{width : '45%', marginRight:"10px" }}>
                    <InputLabel id="activity" required>
                      Activity List
                    </InputLabel>
                    <Select
                      labelId="activity"
                      id="activity"
                      value={this.state.selectedActivityPVAC}
                      label="activity"
                      onChange={event => {
                        const selectedPVACValue = event.target.value;
                        const selectedActivityPVAC = this.state.PVACost.find(item => item.activityName === selectedPVACValue);
                        this.setState({
                          selectedActivityPVAC: selectedPVACValue,
                          selectedLibraryPVAC: '',
                          selectedActivityPVACObject: selectedActivityPVAC,
                          showHiddenSelectPVAC: selectedPVACValue !== "0"
                        });
                      }}
                    >
                      <MenuItem value="0">All</MenuItem>
                      {this.state.PVACost.map((item, index) => (
                        <MenuItem key={index} value={item.activityName}>{item.activityName}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {this.state.showHiddenSelectPVAC && this.state.selectedActivityPVAC !== "0" && (
                    <FormControl style={{ width: '45%' }}>
                      <InputLabel id="hidden-select-label">Library List</InputLabel>
                      <Select
                        labelId="hidden-select-label"
                        id="hidden-select"
                        value={this.state.selectedLibraryPVAC}
                        label="Subactivity List"
                        onChange={event => {
                          this.setState({
                            selectedLibraryPVAC: event.target.value
                          });
                        }}
                      >
                          <MenuItem value="equipment">Equipments</MenuItem>
                          <MenuItem value="contractorras">Contractor'RAS</MenuItem>
                          <MenuItem value="materials">Material</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                  <PVAC PVACresponse={this.state.PVACost} selectedActivityPVAC={this.state.selectedActivityPVAC} selectedLibraryPVAC={this.state.selectedLibraryPVAC} />
              </div>
            </Grid>
            {/* <Grid item sm={6} xs={12} style={{padding : '10px 10px'}}>
              <div style={{fontWeight : 600, fontSize : '24px', paddingBottom:'10px', color:"#345BAE"}}>
              <span className="mr-2">
              <img src={costSummary} alt="" />
              </span>
              <span>Project Cost Summary</span>
              </div>
              <div style={{textAlign : 'center', boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)', borderRadius : '10px', padding : '10px'}}>
              <div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}> 
              <div>
              <Typography
              style={{ fontSize: "1.1em"}}
              >
              <strong>Project Name: </strong>
              test
              </Typography>
              </div>
              <div>
              <Typography
                        style={{ fontSize: "1.1em"}}
                        >
                        <strong>Sub Project Name: </strong>
                        test
                      </Typography>
                    </div>
                    </div>
                  <FormControl style={{width : '50%'}}>
                    <InputLabel id="activity" required>
                      All
                    </InputLabel>
                    <Select
                      labelId="activity"
                      id="activity"
                      value={this.state.selectedActivity}
                      label="activity"
                      onChange={event => {
                        this.setState({
                          selectedActivity: event.target.value
                        });
                      }}
                    >
                      {this.state.activityNames.map(item => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                      </Select>
                      </FormControl>
                      {this.state.accNames &&
                        this.state.actualCompletionByCost.length > 0 &&
                        this.state.accNames.length > 0 ? (
                          <BarchartData
                      accNames={this.state.accNames}
                      percentage={this.state.estimatedCompletionByCost}
                      percentageLabel="Estimated Completion by Cost"
                      percentage1={this.state.actualCompletionByCost}
                      percentage1Label="Actual Completion by Cost"
                    />
                  ) : (
                    ""
                  )}
              </div>
            </Grid> */}
            <Grid item sm={6} xs={12}>
              <Grid item sm={12} xs={12} style={{padding : '10px 10px'}}>
                <div style={{fontWeight : 600, fontSize : '24px', paddingBottom:'10px', color:"#345BAE"}}>
                  <span className="mr-2">
                    <img src={costSummary} alt="" />
                  </span>
                  <span>Project Cost Summary</span>
                </div>
                  <div style={{textAlign : 'center', boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)', borderRadius : '10px', padding : '10px'}}>
                    <div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}> 
                      <div>
                        <Typography
                          style={{ fontSize: "1.1em"}}
                        >
                          <strong>Project Name: </strong>
                          {this.state.projectName}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          style={{ fontSize: "1.1em"}}
                        >
                          <strong>Overall Cost: </strong>
                          {this.state.overallCost}
                        </Typography>
                      </div>
                    </div>
                    <FormControl style={{width : '50%'}}>
                      <InputLabel id="activity" required>
                        All
                      </InputLabel>
                      <Select
                        labelId="activity"
                        id="activity"
                        value={this.state.selectedActivity}
                        label="activity"
                        onChange={event => {
                          this.setState({
                            selectedActivity: event.target.value
                          });
                        }}
                      >
                        <MenuItem value="0">All</MenuItem>
                        {CostSummary.reduce((uniqueActivities, item) => {
                          if (!uniqueActivities.includes(item.mainActivity)) {
                            uniqueActivities.push(item.mainActivity);
                          }
                          return uniqueActivities;
                        }, []).map((activity, index) => (
                          <MenuItem key={index} value={activity}>
                            {activity}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <BarChartHorizontal costSum={CostSummary} costSumSubAct={this.state.selectedActivity} />
                </div>
              </Grid>
            </Grid>
            <Grid item sm={6} xs={12} style={{padding : '10px 10px'}}>
              <Grid item sm={12} xs={12}>
                <div style={{fontWeight : 600, fontSize : '24px', paddingBottom:'10px', color:"#345BAE"}}>
                  <span className="mr-2">
                    <img src={progressGraph} alt="" />
                  </span>
                  <span>Project Progress Graph</span>
                </div>
                  <div style={{textAlign : 'center', boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)', borderRadius : '10px', padding : '10px'}}>
                    <div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}> 
                      <div>
                        <Typography
                          style={{ fontSize: "1.1em"}}
                        >
                          <strong>Project Name: </strong>
                          {this.state.projectName}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          style={{ fontSize: "1.1em"}}
                        >
                          <strong>Overall Progress: </strong>
                          {Number(overallpercentage).toFixed(2)}%
                        </Typography>
                      </div>
                    </div>
                    <FormControl style={{width : '50%'}}>
                      <InputLabel id="activity" required>
                        All
                      </InputLabel>
                      <Select
                        labelId="activity"
                        id="activity"
                        value={this.state.selectedActivityPPG}
                        label="activity"
                        onChange={event => {
                          this.setState({
                            selectedActivityPPG: event.target.value
                          });
                        }}
                      >
                        <MenuItem value="0">All</MenuItem>
                        {PPG.reduce((uniqueActivities, item) => {
                          if (!uniqueActivities.includes(item.mainActivity)) {
                            uniqueActivities.push(item.mainActivity);
                          }
                          return uniqueActivities;
                        }, []).map((activity, index) => (
                          <MenuItem key={index} value={activity}>
                            {activity}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <BarChartVerticle Ppg={PPG} subPpg={this.state.selectedActivityPPG} />
                </div>
              </Grid>
              <Grid item sm={12} xs={12}>
                <div style={{fontWeight : 600, fontSize : '24px', paddingBottom:'10px', paddingTop: '20px', color:"#345BAE"}}>
                  <span className="mr-2">
                    <img src={plannedActive} alt="" />
                  </span>
                  <span>Planned Activity</span>
                </div>
                  <div style={{textAlign : 'center', boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)', borderRadius : '10px', padding : '10px'}}>
                    {plannedActive.length > 0 ? (
                        <MuiThemeProvider theme={this.getMuiTheme()}>
                          <MUIDataTable
                            columns={columnsPlannedActivity}
                            data={plannedActive}
                            options={{
                              selectableRows: false,
                              responsive: "verticle",
                              pagination: false,
                              download: false,
                              viewColumns: false,
                              filter: false,
                              print: false,
                              search: false,
                            }}
                          />
                        </MuiThemeProvider>
                    ) : (
                      "Sorry, no matching records found"
                    )}
                  </div>
              </Grid>
            </Grid>
            <Grid item sm={6} xs={12} style={{padding : '10px 10px'}}>
              <div style={{fontWeight : 600, fontSize : '24px', paddingBottom:'10px', color:"#345BAE"}}>
                <span className="mr-2">
                  <img src={delayedActivity} alt="" />
                </span>
                <span>Delayed Activities</span>
              </div>
                <div style={{textAlign : 'center', boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)', borderRadius : '10px', padding : '10px'}}>
                  <div className="status d-flex">
                    <div className="completeSt d-flex">
                      <div style={{width: '20px', height: '20px', background: '#1BD741', borderRadius: '30px'}}></div>
                      <h6 className="m-0 ml-2">Complete</h6>
                    </div>
                    <div className="in-progressSt d-flex">
                      <div style={{width: '20px', height: '20px', background: '#1F89EC', borderRadius: '30px'}}></div>
                      <h6 className="m-0 ml-2">In Progress</h6>
                    </div>
                    <div className="pendingSt d-flex">
                      <div style={{width: '20px', height: '20px', background: '#FFC107', borderRadius: '30px'}}></div>
                      <h6 className="m-0 ml-2">Pending</h6>
                    </div>
                  </div>
                  {DelayedActive.length > 0 ? (
                      <MuiThemeProvider theme={this.getMuiTheme()}>
                        <MUIDataTable
                          columns={columnsDelayedActivity}
                          data={DelayedActive}
                          options={{
                            selectableRows: false,
                            responsive: "verticle",
                            pagination: false,
                            download: false,
                            viewColumns: false,
                            filter: false,
                            print: false,
                            search: false,
                          }}
                        />
                      </MuiThemeProvider>
                    
                    ) : (
                    "Sorry, no matching records found"
                  )}
                </div>
            </Grid>
            <Grid item sm={6} xs={12} style={{padding : '10px 10px'}}>
              <div style={{fontWeight : 600, fontSize : '24px', paddingBottom:'10px', color:"#345BAE"}}>
                <span className="mr-2">
                  <img src={ToDolist} alt="" />
                </span>
                <span>To-Do List</span>
              </div>
                <div style={{textAlign : 'center', boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)', borderRadius : '10px', padding : '10px'}}>
                  {toDoData.length > 0 ? (
                      <MuiThemeProvider theme={this.getMuiTheme()}>
                        <MUIDataTable
                          columns={columnsToDoList}
                          data={toDoDataWithSN}
                          options={{
                            selectableRows: false,
                            responsive: "verticle",
                            pagination: false,
                            download: false,
                            viewColumns: false,
                            filter: false,
                            print: false,
                            search: false,
                          }}
                        />
                      </MuiThemeProvider>
                  ) : (
                    "Sorry, no matching records found"
                  )}
                </div>
            </Grid>
            <Grid item sm={6} xs={12} style={{padding : '10px 10px'}}>
              <div style={{fontWeight : 600, fontSize : '24px', paddingBottom:'10px', color:"#345BAE"}}>
                <span className="mr-2">
                  <img src={Todayreceive} alt="" />
                </span>
                <span>Today’s Received Material</span>
              </div>
                <div style={{textAlign : 'center', boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)', borderRadius : '10px', padding : '10px'}}>
                  {materialReceived.length > 0 ? (
                      <MuiThemeProvider theme={this.getMuiTheme()}>
                        <MUIDataTable
                          columns={columnsTodayReceived}
                          data={materialReceived.map((item) => item.material)}
                          options={{
                            selectableRows: false,
                            responsive: "verticle",
                            pagination: false,
                            download: false,
                            viewColumns: false,
                            filter: false,
                            print: false,
                            search: false,
                          }}
                        />
                      </MuiThemeProvider>
                  ) : (
                    "Sorry, no matching records found"
                  )}
                </div>
            </Grid>
            <Grid item sm={6} xs={12} style={{padding : '10px 10px'}}>
              <div style={{fontWeight : 600, fontSize : '24px', paddingBottom:'10px', color:"#345BAE"}}>
                <span className="mr-2">
                  <img src={dailyMaterial} alt="" />
                </span>
                <span>Daily material consumption</span>
              </div>
                <div style={{textAlign : 'center', boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)', borderRadius : '10px', padding : '10px'}}>
                  {dataMatr.length > 0 ? (
                      <MuiThemeProvider theme={this.getMuiTheme()}>
                        <MUIDataTable
                          columns={columnsDailyMaterial}
                          data={dataMatr}
                          options={{
                            selectableRows: false,
                            responsive: "verticle",
                            pagination: false,
                            download: false,
                            viewColumns: false,
                            filter: false,
                            print: false,
                            search: false,
                          }}
                        />
                      </MuiThemeProvider>
                  ) : (
                    "Sorry, no matching records found"
                  )}
                </div>
            </Grid>
            {/* <Grid item sm={6} xs={12} style={{padding : '10px 10px'}}>
              <div style={{fontWeight : 600, fontSize : '24px'}}>Planned vs Actual</div>
                <div style={{textAlign : 'center', boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)', borderRadius : '10px', padding : '10px'}}>
                  {this.state.accNames &&
                    this.state.actualCompletionByCost.length > 0 &&
                    this.state.accNames.length > 0 ? (
                      <MuiThemeProvider theme={this.getMuiTheme()}>
                        <MUIDataTable
                          columns={columnsPlannedActivity}
                          data={[{name: 'Brickwork', estimatedCost : '10000', startDate: '16-03-2023'},{name: 'Brickwork', estimatedCost : '10000', startDate: '16-03-2023'},{name: 'Brickwork', estimatedCost : '10000', startDate: '16-03-2023'}]}
                          options={{
                            selectableRows: false,
                            responsive: "verticle",
                            pagination: false,
                            download: false,
                            viewColumns: false,
                            filter: false,
                            print: false,
                            search: false,
                          }}
                        />
                      </MuiThemeProvider>
                  ) : (
                    ""
                  )}
                </div>
            </Grid> */}
          </Grid>
          

          {/* <Grid item sm={6} xs={12}> */}
            {/* {console.log(this.state.accNames)} */}
            {/* {this.state.accNames.length > 0 ? ( */}
              {/* <BarchartData
                accNames={this.state.accNames}
                percentage={this.state.percentage}
                percentageLabel="Activity Updates"
              /> */}
            {/* ) : ( */}
              {/* "" */}
            {/* )} */}
          {/* </Grid> */}


            {/* previos code */}
          {/* <Grid container sm={8} xs={12}>
            <Grid item sm={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="activity" required>
                  Activity
                </InputLabel>
                <Select
                  labelId="activity"
                  id="activity"
                  value={this.state.selectedActivity}
                  label="activity"
                  onChange={event => {
                    this.setState({
                      selectedActivity: event.target.value
                    });
                  }}
                >
                  {this.state.activityNames.map(item => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="activity" required>
                  Sub Activity
                </InputLabel>
                <Select
                  labelId="subactivity"
                  id="subactivity"
                  value={this.state.selectedSubActivity}
                  label="subActivity"
                  onChange={event => {
                    this.setState({
                      selectedSubActivity: event.target.value
                    });
                  }}
                >
                  {this.state.subactivityNames.map(item => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            
            <Grid item sm={6} xs={12}>
              {this.state.taskNames &&
                this.state.estimatedCompletionByPercent &&
                this.state.taskNames.length > 0 ? (
                <BarchartData
                  accNames={this.state.taskNames}
                  percentage={this.state.estimatedCompletionByPercent}
                  percentageLabel="Estimated Completion by Percentage"
                  percentage1={this.state.actualCompletionByPercent}
                  percentage1Label="Actual Completion by Percentage"
                />
              ) : (
                <Typography> [Select Activity and Sub Activity*]</Typography>
              )}
            </Grid>
            <Grid item sm={6} xs={12}>

              {this.state.accNames &&
                this.state.actualCompletionByCost &&
                this.state.accNames.length > 0 ? (
                <BarchartData
                  accNames={this.state.accNames}
                  percentage={this.state.estimatedCompletionByCost}
                  percentageLabel="Estimated Completion by Cost"
                  percentage1={this.state.actualCompletionByCost}
                  percentage1Label="Actual Completion by Cost"
                />
              ) : (
                ""
              )}
            </Grid>
          </Grid> */}

        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  // console.log(state.monitoringReducer.monitoring)
  return {
    defaultActivity: state.activityReducer.defaultActivity,
    percentage: state.activityReducer.percentage,
    loading: state.activityReducer.loading,
    activityDetails: state.overviewReducer.activityDetails,
    monitoring: state.monitoringReducer.monitoring,
    cost: state.monitoringReducer.cost,
  };
};

export default connect(mapStateToProps, {
  getDefaultActivities,
  getAllUpdatesDetails,
  getActivities
})(Overview);
