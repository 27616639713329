import React, { useState, useEffect, useMemo } from "react";
import debounce from "lodash/debounce";
import { Add as AddIcon, AddCircleOutline, Delete } from "@material-ui/icons";
import Swal from 'sweetalert2';
import {
  InputLabel,
  TextField,
  Grid,
  Button,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  FormControl,
  Select,
  MenuItem,
  Typography,
  IconButton,
  Tabs, Tab, AppBar
} from "@material-ui/core";
import { Save, Cancel } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Style from "./style.module.scss";
import { isInteger } from "lodash";
import axios from "axios";

const Editlabour = props => {
  const [open, setOpen] = useState(false);
  const [labourName, setlabourname] = useState("");
  const [labour, setlabour] = useState([]);
  const [txt, setTxt] = useState("");
  const [toggleText, setToggleText] = useState(false);
  const [activities, setActivities] = useState([]);
  const [activity, setActivity] = useState("");
  const loading = open && labour.length === 0;
  const [mainActivities, setMainActivities] = useState([]);
  const [contractorList, setContractorList] = useState([]);
  const [itemlist, setItemlist] = useState([]);
  const[ tabIndex,setTabIndex]=useState(0)
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const onInputChange = e => {
    const { value } = e.target;

    const re = /^[A-Za-z]+$/;
    if (value === "" || re.test(value)) {
      setTxt(value);
    }
  };
  const handelTabs = (e, val) => {
    setTabIndex(val);
    props.handleTabChange()
  }
  async function getMasterContractorList() {
    let projectId = await global.config.secureStorage.getItem("projectId");
    const response = await axios.get(
      `${global.config.backendURL}/stores/getMasterListContractorRA/${projectId}`
    );
    console.log("ressponse is ", response.data);
    let labour_list = [];
    let item_list = [];
    response.data.forEach(element => {
      console.log("items are", element.items);
      labour_list.push(element.contractorName);
      item_list.push(element.items);
    });
    setItemlist(item_list);
    console.log("item list is ", item_list);
    setContractorList(labour_list);
  }

  useEffect(() => {
    if (!loading) {
      return undefined;
    }
    var tempMaterials = new Set();
    console.log("in loading see here")(() => {
      props.storeData.map(ele => {
        return tempMaterials.add(ele.materialName);
      });
      setlabour(Array.from(tempMaterials));
    })();
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setlabour([]);
    }
    console.log("props activites are ", props);
    getActivities();
    getMasterContractorList();
    console.log("see store data", props.storeData);
  }, [open]);

  async function getActivities() {
    let projectId = await global.config.secureStorage.getItem("projectId");
    const response = await axios.get(
      `${global.config.backendURL}/activities/getDefaultActivities/${projectId}`
    );
    // if(projectType === "Infrastructure"){
    //   const mainActivities = response.data.data.map((mainAct,index)=>{
    //     console.log(mainAct)
    //     return {
    //       id: index,
    //       title : maisnAct.name,
    //       activities : mainAct.data
    //     };
    //   });
    //   setMainActivities(mainActivities);
    // }
    // else{
    //   setActivities(response.data.data[0].data);
    // }
    let final = [];
    response.data.data.forEach(element => {
      if (element.visibility) final.push(element);
    });
    setActivities(final);
  }

  // const handleMainActivityChange = (event) =>{
  //    const value = event.target.value;
  //    const id = mainActivities.findIndex(act=>{
  //      return act.title === value;
  //    });
  //    console.log(id)
  //    if(id>=0){
  //     const activity = mainActivities[id].activities;
  //     setActivities(activity);
  //     props.handleMainActivity(event);
  //    }
  // }
  console.log("props", props.contractorIndex, props.contractorName);

  return (
    <div>
      <Dialog
        open={true}
        // open={addClicked}
        // onClose={handleClose}
        className="mt-5"
        maxWidth="sm"
        fullWidth
        fullScreen={fullScreen}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          {/* <section className={Style.create__material}> */}
          <form onSubmit={props.updateLabour}>
            <center>
              <Typography variant="overline" style={{ fontSize: "1.5em" }}>
                Add to Contractor
              </Typography>
            </center>
            <br />
            <br />

            <Grid container>
              <Grid item md={12} xs={12} width="100%">
                <FormControl fullWidth>
                  <InputLabel id="activity">Contractor</InputLabel>
                  <Select
                    labelId="Contractor"
                    id="contractor"
                    value={
                      typeof props.contractorName === "string" &&
                      props.contractorName !== null
                        ? props.contractorName
                        : ""
                    }
                    label="contractor"
                    onChange={event => {
                      props.handleContractorChange(event);
                    }}
                    required
                  >
                    {contractorList.map((item, i) => (
                      <MenuItem value={`${item};${i}`}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <br />
            <br />
            {/* {projectType === "Infrastructure"?
          <>
          <FormControl fullWidth>
            <InputLabel id="activity">Main Activity</InputLabel>
            <Select
              labelId="mainActivity"
              id="mainActivity"
              value={props.mainActivity}
              label="mainActivity"
              onChange={event => {
                handleMainActivityChange(event);
              }}
              required
            >
              {mainActivities.map(item => (
                <MenuItem value={item.title}>{item.title}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />
          <br />
          </>
          :''
          } */}

            <FormControl fullWidth>
              <InputLabel id="activity">Activity</InputLabel>

              <Select
                labelId="activity"
                id="activity"
                value={props.activity}
                label="activity"
                onChange={event => {
                  props.handleActivity(event);
                }}
                required
              >
                {props.activities.map((item, i) => (
                  <MenuItem value={`${item.title};${i}`}>{item.title}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <div 
          style={{display:"flex", justifyContent:"center"}}> 
          <AppBar position="static"style={{backgroundColor:"white",color:"blue",width:"60%"}} >
                        <Tabs value={tabIndex} onChange={handelTabs} TabIndicatorProps={{ style: { height: "2px"} }}>
                          <Tab label="Sub activities"></Tab>
                          <Tab label="Task"></Tab>
                        </Tabs>
          </AppBar>
          </div>
            {props.activityIndex !== -1 && (
          <>
            
           <FormControl fullWidth>
           <InputLabel id="activity">Sub activity</InputLabel>

           <Select
             labelId="subactivity"
             id="subactivity"
             value={props.subAccName}
             label="subactivity"
             onChange={event => {
               props.handleSubActivity(event);
             }}
             required
           >
             {props.activities && props.activities[props.activityIndex].content && props.activities[props.activityIndex].content.map((item,i) => (
               <MenuItem value={`${item.subTitle};${i}`}>{item.subTitle}</MenuItem>
             ))}
           </Select>
         </FormControl>
         <br />
         <br />
         </>
         )}
            {props.taskList.length > 0 && tabIndex===1 && (
              <>
                <FormControl fullWidth>
                  <InputLabel id="activity">task</InputLabel>

                  <Select
                    labelId="task"
                    id="task"
                    value={props.taskName}
                    label="task"
                    onChange={event => {
                      props.handleTaskChange(event);
                    }}
                    required
                  >
                    {props.taskList.map((item, i) => (
                      <MenuItem value={`${item.name};${i}`}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <br />
                <br />
                {/* <TextField
                  name="taskcost"
                  id="taskcot"
                  variant="outlined"
                  label="Cost"
                  value={props.taskCost}
                  type="number"
                  required
                  fullWidth
                  disabled
                /> */}
              </>
            )}
            {props.items && props.contractorIndex !== -1
              ? props.items.map((item, itemIndex) => {
                  return (
                    <>
                      <Grid container spacing={1}>
                        <Grid item xs={3}>
                          <FormControl fullWidth>
                            <InputLabel id="activity">Item</InputLabel>
                            <Select
                              labelId="item"
                              id="item"
                              name="item"
                              value={item.item}
                              label="item"
                              onChange={e => {
                                props.handleItemChange(e, itemIndex);
                              }}
                              required
                            >
                              {itemlist[props.contractorIndex] &&
                                itemlist[props.contractorIndex].map(item => (
                                  <MenuItem value={`${item.item};${item.unit}`}>
                                    {item.item}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            value={item.unit}
                            margin="dense"
                            name="unit"
                            id="unit"
                            type="text"
                            variant="outlined"
                            label="Unit"
                            disabled
                            fullWidth
                            onKeyPress={event =>
                              (event.charCode >= 65 && event.charCode <= 90) ||
                              (event.charCode >= 97 && event.charCode <= 122)
                            }
                            onChange={e => props.handleItemChange(e, itemIndex)}
                            required
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            name="quantity"
                            id="quantity"
                            variant="outlined"
                            onChange={e => props.handleItemChange(e, itemIndex)}
                            label="Quantity"
                            value={item.quantity}
                            type="number"
                            margin="dense"
                            required
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            name="rate"
                            id="rate"
                            variant="outlined"
                            onChange={e => props.handleItemChange(e, itemIndex)}
                            label="Rate"
                            value={item.rate}
                            type="number"
                            margin="dense"
                            required
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            color="secondary"
                            className="mt-4"
                            onClick={() => {
                              Swal.fire({
                                title: 'Are you sure?',
                                text: "You won't be able to revert this!",
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, delete it!'
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  Swal.fire(
                                    'Deleted!',
                                    'Your file has been deleted.',
                                    'success'
                                  )
                                  props.handleDeleteItem(itemIndex);
                                }
                              })
                             
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </Grid>
                        {props.items.length - 1 === itemIndex && (
                          <Grid item xs={1}>
                            <IconButton
                              color="primary"
                              className="mt-4"
                              onClick={props.handleAddItem}
                            >
                              <AddCircleOutline />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                    </>
                  );
                })
              : ""}

            <br />
            <br />
            <TextField
              name="labourAmount"
              id="labourAmount"
              variant="outlined"
              onChange={event => {
                if (isInteger(parseInt(event.target.value))) {
                  props.handleChange(event);
                }
              }}
              label="Amount"
              value={props.contractorAmount}
              type="number"
              required
              fullWidth
              disabled
            />
            <Grid justify="center" container className="mt-4 mb-4">
              <Grid className="mr-4" item>
                <Button
                  variant="contained"
                  size="large"
                  type="submit"
                  startIcon={<Save />}
                >
                  Save
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="secondary"
                  size="large"
                  onClick={props.CancelEditContractor}
                  startIcon={<Cancel />}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
          {/* </section> */}
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default Editlabour;