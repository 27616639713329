import React, { Component } from "react";
import { connect } from "react-redux";
import OurTable from "../../Components/tableCard";
import Loading from "../../Components/Loading";
import AddIssue from "./AddIssue";
import TableIssues from "./TableIssues";
import {
  Button,
  Grid,
  Modal,
  GridList,
  GridListTile,
  Backdrop,
  Fade,
} from "@material-ui/core";
import * as action from "../../Redux/IssuesRedux/issuesAction";
import {
  PhotoCamera,
  Check,
  PanTool,
  Add,
  CloudDownload,
} from "@material-ui/icons";
// import { blue } from "@material-ui/core/colors";
import Notifications from "react-notification-system-redux";
import axios from "axios";
import { Tab } from "@material-ui/core";

class Issues extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      open: false,
      constPics: "",
      section: "",
      desc: "",
      createIssue: false,
      activityname: "",
      location: "",
      viewImages: [],
      priority: "",
      data: [],
      initialData: [],
      loading: false,
      approveImage: "",
      isOpenTable: false,
      role: global.config.secureStorage.getItem("role"),
      contractorName:"",
      debitAmount:"",
      assignUsers:[],
      viewUsers:[],
      sectionError: false,
      locationError: false,
      activitynameError: false,
      priorityError: false,
      contractorNameError: false,
      debitAmountError: false,
      descError: false,
      assignUsersError: false,
      viewUsersError: false
    };
  }


  handleChangeUser = (event, values) => {
    let newId = [];
    for (let value of values) {
      newId.push({
        UserName: value.firstName + " " + value.lastName,
        UserId: value._id,
      });
    }

    this.setState({
      assignUsers: newId,
    });
  };

  handleChangeViewUser = (event, values) => {
    let newId = [];
    for (let value of values) {
      newId.push({
        UserName: value.firstName + " " + value.lastName,
        UserId: value._id,
      });
    }

    this.setState({
      viewUsers: newId,
    });
  };

  handleContractorChange = (event) => {
    
    this.setState({
      contractorName: event.target.value,
    });
  };
  handledebitAmountChange=(event) =>{
     this.setState({
      debitAmount:event.target.value
     })
  }
  saveIssueSectionToState = event => {
    this.setState({ section: event.target.value });
  };

  saveIssueactivitytToState = event => {
    this.setState({ activityname: event.target.value });
  }
  saveIssueDescriptionToState = event => {
    this.setState({ desc: event.target.value });
  };

  savelocationToState = event => {
    this.setState({ location: event.target.value });
  };

  savepriorityToState = event => {
    const val = event.target.value > 0 ? event.target.value : 0;
    this.setState({ priority: val });
  };
  toggleCreateNewIssue = () => {
    this.setState({ createIssue: !this.state.createIssue });
    this.setState({
      sectionError:false,
      locationError:false,
      activitynameError:false,
      priorityError:false,
      contractorNameError:false,
      debitAmountError:false,
      descError:false,
      assignUsersError:false,
      viewUsersError:false
    })
  };

  toggleOpenTable = () => {
    this.setState({ isOpenTable: !this.state.isOpenTable });
  };

  toggleCloseTable = () => {
    this.setState({ isOpenTable: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };



  async reduce_image_file_size(base64Str, MAX_WIDTH = 500, MAX_HEIGHT = 500) {
    let resized_base64 = await new Promise(resolve => {
      let img = new Image();
      img.src = base64Str;
      img.onload = () => {
        let canvas = document.createElement("canvas");
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL()); // this will return base64 image results after resize
      };
    });
    return resized_base64;
  }
  calc_image_size(image) {
    let y = 1;
    if (image.endsWith("==")) {
      y = 2;
    }
    const x_size = image.length * (3 / 4) - y;
    return Math.round(x_size / 1024);
  }
  handleSubmit = async () => {
    let now = new Date();
    const date =
      now.getDate() +
      "/" +
      (parseInt(now.getMonth()) + 1).toString() +
      "/" +
      now.getFullYear();
    var image =this.state.constPics

    let resizedFile="";
    let oldSize = this.calc_image_size(image);
    // console.log("new", oldSize);
    if (oldSize > 500) {
      resizedFile = await this.reduce_image_file_size(image);
    } else {
      resizedFile = image;
    }

   let assignUsersIds = this.state.assignUsers.map(function (item) {
    return item["UserId"];
  });
  let viewUsersIds = this.state.viewUsers.map(function (item) {
    return item["UserId"];
  });
  let assignUsers = this.state.assignUsers.map(function (item) {
    return item["UserName"];
  });
  let viewUsers = this.state.viewUsers.map(function (item) {
    return item["UserName"];
  });
  let sectionError=false;
  const sectionspace = this.state.section.trim();
  let locationError=false;
  const locationspace = this.state.location.trim();
  let activitynameError= false;
  const activityspace = this.state.activityname.trim();
  let priorityError= false;
  let contractorNameError = false;
  let debitAmountError = false;
  let descError = false;
  const descspace = this.state.desc.trim();
  let assignUsersError = false
  let viewUsersError = false

  if(this.state.section.length === 0 || sectionspace === ""){
    sectionError=true
    this.setState({
      section: ""
    })
  }

  if(this.state.location.length === 0 || locationspace === ""){
    locationError=true
    this.setState({
      location: ""
    })
  }

  if(this.state.activityname.length === 0 || activityspace === ""){
    activitynameError=true
    this.setState({
      activityname: ""
    })
  }

  if(this.state.priority.length === 0){
    priorityError=true
  }

  if(this.state.contractorName.length === 0){
    contractorNameError=true
  }
  
  if(this.state.debitAmount.length === 0){
    debitAmountError=true
  }

  if(this.state.desc.length === 0 || descspace === ""){
    descError=true
    this.setState({
      desc: ""
    })
  }

  if(assignUsers.length === 0){
    assignUsersError=true
  }
  if(viewUsers.length === 0){
    viewUsersError=true
  }

    const payload = {
      assignUsersIds,
      viewUsersIds,
      assignUsers,
      viewUsers,
      issueAdmin : global.config.secureStorage.getItem("user_id"),
      date: date,
      location: this.state.location,
      priority: this.state.priority,
      section: this.state.section,
      desc: this.state.desc,
      contractorName:this.state.contractorName,
      debitAmount:this.state.debitAmount,
      image:resizedFile,
      subProjectId: global.config.secureStorage.getItem("subprojectId"),
      type: this.props.match.params.type,
    };
    if(sectionError || locationError || activitynameError || priorityError || contractorNameError || debitAmountError || descError || assignUsersError || viewUsersError){
      this.setState({
        sectionError:sectionError,
        locationError:locationError,
        activitynameError:activitynameError,
        priorityError:priorityError,
        contractorNameError:contractorNameError,
        debitAmountError:debitAmountError,
        descError:descError,
        assignUsersError:assignUsersError,
        viewUsersError:viewUsersError
      })
    }
    else{
      this.props.saveIssue(payload);
      this.toggleCreateNewIssue();
      this.setState({
        section : "",
        location :"",
        contractorName :"",
        priority :"",
        debitAmount:"",
        desc:"",
        constPics:"",
        activityname: ""
      })
    }
  };

  savePicsToState = files => {
    this.setState({ constPics: files });
    //
  };
  handelApproveImg = async file => {
    // console.log(file.toString());
    if (file == "") {
      this.setState({
        approveImage: "",
      });
    }

    let resizedFile="";
    let oldSize = this.calc_image_size(file.toString());
    // console.log("new", oldSize);
    if (oldSize > 500) {
      resizedFile = await this.reduce_image_file_size(file.toString());
    } else {
      resizedFile = file.toString();
    }


    this.setState({
      approveImage: resizedFile,
    });
  };

  componentDidMount() {
    const subProjectId = global.config.secureStorage.getItem("subprojectId");
    //
    const type = this.props.match.params.type;
    //
    this.props.getIssues(subProjectId, type);
  }

  setImages = list => {
    this.setState({
      viewImages: list,
    });
  };
  handleDropdownChange = e => {
    console.log(e.target.value);
    if (e.target.value != null) {
      this.setState({
        priority: e.target.value,
      });
    }
  };

  componentWillReceiveProps(newProps) {
    if (newProps.match.params.type !== this.props.match.params.type) {
      const subProjectId = global.config.secureStorage.getItem("subprojectId");

      this.props.getIssues(subProjectId, newProps.match.params.type);
    }
  }
  top = 50;
  left = 50;
  width = window.innerWidth > 400 ? "60%" : "100%";
  num = window.innerWidth > 1000 ? 1 : 2;
  render() {
    const { isOpenTable } = this.state;

    if (isOpenTable) {
      return (
        <TableIssues
          isApprovable={this.state.isApprovable}
          title="Issues"
          subtitle="Track Your Issues Here"
          data={this.props.issuesData}
          toggleCloseTable={this.toggleCloseTable}
        />
      );
    }
    return (
      <Grid container direction="row" style={{ flex: "1", justifyContent: "center" }}>
        <Grid className="container-sm" item xs={12}>
          <Modal
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={this.state.open}>
              <div
                className="container mt-2"
                style={{
                  position: "absolute",
                  top: `${this.top}%`,
                  left: `${this.left}%`,
                  transform: `translate(-${this.top}%, -${this.left}%)`,
                  margin: "30px auto",
                  backgroundColor: "white",
                  maxWidth: `${this.width}`,
                  padding: "20px 20px",
                  boxShadow:
                    "0px 20px 30px rgba(0, 0, 0, 0.1), 0px 8px 8px rgba(0, 0, 0, 0.15),0px 4px 4px rgba(0, 0, 0, 0.5)",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-around",
                    overflow: "hidden",
                    backgroundColor: "white",
                  }}
                >
                  <GridList
                    style={{
                      width: "100%",
                      height: 450,
                    }}
                    className="justify-content-center align-items-center"
                  >
                    {!this.state.viewImages ? (
                      <div className="d-flex justify-content-center align-items-center">
                        <h5>No Photos Uploaded</h5>
                      </div>
                    ) : (
                      this.state.viewImages.map((ele, key, index) => {
                        return (
                          <img
                            src={ele}
                            alt={"Contruction" + key.toString()}
                            style={{ width: "100%", height: "auto" }}
                          // width="100%"
                          // height="100%"
                          />
                        );
                      })
                    )}
                  </GridList>
                </div>
                <br />
                <Grid
                  style={{ flex: "1" }}
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                // className="justify-content-center align-items-center"
                >
                  <Grid item>
                    <Button color="primary" onClick={this.handleClose}>
                      Close
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Fade>
          </Modal>
        </Grid>
        {this.props.notifications && (
          <Notifications notifications={this.props.notifications} />
        )}
      
            {this.state.createIssue ? (
              <AddIssue
              handleChangeUser={this.handleChangeUser}
              handleChangeViewUser={this.handleChangeViewUser}
                classes={this.classes}
                desc={this.state.desc}
                section={this.state.section}
                priority={this.state.priority}
                location={this.state.location}
                constPics={this.state.constPics}
                saveIssueSectionToState={this.saveIssueSectionToState}
                saveIssueDescriptionToState={this.saveIssueDescriptionToState}
                saveIssueactivitytToState = {this.saveIssueactivitytToState}
                handleSubmit={this.handleSubmit}
                savepriorityToState={this.savepriorityToState}
                toggleCreateNewIssue={this.toggleCreateNewIssue}
                savelocationToState={this.savelocationToState}
                savePicsToState={this.savePicsToState}
                handleDropdownChange={this.handleDropdownChange}
                contractorName={this.state.contractorName}
                debitAmount={this.state.debitAmount}
                handleContractorChange={this.handleContractorChange}
                handledebitAmountChange={this.handledebitAmountChange}
                sectionError={this.state.sectionError}
                locationError={this.state.locationError}
                activitynameError={this.state.activitynameError}
                priorityError={this.state.priorityError}
                contractorNameError={this.state.contractorNameError}
                debitAmountError = {this.state.debitAmountError}
                descError = {this.state.descError}
                assignUsersError = {this.state.assignUsersError}
                viewUsersError = {this.state.viewUsersError}
              />
            ) : (
              <Grid
                container
                direction="row"
                style={{ margin: "10px" }}
                justify="space-around"
              >
                <Grid item>
                  <Button
                    style={{ margin: "10px 0px" }}
                    onClick={this.toggleCreateNewIssue}
                    size="large"
                    startIcon={<Add />}
                  >
                    Add Issue
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    style={{ margin:"10px 0px", backgroundColor: "#4a934a" }}
                    onClick={this.toggleOpenTable}
                    size="large"
                    startIcon={<CloudDownload />}
                  >
                    Generate PDF
                  </Button>
                </Grid>
              </Grid>
            )}
         
        <Loading loading={this.props.loading} />
        {!this.props.loading && !this.state.createIssue && (
          <Grid container direction="row" style={{ marginTop: "1em"}}>
            <Grid item xs={12}>
              {console.log(this.props.issuesData)}
              <OurTable
                handelApproveImg={this.handelApproveImg}
                approveImage={this.state.approveImage}
                title="Issues"
                subtitle="Track Your Issues Here"
                data={this.props.issuesData}
                setImages={this.setImages}
                handleOpen={this.handleOpen}
                removeIssue={this.props.removeIssue}
                askApproval={this.props.askApproval}
                type={this.props.match.params.type}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  //
  return {
    loggedIn: state.loginReducer.loggedIn,
    issuesData: state.issuesReducer.data,
    loading: state.issuesReducer.loading,
    notifications: state.notifications,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    saveIssue: payload => dispatch(action.createIssue(payload)),
    getIssues: (subProjectId, type) =>
      dispatch(action.getIssues(subProjectId, type)),
    removeIssue: (id, subprojectId, type) =>
      dispatch(action.removeIssue(id, subprojectId, type)),
    askApproval: (id, subProjectId, type, approveImage) =>
      dispatch(action.askApproval(id, subProjectId, type, approveImage)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Issues);
