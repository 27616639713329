import React, { Component } from "react";
import {
  getMasterListEquipments,
  updateEquipmentItem,
  deleteEquipmentItem,
} from "../../Redux/storeRedux/storeAction";
import { connect } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import {
  Dialog,
  TextField,
  DialogActions,
  DialogContent,
  useMediaQuery,
  DialogContentText,
  Button,
  DialogTitle,
  CardContent,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Card,
} from "@material-ui/core";
import _ from "lodash";
import Editlist from "./Editlist";
import Loading from "../../Components/Loading";
//import Box from '@mui/material/Box';
//import { DataGrid } from '@mui/x-data-grid';
import MUIDataTable from "mui-datatables";
import Swal from "sweetalert2";
import {
  ArrowForwardIos as ArrowForwardIosIcon,
  Add as AddIcon,
  Edit,
  Delete,
  ExpandMore as ExpandMoreIcon
} from "@material-ui/icons";
import "./style-masterlist.css";
class DisplayMaterial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      store: [],
      open: false,
      itemName: "",
      rate: 0,
      unit: "",
      editIndex: 0,
      nameError: false,
    };
  }
  handleFieldsChange = async (name, value) => {
    let found = false;
    if (name === "itemName") {
      this.props.storeData.forEach(material => {
        if (material.materialName === value.trim()) {
          found = true;
          return;
        }
      });
      if (found) {
        this.setState({ nameError: true });
        //return;
      } else {
        this.setState({ nameError: false });
      }
    }
    if (this.state[name] != value)
      await this.setState({
        [name]: value,
      });

    console.log("staet is ", this.state[name], value);
  };
  async componentDidUpdate() {
    if (this.state.store.length !== this.props.equipmentData.length) {
      this.setState({
        store: this.props.equipmentData,
      });
    }
  }
  async componentDidMount() {
    await this.props.getMasterListEquipments();
    if (!this.props.getStoreLoading) {
      this.setState({
        store: this.props.equipmentData,
      });
    }
  }
  deleteMaterial = i => {
    let id = this.state.store[i]._id;
    var arr = [...this.state.store];
    arr.splice(i, 1);
    this.props.deleteEquipmentItem(id);
  };
  handleEdit = (itemName, unit, rate, rowIndex) => {
    this.setState({
      itemName,
      rate,
      unit,
      open: true,
      editIndex: rowIndex,
    });
  };
  handleModalOpen = () => {
    this.setState({
      open: false,
      itemName: "",
      rate: 0,
      unit: "",
      editIndex: 0,
      nameError: false,
    });
  };
  handlesubmit = () => {
    if(this.state.nameError) return
    let new_items = this.state.store;
    new_items[this.state.editIndex] = {
      ...new_items[this.state.editIndex],
      equipmentName: this.state.itemName,
      equipmentRate: this.state.rate,
      equipmentUnit: this.state.unit,
    };
    let id = this.state.store[this.state.editIndex]._id;
    this.props.updateEquipmentItem(id, new_items[this.state.editIndex]);

    this.setState({
      open: false,
      store: new_items,
    });
  };
  columns = [
    {
      name: "SN",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowIndex + 1;
        }
      }
    },
    {
      name: "equipmentName",
      label: "Equipment",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "equipmentUnit",
      label: "Unit",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "equipmentRate",
      label: "Rate",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Edit",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <button
              onClick={() => {
                console.log("values of edit", value, tableMeta);
                this.handleEdit(
                  tableMeta.rowData[0],
                  tableMeta.rowData[1],
                  tableMeta.rowData[2],
                  tableMeta.rowIndex
                );
              }}
              style={{
                background:"#345BAE",
                color:'white',
                border:"1px solid transparent",
                borderRadius: "3px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Edit  style={{width: ".65em", height:".65em", marginRight:"2px"}} />
              Edit
            </button>
          );
        },
      },
    },
    {
      name: "Delete",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <button onClick={() => 
              Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
              }).then((result) => {
                if (result.isConfirmed) {
                  this.deleteMaterial(tableMeta.rowIndex)
                  Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                  )
                }
              })
            }
            style={{
              background:"#F24236",
              color:'white',
              border:"1px solid transparent",
              borderRadius: "3px",
              display: "flex",
              alignItems: "center",
            }}>
              <Delete  style={{width: ".65em", height:".65em", marginRight:"2px"}} />
              Delete
            </button>
          );
        },
      },
    },
  ];

  render() {
    return (
      <>
        {this.props.getStoreLoading ? (
          <Loading loading={this.props.loading} />
        ) : (
          <div className="container mt-2" style={{ marginBottom: "1em" }}>
            {/* <h3 className="mt-4 mb-3"></h3> */}
            <Accordion style={{ margin: "20px auto" }}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    fontSize="large"
                    color="secondary"
                  />
                }
                aria-controls="panel1d-content"
                id="panel1d-header"
                style={{
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                <Grid
                  container
                  spacing={0}
                  alignItems="center"
                  justify="space-between"
                >
                  <Typography variant="h6">Equipments Registered</Typography>
                </Grid>
              </AccordionSummary>
              <AccordionDetails style={{display:"block"}}>
              <Editlist
                  itemName={this.state.itemName}
                  unit={this.state.unit}
                  rate={this.state.rate}
                  open={this.state.open}
                  handleModalOpen={this.handleModalOpen}
                  handlesubmit={this.handlesubmit}
                  handleFieldsChange={this.handleFieldsChange}
                  nameError={this.state.nameError}
                  title={"Equipment"}
              />
                {this.state.store.length > 0 && (
                  <MUIDataTable
                    title={`Equipments`}
                    data={this.state.store}
                    columns={this.columns}
                    options={{
                      rowsPerPage:[10],
                      rowsPerPageOptions:false,
                      textLabels:{
                        pagination: {
                          next: "Next >",
                          previous: "< Previous",
                          displayRows: "OF"
                        }
                      },
                      selectableRows: "none",
                      responsive: "verticle",
                      download: false,
                      viewColumns: false,
                      filter: true,
                      print: false,
                    }}
                  />
                )}
              </AccordionDetails>
            </Accordion>
          </div>
        )}
      </>
    );
  }
}
//

const mapStateToProps = state => {
  return {
    //   check weather getStoreloading ??
    getStoreLoading: state.storeReducer.getStoreLoading,
    equipmentData: state.storeReducer.equipmentData,
  };
};

export default connect(mapStateToProps, {
  getMasterListEquipments,
  updateEquipmentItem,
  deleteEquipmentItem,
})(DisplayMaterial);
