import React, { useState } from "react";

import { useTheme } from "@material-ui/core/styles";
import {
  makeStyles,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import DialogBox from "../../Components/Modal/DialogBox";
const useStyles = makeStyles({
  btn: {
    padding: 20,
    backgroundColor: "#333",
    color: "#fff",
  },
  title: {
    backgroundColor: "#345BAE",
    padding: "11px 10px",
    color: "#fff",
    borderRadius: 11,
  },
  footer: {
    width: "100%",
    textAlign: "center",
  },
  footerlink: {
    backgroundColor: "#345BAE",
    padding: "11px 10px",
    borderRadius: 11,
    color: "#fff",
  },
  getcol: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "100%",
  },
  noUpdates: {
    padding: "1em",
    textAlign: "center",
  },
});

export default function VerticleList(props) {
  // console.log(props.activityDetails);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modelImageUrl, setModelImageUrl] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const classes = useStyles();
  const handleShowDialog = url => {
    if (!isModalOpen) setModelImageUrl(url);
    setIsModalOpen(!isModalOpen ? true : false);
  };
  return (
    <div  style={{marginTop : '9px', background : '#ffffff', borderRadius : '10px', padding : '10px', boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)'}}>
      <DialogBox
        handleShowDialog={handleShowDialog}
        isModalOpen={isModalOpen}
        modelImageUrl={modelImageUrl}
      />
      <div className={classes.title} style = {{backgroundColor : 'transparent', padding : 0}}>
        <div style={{fontWeight : 600, fontSize : '24px', color : 'rgba(0, 0, 0, 0.87)'}}>Latest Updates</div>
      </div>
      <div style={{maxHeight : '200px', overflow : 'auto'}}>
        {props.activityDetails.records ? (
          props.activityDetails.records.slice(0, 3).map((detail, detailIndex) => {
            return (
              <Card
                variant="outlined"
                style={{ margin: "5px", padding: "5px" }}
                elevation={3}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={8} md={8}>
                      <Typography variant="p" className={classes.getcol}>
                        Updated by:{detail.data[0].user}
                        {/* {detail.user} */}
                      </Typography>
                      <Typography variant="p" className={classes.getcol}>
                        Update Date :{detail.data[0].date}
                      </Typography>

                      <Typography variant="p" className={classes.getcol}>
                        Activity Name:
                        {detail.data[0].activityName}
                      </Typography>
                      <Typography variant="p" className={classes.getcol}>
                        Update Percentage:
                        {detail.data[0].updatedPercentage}%
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={4} style={{display : 'flex', justifyContent : 'center', alignItems : 'center'}}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ padding: "5px" }}
                        onClick={() => {
                          if (detail.data[0].full_panaroma_url)
                            handleShowDialog(detail.data[0].full_panaroma_url);
                          else handleShowDialog(detail.data[0].panaroma);
                        }}
                      // startIcon={<Info />
                      >
                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M39.5833 6.25H10.4167C8.11548 6.25 6.25 8.11548 6.25 10.4167V39.5833C6.25 41.8845 8.11548 43.75 10.4167 43.75H39.5833C41.8845 43.75 43.75 41.8845 43.75 39.5833V10.4167C43.75 8.11548 41.8845 6.25 39.5833 6.25Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M17.7085 20.8333C19.4344 20.8333 20.8335 19.4342 20.8335 17.7083C20.8335 15.9825 19.4344 14.5833 17.7085 14.5833C15.9826 14.5833 14.5835 15.9825 14.5835 17.7083C14.5835 19.4342 15.9826 20.8333 17.7085 20.8333Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M43.7498 31.25L33.3332 20.8333L10.4165 43.75" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            );
          })
        ) : (
          <div className={classes.noUpdates}>
            <Typography variant="p">Updates Not Available</Typography>
          </div>
        )}
      </div>
      {props.activityDetails.records && props.activityDetails.records.length > 0 ?
        <div className={classes.footer} style={{marging : '10px 0px 0px 0px'}}>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              state: {
                activityDetails: props.activityDetails,
              },
              pathname: `/overview/viewupdates`,
            }}
          >
            <Typography variant="p" className={classes.footerlink}>
              View More {">>"}
            </Typography>
          </Link>
        </div> : ''}


    </div>
  );
}
