import "date-fns";
import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Button,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TableContainer,TableFooter
} from "@material-ui/core";
import Style from "../style.module.scss";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MuiTableCell: {
        root: {
          border: "1px solid #333",
          borderBottom: "1px solid #333",
          verticalAlign: "baseline",
          height: "100%",
          padding: "5px",
        },
        // body:{
        //   maxWidth: '600px',
        // },
      },

      MuiToolbar: {
        height: "40px",
      },
    },
  });
const ContractorRaReport = props => {
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState(props.startDate);
  const [endDate, setEndDate] = useState(props.endDate);
  const [smallScreen, setSmallScreen] = useState(window.innerWidth <= 760);

  const [totalQuantity, setTotalQuantity] = useState(0);
  let columns = [];
  if (props.ReportFormat == "Contractor Wise") {
    columns = [
      {
        name: "mat",
        label: "Contractor Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "masadt",
        label: "Values",
        options: {
          filter: true,
          sort: true,
        },
      },
    ];
  } else if (props.ReportFormat == "Item Wise") {
    columns = [
      {
        name: "mat",
        label: "Item Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "masadt",
        label: "Values",
        options: {
          filter: true,
          sort: true,
        },
      },
    ];
  }

  useEffect(() => {
    const tempData = [];
    props.newWbs.forEach((elem, id) => {
      let row = [];
      if (props.ReportFormat == "Contractor Wise") {
        let totalQuantitytemp = 0
        elem.elm.map((item, index) => {
          if(item.number !='' ){
            if(item.number)
            totalQuantitytemp=parseFloat(totalQuantitytemp)+parseFloat(item.number)
          }
          item.serial = index + 1;
        });
        row.push(
          elem.mat,
          <div>
            <MuiThemeProvider theme={getMuiTheme()}>

            <MUIDataTable
                data={elem.elm}
                columns={[ {
                  name: "serial",
                  label: "Serial No"
                 },{
                  name: "date",
                  label: "Date",
                },
                {
                  name: "item",
                  label: "Item",
                },
                {
                  name: "number",
                  label: "Quanity",
                },
                {
                  name: "unit",
                  label: "unit",
                },
                ]
                }
                options={{
                  selectableRows: "none",
                  responsive: smallScreen ? "scroll" : "simple",
                  pagination: false,
                  download: false,
                  viewColumns: false,
                  filter: true,
                  print: false,
                  customFooter: (
                    ) => {
                      return (
                        <TableFooter>
                          <TableRow>
                            <TableCell variant="h4" colSpan={6} style={{float:'right',border:'none'}}>
                            Total Quantity:{totalQuantity ? totalQuantity.toFixed(2):totalQuantitytemp}
                            </TableCell>
                          </TableRow>
                        </TableFooter>
                      );
                    },
                    onFilterChange: (changedColumn,filterList,type,changedColumnIndex,displayData)=>{
                      let templist= []
                      displayData.forEach(ele1 => {
                        
                        console.log(ele1)
                        templist.push({number:ele1.data[3]})
                      });

                      let totalequipmentQuantity = 0
                      templist.map((item, index) => {
                        if(item.number !='' ){
                          if(item.number)
                          totalequipmentQuantity=parseFloat(totalequipmentQuantity)+parseFloat(item.number)
                        }
                      });
                      setTotalQuantity(totalequipmentQuantity)
                    }
                }}
              />
            
            </MuiThemeProvider>
          </div>
        );
      } else if (props.ReportFormat == "Item Wise") {
        let totalQuantitytemp = 0
        elem.elm.map((item, index) => {
          if(item.number !='' ){
            if(item.number)
            totalQuantitytemp=parseFloat(totalQuantitytemp)+parseFloat(item.number)
          }
          item.serial = index + 1;
        });
        row.push(
          elem.mat,
          <div>
            <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
                data={elem.elm}
                columns={[ {
                  name: "serial",
                  label: "Serial No"
                 },{
                  name: "date",
                  label: "Date",
                },
                {
                  name: "contractor",
                  label: "Contractor",
                },
                {
                  name: "number",
                  label: "Quanity",
                },
                {
                  name: "unit",
                  label: "unit",
                },
                ]
                }
                options={{
                  selectableRows: "none",
                  responsive: smallScreen ? "scroll" : "simple",
                  pagination: false,
                  download: false,
                  viewColumns: false,
                  filter: true,
                  print: false,
                  customFooter: (
                    ) => {
                      return (
                        <TableFooter>
                          <TableRow>
                            <TableCell variant="h4" colSpan={6} style={{float:'right',border:'none'}}>
                            Total Quantity:{totalQuantity ? totalQuantity.toFixed(2):totalQuantitytemp}
                            </TableCell>
                          </TableRow>
                        </TableFooter>
                      );
                    },
                    onFilterChange: (changedColumn,filterList,type,changedColumnIndex,displayData)=>{
                      let templist= []
                      displayData.forEach(ele1 => {
                        templist.push({number:ele1.data[3]})
                      });

                      let totalequipmentQuantity = 0
                      templist.map((item, index) => {
                        if(item.number !='' ){
                          if(item.number)
                          totalequipmentQuantity=parseFloat(totalequipmentQuantity)+parseFloat(item.number)
                        }
                      });
                      setTotalQuantity(totalequipmentQuantity)
                    }
                }}
              />
            </MuiThemeProvider>
          </div>
        );
      }

      tempData.push(row);
    });

    setData(tempData);
  }, [totalQuantity]);

  return (
    <>
      <div className="container" id="download-section">
        <Grid container className={Style.header___top}>
          <Typography
            variant="h4"
            color="textSecondary"
            style={{ marginLeft: "0.5em", color: "#fff" }}
          >
            Enbuild
          </Typography>
        </Grid>
        <Grid container className={Style.container___info}>
          <Grid item xs={3}>
            <Typography
              variant="h6"
              color="textSecondary"
              style={{ marginLeft: "0.5em" }}
            >
              Generated By
            </Typography>
            <Typography variant="h6" style={{ marginLeft: "0.5em" }}>
              {global.config.secureStorage.getItem("user_fullname")}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography
              variant="h6"
              color="textSecondary"
              style={{ marginLeft: "0.5em" }}
            >
              Generated On
            </Typography>
            <Typography variant="h6" style={{ marginLeft: "0.5em" }}>
              {new Date(new Date()).toISOString().split("T")[0]}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="h6"
              color="textSecondary"
              style={{ marginLeft: "0.5em" }}
            >
              Start Date
            </Typography>
            <Typography variant="h6" style={{ marginLeft: "0.5em" }}>
              {startDate}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="h6"
              color="textSecondary"
              style={{ marginLeft: "0.5em" }}
            >
              End Date
            </Typography>
            <Typography variant="h6" style={{ marginLeft: "0.5em" }}>
              {endDate}
            </Typography>
          </Grid>
        </Grid>

        <Grid container className={Style.container___info}>
          <Grid item xs={12}>
            <Typography variant="h6" style={{ marginLeft: "0.5em" }}>
            {
                props.ReportFormat == "Contractor Wise"
                  ? `Contractor wise item report`
                  : "Item wise Contractor  report"
              }
            </Typography>
            <Typography
              variant="p"
              color="textSecondary"
              style={{ marginLeft: "0.5em" }}
            >
              {/* This Report is from {props.startDate} to {props.endDate} */}
            </Typography>
          </Grid>
        </Grid>
        <hr />

        <div className={Style.table___box}>
          {props.newWbs !== undefined && (
            <MUIDataTable
              title={
                props.ReportFormat == "Contractor Wise"
                  ? `Contractor wise item report`
                  : "Item wise Contractor  report"
              }
              data={data}
              columns={columns}
              options={{
                selectableRows: "none",
                responsive: smallScreen ? "scroll" : "simple",
                pagination: false,
                download: false,
                viewColumns: false,
                filter: false,
                print: false,
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default ContractorRaReport;
