import React, { Component } from "react";
import { Button, Grid,Typography } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import  MaterialReport  from "./MaterialReport";
import isEqual from 'lodash/isEqual';
import {
      getStore,
    getMaterial,
    getMaterialBySuperProj
  } from "../../../Redux/materialRedux/materialAction";

class MaterialTracking extends Component {
  constructor(props) {
    super(props);
    const editRoles = ["store_manager", "super_admin"];
    const role = global.config.secureStorage.getItem("role");
    const isEditable = editRoles.find(item => item == role) ? true : false;
      
    this.state = {
      materialDetails: [],
   
    };
  }
  async componentDidMount() {   
    await this.props.getMaterialBySuperProj();
    this.setState({ equipmentData: this.props.data });
  }
  // async componentDidUpdate() {
  //     if(this.props.data.length>0 && this.state.materialDetails.length==0){
  //         let newData = [];
        

  //       this.props.data.forEach(el =>{
  //           if(el.approvalHistory && el.approvalHistory.length===3)
  //           newData.push(el)
  //         });
  //         console.log(newData)
  //       this.setState({ materialDetails: newData });
  //   }
  // }
  async componentDidUpdate() {
    if (this.props.data.length > 0 && this.state.materialDetails.length === 0) {
      let newData = [];
  
      this.props.data.forEach((el) => {
        if (el.approvalHistory && el.approvalHistory.length === 3) {
          newData.push(el);
        }
      });
  
      if (newData.length > 0 && !isEqual(newData, this.state.materialDetails)) {
        console.log(newData);
        this.setState({ materialDetails: newData });
      }
    }
  }
  
 
  render() {
    return (
       <>  
       <Grid container justify="center">
       <Grid item xs={12}>
         {this.state.materialDetails.length > 0 ? (
          <MaterialReport materialDetails={this.state.materialDetails}/>
         ): (
         <div style={{textAlign:'center',marginTop:'40px'}}>  <Typography variant="h4">
         <span>
         Requisition Not Received
         </span>
       </Typography></div>
         )}
       </Grid>
     </Grid></>
      );
    
  }
}


const mapStateToProps = state => {
  return {
    storeData: state.storeReducer.storeData,
    data: state.materialReducer.data,
  };
};

export default connect(mapStateToProps, {  getStore,getMaterialBySuperProj})(MaterialTracking);
