import {Typography,Grid,Button,Dialog,DialogTitle,makeStyles,useMediaQuery,useTheme,DialogContent,DialogContentText,TextField,InputLabel,Select,MenuItem,DialogActions,Snackbar,Slide,FormControl,IconButton,InputAdornment,Divider,Tabs, Tab, AppBar, FormControlLabel, Checkbox} from "@material-ui/core";
import Swal from 'sweetalert2';
import { Add as AddIcon, AddCircleOutline, Delete, CheckBox } from "@material-ui/icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ProgressCard from "./ProgressCard";
import DailyProgressCards from "./DailyProgressCards";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Alert } from "@material-ui/lab";
import _ from "lodash";
import '../Checklist/style.css';
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
}));
const DailyProgressReport = props => {
  const [dpr, setDpr] = useState({});
  const [endDateErr, setEndDateErr] = useState([]);
  const [dates, setDates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addClicked, setAddClicked] = useState(false);
  const [date, setDate] = useState(new Date());
  const [activity, setActivity] = useState("");
  const [activityError, setActivityError] = useState(false);
  const [description, setDescription] = useState("");
  const [qty, setQty] = useState("");
  const [contractor, setContractor] = useState([]);
  const [numberOfContractor, setNumberOfContractor] = useState(1);
  const [numberOfManpower, setNumberOfManpower] = useState(1);
  const [manpower, setManpower] = useState([]);
  const [itemlist, setItemlist] = useState([]);
  const [items, setItems] = useState([]);
  const [contractorName, setContractorName] = useState("");
  const [clicked, setClicked] = useState(null);
  const [openAddSuccess, setOpenAddSuccess] = useState(false);
  const [openAddFailure, setOpenAddFailure] = useState(false);
  //Material
  const [material, setMaterial] = useState([]);
  const [numberOfMaterial, setNumberOfMaterial] = useState(1);
  const [materialData, setmaterialData] = useState([]);
  const [labourData, setLabourData] = useState([]);
  const [manpowerData, setmanpowerData] = useState([]);
  const [equipmentData, setEquipmentData] = useState([]);
  const [SubAccName,setSubAccName]=useState("")
  const [SubAccNameError,setSubAccNameError]=useState(false)
  const [taskList,setTaskList]=useState([])
  const [taskName,setTaskName]=useState("")
  const [taskNameError,setTaskNameError]=useState(false)
  const [activityIndex, setActivityIndex] = useState(-1);
  const [subAccIndex, setSubAccIndex] = useState(-1);
  const [suppliersDetails, setSuppliersDetails] = useState([]);
  const [materialsuppliersDetails, setMaterialSuppliersDetails] = useState([]);
  const [activityData, setactivityData] = useState([]);
  const [miscellaneous, setMiscellaneous] = useState([]);
  const [numberOfMiscellaneous, setNumberOfMiscellaneous] = useState(1);
  const [equipment, setEquipment] = useState([]);
  const [numberOfEquipment, setNumberOfEquipment] = useState(1);
  const [metaData, setMetaData] = useState([]);
  const [numberOfMetaData, setNumberOfMetaData] = useState(1);
  const [partyDetails, setPartyDetails] = useState([]);
  const [numberOfPartyDetails, setNumberOfPartyDetails] = useState(1);
  const [state, setState] = useState({
    supNErrors: [],
    dcNoErrors: [],
    quanErrors: [],
    locaErrors: [],
    remaErrors: [],
    mateErrors: [],
    workErrors: [],
    worqErrors: [],
    worUErrors: [],
    conNErrors: [],
    conIErrors: [],
    conQErrors: [],
    matNErrors: [],
    matMErrors: [],
    matQErrors: [],
    equEqError: [],
    equQErrors: [],
    equMErrors: [],
    equSErrors: [],
    equEErrors: [],
    equUErrors: [],
    equDErrors: [],
    equTErrors: [],
    equRErrors: [],
    labLErrors: [],
    labAErrors: [],
    labRErrors: [],
    labSErrors: [],
    misCErrors: [],
    misRErrors: [],
    misRRErrors:[],
  });
  const [applyconval, setApplyConval] = useState(false);
  const [applymatval, setApplyMatval] = useState(false);
  const [applyequval, setApplyEquval] = useState(false);
  const [applylabval, setApplyLabval] = useState(false);
  const [applymisval, setApplyMisval] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [contractorNames, setContractorNames] = useState([]);
  const [contractorIndex, setContractorIndex] = useState([]);
  const [remark, setRemark] = useState("");
  const[ tabIndex,setTabIndex]=useState(0)
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    getDprData();
    libraryData();
    activityNames();
    let new_contractor = [
      {
        contractor: "",
        items: [
          {
            item: "",
            quantity: "",
            unit: "",
          },
        ],
      },
    ];
    let new_items = [
      [
        {
          item: "",
          number: "",
          unit: "",
        },
      ],
    ];
    setItems(new_items);
    setContractor(new_contractor);
  }, []);
  const handelTabs = (e, val) => {
    setTabIndex(val);
    setTaskName("")
    setTaskList("")
    setSubAccName("")
    setSubAccIndex("")
  }
  const handleItemNameChange = (e, i_index, c_index) => {
    let new_items = [...items];
    new_items[c_index][i_index] = {
      item: e.target.value,
      unit: e.target.value.split(";")[1],
      number: new_items[c_index][i_index].number,
    };
    setItems(new_items);
  };
  const handleItemNumberChange = (e, i_index, c_index) => {
    let new_items = [...items];
    new_items[c_index][i_index] = {
      item: new_items[c_index][i_index].item,
      unit: new_items[c_index][i_index].unit,
      number: e.target.value,
    };
    setItems(new_items);
  };
  const handleContractorChange = (event, index) => {
    setApplyConval(!applyconval);
    let temp = [...contractorNames];
    let temp_index = [...contractorIndex];
    temp_index[index] = event.target.value.split(";")[1];

    temp[index] = event.target.value;
    setContractorName(event.target.value);
    setContractorNames(temp);
    setContractorIndex(temp_index);
    let temp_items = [...items];
    temp_items[index] = [
      {
        item: "",
        unit: "",
        number: "",
      },
    ];
    setItems(temp_items);
    console.log("indes  is ", contractorIndex, event.target.value);
  };
  async function getDprData() {
    let projectId = await global.config.secureStorage.getItem("projectId");
    let superProject=global.config.secureStorage.getItem("superProject")
    try {
      const res = await axios.post(
        `${global.config.backendURL}/dpr/getAllDpr`,
        {
          projectId:projectId,
        }
      );
      let datesArr = Object.keys(res.data);
      setDates(datesArr);
      setDpr(res.data);
      setLoading(false);
      setDate(new Date());

    } catch (err) {
      console.log(err);
    }
  }

  async function libraryData() {
    let projectId = await global.config.secureStorage.getItem("projectId");
    setLoading(true);
    try {
      const res = await axios.post(
        `${global.config.backendURL}/labour/getContractor`,
        {
          projectId: projectId,
        }
      );
      let new_itemlist = [];
      res.data.forEach(con => {
        let temp = [];
        con.items.forEach(it => {
          temp.push({
            item: it.item,
            unit: it.unit,
            number: "",
          });
        });
        new_itemlist.push(temp);
      });
      setItemlist(new_itemlist);
      setLabourData(res.data);
      console.log("labour data i s", labourData);
      const res1 = await axios.post(
        `${global.config.backendURL}/equipment/getEquipment`,
        {
          projectId: projectId,
        }
      );
      setEquipmentData(res1.data);

      const res2 = await axios.post(
        `${global.config.backendURL}/library/getLibrary`,
        {
          projectId: projectId,
        }
      );
      setmaterialData(res2.data);
      const id = global.config.secureStorage.getItem("superProject");
      const res3 = await axios.get(
        `${global.config.backendURL}/stores/getMasterListManforce/${id}`
      );
      setmanpowerData(res3.data);
      console.log("man power data", manpowerData);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }

  async function activityNames() {
    let projectId = await global.config.secureStorage.getItem("projectId");
    
    let subprojectId = global.config.secureStorage.getItem("subprojectId");
    setLoading(true);

    const activitiesData = await axios.get(
      `${global.config.backendURL}/activities/getDefaultActivities/${projectId}`
    );
    let final = [];
    activitiesData.data.data.forEach(element => {
      if (element.visibility) final.push(element);
    });

    setactivityData(final);
    setLoading(false);
    const getSupplierNames = await axios.get(
      `${global.config.backendURL}/dpr/getSupplierNames/${subprojectId}/${projectId}`
    );

    setSuppliersDetails(getSupplierNames.data)

    const getMaterialSupplierNames = await axios.get(
      `${global.config.backendURL}/dpr/getMaterialSupplierNames/${subprojectId}/${projectId}`
    );

    setMaterialSuppliersDetails(getMaterialSupplierNames.data)
  }

  const handleClick = e => {
    e.preventDefault();
    setAddClicked(true);
  };
  const handleSubActivity = async event => {
    let new_task;

    try {
      let index = event.target.value.split(";")[1];
      let subAccLink =
      activityData[activityIndex].content[index].link;
      const subprojectId = global.config.secureStorage.getItem("subprojectId");
      const res = await axios.get(
        `${global.config.backendURL}/activities/getActivity?activity=${subAccLink}&subprojectId=${subprojectId}`
      );
      new_task = res.data;
    } catch (err) {
      console.log("err is ", err);
    }
    setSubAccName(event.target.value)
    setSubAccIndex(event.target.value.split(";")[1])
    setTaskList(new_task)
    setTaskName("")
  };
  const handleTaskChange = event => {
    let index = event.target.value.split(";")[1];
    setTaskName(event.target.value)
  };
  const addButtonHandler = async () => {
    if(applyconval || applyequval || applylabval || applymatval || applymisval){

      let activityError= false;
      let SubAccNameError= false;
      let multipleError= false
  
      if(activity.length === 0 || activity.trim() === ""){
        activityError = true;
      }
      if(SubAccName.length === 0){
        SubAccNameError = true;
      }
      const conNErrors = [];
      const conIErrors = [];
      const conQErrors = [];
      const matMErrors = [];
      const matQErrors = [];
      const equEqError = [];
      const equQErrors = [];
      const equMErrors = [];
      const equSErrors = [];
      const equEErrors = [];
      const equUErrors = [];
      const equDErrors = [];
      const equTErrors = [];
      const equRErrors = [];
      const labLErrors = [];
      const labAErrors = [];
      const labRErrors = [];
      const misCErrors = [];
      const misRErrors = [];
      const misRRErrors= [];
        let contractorErrors = false;
        let materialErrors = false;
        let equipmentErrors = false;
        let laborErrors = false;
        let miscellaneousErrors = false;
        _.times(numberOfContractor, (index, i_index) => {
          if(applyconval){
            const conNField = document.getElementById(`contractorN-${index}`).nextElementSibling;
            const conIField = document.getElementById(`conI-${index}`).nextElementSibling;
            const conQField = document.getElementById(`conQuality-${index}`);
    
            const conNFieldvalue = conNField ? conNField.value : "";
            const conIFieldvalue = conIField ? conIField.value : "";
            const conQFieldvalue = conQField ? conQField.value : "";
    
            const conNEmpty = conNFieldvalue.trim() === "";
            const conIEmpty = conIFieldvalue.trim() === "";
            const conQEmpty = conQFieldvalue.trim() === "";
    
            conNErrors[index] = conNEmpty;
            conIErrors[index] = conIEmpty;
            conQErrors[index] = conQEmpty;
    
            if(conNEmpty || conIEmpty || conQEmpty){
              contractorErrors = true;
            }else{
              contractorErrors = false;
            }
          }else{
            conNErrors[index] = false;
            conIErrors[index] = false;
            conQErrors[index] = false;
            contractorErrors = false;
          }
        });
  
        _.times(numberOfMaterial, (index) => {
          if(applymatval){
            const matMField = document.getElementById(`matM-${index}`).nextElementSibling;
            const matQField = document.getElementById(`matQ-${index}`);
            const matMFieldValue = matMField ? matMField.value : "";
            const matQFieldValue = matQField ? matQField.value : "";
            const matMEmpty = matMFieldValue.trim() === "";
            const matQEmpty = matQFieldValue.trim() === "";
            matMErrors[index] = matMEmpty;
            matQErrors[index] = matQEmpty;
    
            if(matMEmpty || matQEmpty){
              materialErrors = true;
            }else{
              materialErrors = false;
            }
          }else{
            matMErrors[index] = false;
            matQErrors[index] = false;
            materialErrors = false;
          }
        });
  
        _.times(numberOfEquipment, (index) => {
          if(applyequval){
            const equEqField = document.getElementById(`equE-${index}`).nextElementSibling;
            const equqField = document.getElementById(`equq-${index}`);
            const equMField = document.getElementById(`equM-${index}`).nextElementSibling;
            const equsField = document.getElementById(`equStart-${index}`);
            const equeField = document.getElementById(`equEnd-${index}`);
            const equuField = document.getElementById(`equUnit-${index}`);
            const equdField = document.getElementById(`equDiesel-${index}`);
            const equtField = document.getElementById(`equTrip-${index}`);
            const equrField = document.getElementById(`equRemark-${index}`);
    
            const equEqFieldvalue = equEqField ? equEqField.value : "";
            const equqFieldvalue = equqField ? equqField.value : "";
            const equMFieldvalue = equMField ? equMField.value : "";
            const equsFieldValue = equsField ? equsField.value : "";
            const equeFieldValue = equeField ? equeField.value : "";
            const equuFieldValue = equuField ? equuField.value : "";
            const equdFieldValue = equdField ? equdField.value : "";
            const equtFieldValue = equtField ? equtField.value : "";
            const equrFieldValue = equrField ? equrField.value : "";
    
            const equEqEmpty = equEqFieldvalue.trim() === "";
            const equqEmpty = equqFieldvalue.trim() === "";
            const equMEmpty = equMFieldvalue.trim() === "";
            const equsEmpty = equsFieldValue.trim() === "";
            const equeEmpty = equeFieldValue.trim() === "";
            const equuEmpty = equuFieldValue.trim() === "";
            const equdEmpty = equdFieldValue.trim() === "";
            const equtEmpty = equtFieldValue.trim() === "";
            const equrEmpty = equrFieldValue.trim() === "";
    
            equEqError[index] = equEqEmpty;
            equQErrors[index] = equqEmpty;
            equMErrors[index] = equMEmpty;
            equSErrors[index] = equsEmpty;
            equEErrors[index] = equeEmpty;
            equUErrors[index] = equuEmpty;
            equDErrors[index] = equdEmpty;
            equTErrors[index] = equtEmpty;
            equRErrors[index] = equrEmpty;
    
            if(equEqEmpty || equqEmpty || equMEmpty || equsEmpty || equeEmpty || equuEmpty || equdEmpty || equtEmpty || equrEmpty){
              equipmentErrors = true;
            }else{
              equipmentErrors = false;
            }
          }else{
            equEqError[index] = false;
            equQErrors[index] = false;
            equMErrors[index] = false;
            equSErrors[index] = false;
            equEErrors[index] = false;
            equUErrors[index] = false;
            equDErrors[index] = false;
            equTErrors[index] = false;
            equRErrors[index] = false;
            equipmentErrors = false;
          }
        });
        _.times(numberOfManpower, (index) => {
          if(applylabval){
            const labLField = document.getElementById(`labL-${index}`).nextElementSibling;
            const labaField = document.getElementById(`labActual-${index}`);
            const labrField = document.getElementById(`labRequired-${index}`);
            
            const labLFieldValue = labLField ? labLField.value : "";
            const labaFieldValue = labaField ? labaField.value : "";
            const labrFieldvalue = labrField ? labrField.value : "";
    
            const labLEmpty = labLFieldValue.trim() === "";
            const labaEmpty = labaFieldValue.trim() === "";
            const labrEmpty = labrFieldvalue.trim() === "";
    
            labLErrors[index] = labLEmpty;
            labAErrors[index] = labaEmpty;
            labRErrors[index] = labrEmpty;
    
            if(labLEmpty || labaEmpty || labrEmpty){
              laborErrors = true;
            }else{
              laborErrors = false;
            }
          }else{
            labLErrors[index] = false;
            labAErrors[index] = false;
            labRErrors[index] = false;
            laborErrors = false;
          }
        });
        _.times(numberOfMiscellaneous, (index) => {
          if(applymisval){
            const miscField = document.getElementById(`misCost-${index}`);
            const misrField = document.getElementById(`misReason-${index}`);
            const misrrField = document.getElementById(`misRemark-${index}`);
    
            const miscFieldValue = miscField ? miscField.value : "";
            const misrFieldValue = misrField ? misrField.value : "";
            const misrrFieldValue = misrrField ? misrrField.value : "";
    
            const miscEmpty = miscFieldValue.trim() === "";
            const misrEmpty = misrFieldValue.trim() === "";
            const misrrEmpty = misrrFieldValue.trim() === "";
    
            misCErrors[index] = miscEmpty;
            misRErrors[index] = misrEmpty;
            misRRErrors[index] = misrrEmpty;
    
            if(miscEmpty || misrEmpty || misrrEmpty){
              miscellaneousErrors = true;
            }else{
              miscellaneousErrors= false;
            }
          }else{
            misCErrors[index] = false;
            misRErrors[index] = false;
            misRRErrors[index] = false;
            miscellaneousErrors= false;
          }
  
        });
        
        setState((prevState) => ({
          ...prevState,
          conNErrors: conNErrors,
          conIErrors: conIErrors,
          conQErrors: conQErrors,
          matMErrors: matMErrors,
          matQErrors: matQErrors,
          equEqError: equEqError,
          equQErrors: equQErrors,
          equMErrors: equMErrors,
          equSErrors: equSErrors,
          equEErrors: equEErrors,
          equUErrors: equUErrors,
          equDErrors: equDErrors,
          equTErrors: equTErrors,
          equRErrors: equRErrors,
          labLErrors: labLErrors,
          labAErrors: labAErrors,
          labRErrors: labRErrors,
          misCErrors: misCErrors,
          misRErrors: misRErrors,
          misRRErrors: misRRErrors,
        }));
        
        if (contractorErrors || materialErrors || equipmentErrors || laborErrors || miscellaneousErrors) {
        } else {
          const filledConNFieldIndex = conNErrors.findIndex((error) => !error);
          const filledConIFieldIndex = conIErrors.findIndex((error) => !error);
          const filledconQFieldIndex = conQErrors.findIndex((error) => !error);
          const filledMatMFieldIndex = matMErrors.findIndex((error) => !error);
          const filledMatQFieldIndex = matQErrors.findIndex((error) => !error);
          const filledEquEqFieldIndex = equEqError.findIndex((error) => !error);
          const filledEquQFieldIndex = equQErrors.findIndex((error) => !error);
          const filledEquMFieldIndex = equMErrors.findIndex((error) => !error);
          const filledEquSFieldIndex = equSErrors.findIndex((error) => !error);
          const filledEquEFieldIndex = equEErrors.findIndex((error) => !error);
          const filledEquUFieldIndex = equUErrors.findIndex((error) => !error);
          const filledEquDFieldIndex = equDErrors.findIndex((error) => !error);
          const filledEquTFieldIndex = equTErrors.findIndex((error) => !error);
          const filledEquRFieldIndex = equRErrors.findIndex((error) => !error);
          const filledLabLFieldIndex = labLErrors.findIndex((error) => !error);
          const filledLabaFieldIndex = labAErrors.findIndex((error) => !error);
          const filledLabrFieldIndex = labRErrors.findIndex((error) => !error);
          const filledMisCFieldIndex = misCErrors.findIndex((error) => !error);
          const filledMisRFieldIndex = misRErrors.findIndex((error) => !error);
          const filledMisRRFieldIndex = misRRErrors.findIndex((error) => !error);
  
          if (filledConNFieldIndex !== -1 || filledConIFieldIndex !== -1 || filledconQFieldIndex !== -1 || filledMatMFieldIndex !== -1 || filledMatQFieldIndex !== -1 || filledEquEqFieldIndex !== -1 || filledEquQFieldIndex !== -1 || filledEquMFieldIndex !== -1 || filledEquSFieldIndex !== -1 || filledEquEFieldIndex !== -1 || filledEquUFieldIndex !== -1 || filledEquDFieldIndex !== -1 || filledEquTFieldIndex !== -1 || filledEquRFieldIndex !== -1 || filledLabLFieldIndex !== -1 || filledLabaFieldIndex !== -1 || filledLabrFieldIndex !== -1 || filledMisCFieldIndex !== -1 || filledMisRFieldIndex !== -1 || filledMisRRFieldIndex !== -1 ) {
            setState((prevState) => ({
              ...prevState,
              conNErrors: prevState.conNErrors.map((error, index) =>
                index === filledConNFieldIndex ? false : error
              ),
              conIErrors: prevState.conIErrors.map((error, index) =>
                index === filledConIFieldIndex ? false : error
              ),
              conQErrors: prevState.conQErrors.map((error, index) =>
                index === filledconQFieldIndex ? false : error
              ),
              matMErrors: prevState.matMErrors.map((error, index) =>
                index === filledMatMFieldIndex ? false : error
              ),
              matQErrors: prevState.matQErrors.map((error, index) =>
                index === filledMatQFieldIndex ? false : error
              ),
              equEqError: prevState.equEqError.map((error, index) =>
                index === filledEquEqFieldIndex ? false : error
              ),
              equQErrors: prevState.equQErrors.map((error, index) =>
                index === filledEquQFieldIndex ? false : error
              ),
              equMErrors: prevState.equMErrors.map((error, index) =>
                index === filledEquMFieldIndex ? false : error
              ),
              equSErrors: prevState.equSErrors.map((error, index) =>
                index === filledEquSFieldIndex ? false : error
              ),
              equEErrors: prevState.equEErrors.map((error, index) =>
                index === filledEquEFieldIndex ? false : error
              ),
              equUErrors: prevState.equUErrors.map((error, index) =>
                index === filledEquUFieldIndex ? false : error
              ),
              equDErrors: prevState.equDErrors.map((error, index) =>
                index === filledEquDFieldIndex ? false : error
              ),
              equTErrors: prevState.equTErrors.map((error, index) =>
                index === filledEquTFieldIndex ? false : error
              ),
              equRErrors: prevState.equRErrors.map((error, index) =>
                index === filledEquRFieldIndex ? false : error
              ),
              labLErrors: prevState.labLErrors.map((error, index) =>
                index === filledLabLFieldIndex ? false : error
              ),
              labAErrors: prevState.labAErrors.map((error, index) =>
                index === filledLabaFieldIndex ? false : error
              ),
              labRErrors: prevState.labRErrors.map((error, index) =>
                index === filledLabrFieldIndex ? false : error
              ),
              misCErrors: prevState.misCErrors.map((error, index) =>
                index === filledMisCFieldIndex ? false : error
              ),
              misRErrors: prevState.misRErrors.map((error, index) =>
                index === filledMisRFieldIndex ? false : error
              ),
              misRRErrors: prevState.misRRErrors.map((error, index) =>
                index === filledMisRRFieldIndex ? false : error
              ),
            }));
          }
        }
        const allConNErrorsFalse = conNErrors.every((error) => error === false);
        const allConIErrorsFalse = conIErrors.every((error) => error === false);
        const allConQErrorsFalse = conQErrors.every((error) => error === false);
        const allMatMErrorsFalse = matMErrors.every((error) => error === false);
        const allMatQErrorsFalse = matMErrors.every((error) => error === false);
        const allEquEqErrorsFalse = equEqError.every((error) => error === false);
        const allEquQErrorsFalse = equQErrors.every((error) => error === false);
        const allEquMErrorsFalse = equMErrors.every((error) => error === false);
        const allEquSErrorsFalse = equSErrors.every((error) => error === false);
        const allEquEErrorsFalse = equEErrors.every((error) => error === false);
        const allEquUErrorsFalse = equUErrors.every((error) => error === false);
        const allEquDErrorsFalse = equDErrors.every((error) => error === false);
        const allEquTErrorsFalse = equTErrors.every((error) => error === false);
        const allEquRErrorsFalse = equRErrors.every((error) => error === false);
        const allLabLErrorsFalse = labLErrors.every((error) => error === false);
        const allLabAErrorsFalse = labAErrors.every((error) => error === false);
        const allLabRErrorsFalse = labRErrors.every((error) => error === false);
        const allMisCErrorsFalse = misCErrors.every((error) => error === false);
        const allMisRErrorsFalse = misRErrors.every((error) => error === false);
        const allMisRRErrorsFalse = misRRErrors.every((error) => error === false);
  
        if (allConNErrorsFalse && allConIErrorsFalse && allConQErrorsFalse && allMatMErrorsFalse && allMatQErrorsFalse && allEquEqErrorsFalse && allEquQErrorsFalse && allEquMErrorsFalse && allEquSErrorsFalse && allEquEErrorsFalse && allEquUErrorsFalse && allEquDErrorsFalse && allEquTErrorsFalse && allEquRErrorsFalse && allLabLErrorsFalse && allLabAErrorsFalse && allLabRErrorsFalse && allMisCErrorsFalse && allMisRErrorsFalse && allMisRRErrorsFalse) {
          multipleError= false
        }else{
          multipleError = true
        }
      if(activityError || SubAccNameError || multipleError){
        setActivityError(activityError);
        setSubAccNameError(SubAccNameError);
      }
      else{
        setActivityError(activityError);
        setSubAccNameError(SubAccNameError);
        if (endDateErr.includes(true)) {
          alert("Please fill error fields");
          return;
        }
        let new_items = [...items];
        new_items.forEach(it => {
          it.forEach(curr_item => {
            curr_item.item = curr_item.item.split(";")[0];
          });
        });
        console.log("new items", new_items);
        let final_contractors = [];
        for (let i = 0; i < contractorNames.length; i++) {
          final_contractors.push({
            contractorName: contractorNames[i].split(";")[0],
            items: new_items[i],
          });
        }
        console.log("contractornames", contractorNames);
        console.log("items", items);
        console.log("final contractors", final_contractors);
        contractor.forEach(labour => {
          labour.contractor = labour.contractor.split(";")[0];
        });
        partyDetails.forEach(ptr => {
          ptr.partyMaterial = ptr.partyMaterial.split(";")[0];
        });
        material.forEach(mtr => {
          mtr.material = mtr.material.split(";")[0];
        });
        equipment.forEach(eq => {
          eq.equipmentMaterial = eq.equipmentMaterial.split(";")[0];
        });
        manpower.forEach(mp => {
          mp.labor = mp.labor.split(";")[0];
        });
        let projectId = await global.config.secureStorage.getItem("projectId");
        let user_fullname = await global.config.secureStorage.getItem("user_fullname");
        let superProject=global.config.secureStorage.getItem("superProject")
        const reqBody = {
          date,
          activity: activity.split(";")[0],
          material, //Change
          work_desc: description,
          quantity_work: qty,
          metaData,
          contractorRA: final_contractors,
          labor: manpower,
          projectId,
          equipment,
          miscellaneous,
          subactivity:SubAccName.split(";")[0],
          taskName:taskName.split(";")[0],
          remark,
          partyDetails,
          createdBy: user_fullname,
          superProject
        };
        console.log("reqBody..", reqBody);
        try {
          const res = await axios.post(`${global.config.backendURL}/dpr/addDpr`, {
            ...reqBody,
          });
          setTaskList([])
          setTaskName("")
          setSubAccName("")
          setRemark("");
          setActivityIndex(-1);
          setActivity("");
          setMetaData([]);
          setNumberOfMetaData(1);
          setPartyDetails([]);
          setNumberOfPartyDetails(1);
          setContractor([]);
          setContractorNames([]);
          setItems([]);
          setContractorIndex([]);
          setItemlist([]);
          setManpower([]);
          setMaterial([]);
          setNumberOfContractor(1);
          setNumberOfMaterial(1);
          setMiscellaneous([]);
          setNumberOfMiscellaneous(1);
          setEquipment([]);
          setNumberOfEquipment(1);
          setDate(new Date());
          let tempDates = dates;
          let tempDpr = dpr;
          const dateFound = tempDates.includes(res.data.date);
          if (!dateFound) {
            tempDates.unshift(res.data.date);
          }
          tempDpr[res.data.date]
            ? tempDpr[res.data.date].unshift(res.data.dpr)
            : (tempDpr[res.data.date] = [res.data.dpr]);
          setDates(tempDates);
          setDpr(tempDpr);
          setAddClicked(false);
          setOpenAddSuccess(true);
        } catch (err) {
          console.log(err);
          setOpenAddFailure(true);
        }
      }
    }else{
      alert('Check at least one block!');
    }
  };
  const handleconval = () => {
    setApplyConval(!applyconval);
    setState((prevState) => ({
      ...prevState,
      conNErrors: [],
      conIErrors: [],
      conQErrors: [],
    }));
    setItems([]);
  }

  const handlematval = () => {
    setApplyMatval(!applymatval);
    setState((prevState) => ({
      ...prevState,
      matMErrors: [],
      matQErrors: [],
    }));
  }

  const handleequval = () => {
    setApplyEquval(!applyequval);
    setState((prevState) => ({
      ...prevState,
      equEqError: [],
      equQErrors: [],
      equMErrors: [],
      equSErrors: [],
      equEErrors: [],
      equUErrors: [],
      equDErrors: [],
      equTErrors: [],
      equRErrors: [],
    }));
  }
  const handlelabval = () => {
    setApplyLabval(!applylabval);
    setState((prevState) => ({
      ...prevState,
      labLErrors: [],
      labAErrors: [],
      labRErrors: [],
      labSErrors: [],
    }));
  }
  const handlemiscval = () => {
    setApplyMisval(!applymisval);
    setState((prevState) => ({
      ...prevState,
      misCErrors: [],
      misRErrors: [],
      misRRErrors:[],
    }));
  }

  function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  const handleCardClick = date => setClicked(date);

  const handleBackButtonClick = () => setClicked(null);

  const handleClose = () => {
    setAddClicked(false);
    setActivityError(false);
    setSubAccNameError(false);
    setState((prevState) => ({
      ...prevState,
      supNErrors: [],
      dcNoErrors: [],
      quanErrors: [],
      locaErrors: [],
      remaErrors: [],
      mateErrors: [],
      workErrors: [],
      worqErrors: [],
      worUErrors: [],
      conNErrors: [],
      conIErrors: [],
      conQErrors: [],
      matNErrors: [],
      matMErrors: [],
      matQErrors: [],
      equEqError: [],
      equQErrors: [],
      equMErrors: [],
      equSErrors: [],
      equEErrors: [],
      equUErrors: [],
      equDErrors: [],
      equTErrors: [],
      equRErrors: [],
      labLErrors: [],
      labAErrors: [],
      labRErrors: [],
      labSErrors: [],
      misCErrors: [],
      misRErrors: [],
      misRRErrors:[],
    }));
    setApplyConval(false);
    setApplyEquval(false);
    setApplyLabval(false);
    setApplyMatval(false);
    setApplyMisval(false);
  }

  const handleDelete = ids => {
    let temp_dates = dates;
    let temp_dpr = dpr;
    ids.forEach(id => {
      const index = temp_dpr[clicked].findIndex(elem => elem._id === id);
      if (index >= 0) {
        if (temp_dpr[clicked].length === 1) {
          delete temp_dpr[clicked];
          let date_index = temp_dates.findIndex(elem => elem === clicked);
          temp_dates.splice(date_index, 1);
        } else {
          temp_dpr[clicked].splice(index, 1);
        }
      }
    });
    setDate(temp_dates);
    setDpr(temp_dpr);
  };

  const handleContractorAddCount = () => {
    setNumberOfContractor(numberOfContractor + 1);
    let temp = [...items];
    temp.push([
      {
        item: "",
        unit: "",
        number: "",
      },
    ]);
    setItems(temp);
  };
  const handleManpowerAddCount = () => {
    setNumberOfManpower(numberOfManpower + 1);
  };
  const handleMaterialAddCount = () => {
    setNumberOfMaterial(numberOfMaterial + 1);
  };

  const handleEquipmentAddCount = () => {
    setNumberOfEquipment(numberOfEquipment + 1);
  };

  const handleMiscellaneousAddCount = () => {
    setNumberOfMiscellaneous(numberOfMiscellaneous + 1);
  };

  const handleContractorDeleteCount = index => {
    if (numberOfContractor === 1) return;
    let temp = contractor;
    temp.splice(index, 1);
    setContractor(temp);
    setNumberOfContractor(numberOfContractor - 1);
  };
  const handleItemDeleteCount = (index, i_index) => {
    if (items[index].length === 1) return;
    let temp = [...items];
    temp[index].splice(i_index, 1);
    setItems(temp);
  };
  const handleItemAddCount = index => {
    let temp = [...items];
    let x = temp[index];
    x = [
      ...temp[index],
      {
        item: "",
        unit: "",
        number: "",
      },
    ];
    temp[index] = x;
    console.log(items, temp);
    setItems(temp);
    console.log("its up", items[index]);
  };
  useEffect(() => {
    console.warn("updaing it");
  }, [items]);
  const handleManpowerDeleteCount = index => {
    if (numberOfManpower === 1) return;
    let temp = manpower;
    temp.splice(index, 1);
    setManpower(temp);
    setNumberOfManpower(numberOfManpower - 1);
  };
  const handleMaterialDeleteCount = index => {
    if (numberOfMaterial === 1) return;
    let temp = material;
    temp.splice(index, 1);
    setMaterial(temp);
    setNumberOfMaterial(numberOfMaterial - 1);
  };

  const handleMiscellaneousDeleteCount = index => {
    if (numberOfMiscellaneous === 1) return;
    let temp = miscellaneous;
    temp.splice(index, 1);
    setMiscellaneous(temp);
    setNumberOfMiscellaneous(numberOfMiscellaneous - 1);
  };

  const handleEquipmentDeleteCount = index => {
    if (numberOfEquipment === 1) return;
    let temp = equipment;
    temp.splice(index, 1);
    setEquipment(temp);
    setNumberOfEquipment(numberOfEquipment - 1);
  };
  return (
    <>
      <Snackbar
        open={openAddSuccess}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        TransitionComponent={TransitionLeft}
        onClose={() => setOpenAddSuccess(false)}
      >
        <Alert
          severity="success"
          onClose={() => setOpenAddSuccess(false)}
          style={{ borderTop: "5px solid green" }}
        >
          <div style={{ color: "green" }}>
            <strong>Success</strong>
          </div>
          DPR added successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openAddFailure}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        TransitionComponent={TransitionLeft}
        onClose={() => setOpenAddFailure(false)}
      >
        <Alert
          severity="error"
          onClose={() => setOpenAddFailure(false)}
          style={{ borderTop: "5px solid red" }}
        >
          <div style={{ color: "red" }}>
            <strong>Error</strong>
          </div>
          Error while adding DPR!
        </Alert>
      </Snackbar>
      <Dialog
        open={addClicked}
        onClose={handleClose}
        className="mt-5"
        maxWidth="sm"
        fullWidth
        fullScreen={fullScreen}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle className="mt-2" id="form-dialog-title">
          New Daily Progress Report
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Provide details of DPR</DialogContentText>
          <form onSubmit={addButtonHandler}>
            <FormControl fullWidth>
              <InputLabel id="activity" style={{color: activityError ? "red" : "#000"}}>
                Activity
              </InputLabel>
              <Select
                required
                labelId="activity"
                id="activity"
                value={activity}
                label="activity"
                onChange={event => {
                  setActivity(event.target.value);
                  setActivityIndex(event.target.value.split(";")[1]);
                  setTaskName("")
                  setTaskList([])
                  setSubAccName("")
                  setSubAccIndex("");
                }}
                error={activityError}
              >
                {activityData.map((item, i) => (
                  <MenuItem value={`${item.title};${i}`}>{item.title}</MenuItem>
                ))}
              </Select>
              {activityError && (<Typography varient="p" style={{color:"#f44336", fontSize:"0.75rem", marginTop:"4px"}}>please Select Activity!</Typography>)}
            </FormControl>
            <div style={{display:"flex", justifyContent:"center"}}> 
              <AppBar position="static"style={{backgroundColor:"white",color:"blue",width:"60%"}} >
                <Tabs value={tabIndex} onChange={handelTabs} TabIndicatorProps={{ style: { height: "2px"} }}>
                  <Tab label="Sub activities"></Tab>
                  <Tab label="Task"></Tab>
                </Tabs>
              </AppBar>
            </div>
            {activityIndex !== -1 &&  (
          <>
           <FormControl fullWidth>
           <InputLabel id="activity" style={{color: SubAccNameError ? "red" : "#000"}}>Sub activity</InputLabel>

           <Select
             labelId="subActivity"
             id="subActivity"
             value={SubAccName}
             label="subActivity"
             onChange={event => {
               handleSubActivity(event);
             }}
             required
             error={SubAccNameError}
             helperText={SubAccNameError ? "Please Select Sub Activity!" : ""}
           >
             {activityData && activityData[activityIndex].content.map((item,i) => (
               <MenuItem value={`${item.subTitle};${i}`}>{item.subTitle}</MenuItem>
             ))}
           </Select>
           {SubAccNameError && (<Typography varient="p" style={{color:"#f44336", fontSize:"0.75rem", marginTop:"4px"}}>Please Select Sub Activity!</Typography>)}
         </FormControl>
         <br />
         <br />
         </>
         )}
         {taskList.length >0  && tabIndex ===1 && (
          <>
           <FormControl fullWidth>
           <InputLabel id="activity" style={{color: taskNameError ? "red" : "#000"}}>task</InputLabel>

           <Select
             labelId="task"
             id="task"
             value={taskName}
             label="task"
             onChange={event => {
               handleTaskChange(event);
             }}
             required
             error={Boolean(taskNameError)}
             helperText={taskNameError ? "Please Select Task!" : ""}
           >
             {taskList.map((item,i) => (
               <MenuItem value={`${item.name};${i}`}>{item.name}</MenuItem>
             ))}
           </Select>
           {taskNameError && (<Typography varient="p" style={{color:"#f44336", fontSize:"0.75rem", marginTop:"4px"}}>Please Select Task!</Typography>)}
         </FormControl>
         <br />
         <br />
         </>
         )}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className="mt-4"
                fullWidth
                disableToolbar
                label="Date"
                format="dd/MM/yyyy"
                margin="none"
                id="date-picker-inline"
                value={date}
                placeholder="Not available"
                onChange={date => {
                  setDate(date);
                }}
              />
            </MuiPickersUtilsProvider>
            <div style={{textAlign:'center'}}>
              <br/>
              <Divider/><h6 style={{padding:'10px 0', margin:'0px'}}>* Contractors *</h6><Divider/>
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={applyconval}
                  onChange={handleconval}
                  color="primary"
                />
              }
              label="Add Contractors"
            />
            {_.times(numberOfContractor, index => {
              let indexValue = index + 1;
              return (
                <>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <FormControl className="mt-1" fullWidth error={state.conNErrors[index] }>
                        <InputLabel id="contractor-label" required>
                          Contractor RA
                        </InputLabel>
                        <Select
                          labelId="contractor-label"
                          value={contractorNames[index]}
                          onChange={e => {
                            handleContractorChange(e, index);
                          }}
                          id={`contractorN-${index}`}
                          error={Boolean(state.conNErrors[index])}
                        >
                          {labourData.map((Labour, index) => (
                            <MenuItem
                              value={Labour.contractorName + ";" + index}
                            >
                              {Labour.contractorName}
                            </MenuItem>
                          ))}
                        </Select>
                        {state.conNErrors[index] && (<p style={{color:"#f44336", fontSize:"0.75rem", marginTop:"4px"}}>Please select contractor!</p>)}
                      </FormControl>
                    </Grid>
                    <br />

                    <Grid item xs={1}>
                      <IconButton
                        color="secondary"
                        className="mt-4"
                        onClick={() =>{
                          if(numberOfContractor === 1){
                            Swal.fire({
                              title: "Info",
                              icon: "info",
                              text: "Add at least one contractor!"
                            })
                          }else{
                            Swal.fire({
                              title: 'Are you sure?',
                              text: "You won't be able to revert this!",
                              icon: 'warning',
                              showCancelButton: true,
                              confirmButtonColor: '#3085d6',
                              cancelButtonColor: '#d33',
                              confirmButtonText: 'Yes, delete it!'
                            }).then((result) => {
                              if (result.isConfirmed) {
                                Swal.fire(
                                  'Deleted!',
                                  'Your file has been deleted.',
                                  'success'
                                )
                                handleContractorDeleteCount(index)
                              }
                            })
                          }
                        }
                        }
                      >
                        <Delete />
                      </IconButton>
                    </Grid>
                    {numberOfContractor - 1 === index ? (
                      <Grid item xs={1}>
                        <IconButton
                          color="primary"
                          className="mt-4"
                          onClick={() => handleContractorAddCount(index)}
                        >
                          <AddCircleOutline />
                        </IconButton>
                      </Grid>
                    ) : (
                      <Grid item xs={1}></Grid>
                    )}
                  </Grid>
                  {items &&
                    items[index] &&
                    items[index].length > 0 &&
                    items[index].map((c_item, i_index) => {
                      return (
                        <>
                          <Grid></Grid>
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <FormControl className="mt-1" fullWidth error={state.conIErrors[index]}>
                                <InputLabel id="item-label" required>
                                  Item
                                </InputLabel>
                                <Select
                                  labelId="item-label"
                                  id={`conI-${index}`}
                                  name="item"
                                  value={items[index][i_index].item}
                                  label="item"
                                  onChange={e => {
                                    handleItemNameChange(e, i_index, index);
                                  }}
                                  required
                                  error={Boolean(state.conIErrors[index])}
                                >
                                  {itemlist[contractorIndex[index]] &&
                                    itemlist[contractorIndex[index]].map(
                                      item => (
                                        <MenuItem
                                          value={`${item.item};${item.unit}`}
                                        >
                                          {item.item}
                                        </MenuItem>
                                      )
                                    )}
                                </Select>
                                {state.conIErrors[index] && (<p style={{color:"#f44336", fontSize:"0.75rem", marginTop:"4px"}}>Please select item!</p>)}
                              </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                value={items[index][i_index].number}
                                onChange={e => {
                                  handleItemNumberChange(e, i_index, index);
                                }}
                                margin="dense"
                                type="number"
                                variant="standard"
                                label="Quantity"
                                fullWidth
                                required
                                id={`conQuality-${index}`}
                                error={Boolean(state.conQErrors[index])}
                                helperText={state.conQErrors[index] ? "Please enter quantity!" : ""}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                value={c_item.unit}
                                margin="dense"
                                type="text"
                                variant="standard"
                                label="Units"
                                fullWidth
                                disabled
                                required
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <IconButton
                                color="secondary"
                                className="mt-4"
                                onClick={() =>
                                  Swal.fire({
                                    title: 'Are you sure?',
                                    text: "You won't be able to revert this!",
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Yes, delete it!'
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      Swal.fire(
                                        'Deleted!',
                                        'Your file has been deleted.',
                                        'success'
                                      )
                                      handleItemDeleteCount(index, i_index)
                                    }
                                  })
                                 
                                }
                              >
                                <Delete />
                              </IconButton>
                            </Grid>
                            {items[index].length - 1 === i_index ? (
                              <Grid item xs={1}>
                                <IconButton
                                  color="primary"
                                  className="mt-4"
                                  onClick={() => handleItemAddCount(index)}
                                >
                                  <AddCircleOutline />
                                </IconButton>
                              </Grid>
                            ) : (
                              <Grid item xs={1}></Grid>
                            )}
                          </Grid>
                        </>
                      );
                    })}
                </>
              );
            })}

<div style={{textAlign:'center'}}>
<br/>
              <Divider/><h6 style={{padding:'10px 0', margin:'0px'}}>* Materials *</h6><Divider/>
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={applymatval}
                  onChange={handlematval}
                  color="primary"
                />
              }
              label="Add Material"
            />
            {_.times(numberOfMaterial, index => {
              let indexValue = index + 1;
              return (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl className="mt-1" fullWidth error={state.matMErrors[index]}>
                      <InputLabel id="material-label" required>
                        Material
                      </InputLabel>
                      <Select
                        labelId="material-label"
                        value={
                          typeof material[index] === "object" &&
                          material[index] !== null
                            ? material[index].material
                            : ""
                        }
                        onChange={e => {
                          const unit = e.target.value.split(";")[1];
                          let tempArr = [...material];
                          if (tempArr[index] && "number" in tempArr[index]) {
                            tempArr[index] = {
                              supplier:tempArr[index].supplier,
                              material: e.target.value,
                              number: tempArr[index].number,
                              unit: unit,
                            };
                          } else {
                            tempArr[index] = {
                              material: e.target.value,
                              number: "",
                              supplier: "",
                              unit: unit,
                            };
                          }
                          setMaterial(tempArr);
                        }}
                        id={`matM-${index}`}
                        error={Boolean(state.matMErrors[index])}
                      >
                        {materialData.map((material, index) => (
                          <MenuItem
                            value={
                              material.materialName +
                              ";" +
                              material.materialUnit
                            }
                          >
                            {material.materialName}
                          </MenuItem>
                        ))}
                      </Select>
                      {state.matMErrors[index] && (<p style={{color:"#f44336", fontSize:"0.75rem", marginTop:"4px"}}>Please select material!</p>)}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      value={
                        typeof material[index] === "object" &&
                        material[index] !== null
                          ? material[index].number
                          : ""
                      }
                      margin="dense"
                      type="number"
                      variant="standard"
                      label="Quantity"
                      fullWidth
                      onChange={e => {
                        let tempArr = [...material];
                        if (tempArr[index] && "material" in tempArr[index]) {
                          tempArr[index] = {
                            number: e.target.value,
                            material: tempArr[index].material,
                            unit: tempArr[index].unit,
                            supplier: tempArr[index].supplier,
                          };
                        } else {
                          tempArr[index] = {
                            number: e.target.value,
                            material: "",
                            unit: "",
                            supplier: "",
                          };
                        }
                        setMaterial(tempArr);
                      }}
                      required
                      id={`matQ-${index}`}
                      error={Boolean(state.matQErrors[index])}
                      helperText={state.matQErrors[index] ? "Please enter quantity!" : ""}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      value={
                        typeof material[index] === "object" &&
                        material[index] !== null
                          ? material[index].unit
                          : ""
                      }
                      margin="dense"
                      type="text"
                      variant="standard"
                      label="Units"
                      fullWidth
                      disabled
                      onChange={e => {
                        let tempArr = [...material];
                        if (tempArr[index] && "material" in tempArr[index]) {
                          tempArr[index] = {
                            unit: e.target.value,
                            material: tempArr[index].material,
                            number: tempArr[index].number,
                          };
                        } else {
                          tempArr[index] = {
                            number: "",
                            material: "",
                            unit: e.target.value,
                          };
                        }
                        setMaterial(tempArr);
                      }}
                      required
                    />
                  </Grid>

                  <Grid item xs={1}>
                    <IconButton
                      color="secondary"
                      className="mt-4"
                      onClick={() =>
                        Swal.fire({
                          title: 'Are you sure?',
                          text: "You won't be able to revert this!",
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonColor: '#3085d6',
                          cancelButtonColor: '#d33',
                          confirmButtonText: 'Yes, delete it!'
                        }).then((result) => {
                          if (result.isConfirmed) {
                            Swal.fire(
                              'Deleted!',
                              'Your file has been deleted.',
                              'success'
                            )
                            handleMaterialDeleteCount(index)
                          }
                        })
                       }
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                  {numberOfMaterial - 1 === index ? (
                    <Grid item xs={1}>
                      <IconButton
                        color="primary"
                        className="mt-4"
                        onClick={() => handleMaterialAddCount(index)}
                      >
                        <AddCircleOutline />
                      </IconButton>
                    </Grid>
                  ) : (
                    <Grid item xs={1}></Grid>
                  )}
                </Grid>
              );
            })}
                    <div style={{textAlign:'center'}}>
                    <br/>
              <Divider/><h6 style={{padding:'10px 0', margin:'0px'}}>* Equipments *</h6><Divider/>
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={applyequval}
                  onChange={handleequval}
                  color="primary"
                />
              }
              label="Add Equipment"
            />
            {_.times(numberOfEquipment, index => {
              let indexValue = index + 1;
              return (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl className="mt-1" fullWidth error={state.equEqError[index]}>
                      <InputLabel id="equipment-label" required>
                        Equipment
                      </InputLabel>
                      <Select
                        labelId="equipment-label"
                        value={
                          typeof equipment[index] === "object" &&
                          equipment[index] !== null
                            ? equipment[index].equipment
                            : ""
                        }
                        onChange={e => {
                          let tempArr = [...equipment];
                          if (tempArr[index] && "equipment" in tempArr[index]) {
                            tempArr[index] = {
                              totalHours: tempArr[index].totalHours,
                              equipment: e.target.value,
                              unit: tempArr[index].unit,
                              start: tempArr[index].start,
                              end: tempArr[index].end,
                              diesel: tempArr[index].diesel,
                              number: tempArr[index].number,
                              equipmentMaterial: tempArr[index].equipmentMaterial,
                              trip: tempArr[index].trip,
                              remark: tempArr[index].remark,
                              countUnit:tempArr[index].countUnit,
                            };
                          } else {
                            tempArr[index] = {
                              start: "",
                              equipment: e.target.value,
                              unit: "",
                              totalHours: "",
                              end: "",
                              diesel: "",
                              number: "",
                              equipmentMaterial: "",
                              trip:"",
                              remark: "",
                              countUnit:"",
                            };
                          }
                          setEquipment(tempArr);
                        }}
                        id={`equE-${index}`}
                        error={Boolean(state.equEqError[index])}
                      >
                        {equipmentData.map((equipment, index) => (
                          <MenuItem
                            value={
                              equipment.equipmentName
                            }
                          >
                            {equipment.equipmentName}
                          </MenuItem>
                        ))}
                      </Select>
                      {state.equEqError[index] && (<p style={{color:"#f44336", fontSize:"0.75rem", marginTop:"4px"}}>Please select equipment!</p>)}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      value={
                        typeof equipment[index] === "object" &&
                        equipment[index] !== null
                          ? equipment[index].number
                          : ""
                      }
                      margin="dense"
                      type="number"
                      variant="standard"
                      label="Quantity"
                      fullWidth
                      onChange={e => {
                        let tempArr = [...equipment];
                        if (tempArr[index] && "equipment" in tempArr[index]) {
                          tempArr[index] = {
                            number: e.target.value,
                            totalHours: tempArr[index].totalHours,
                            equipment: tempArr[index].equipment,
                            unit: tempArr[index].unit,
                            diesel: tempArr[index].diesel,
                            equipmentMaterial: tempArr[index].equipmentMaterial,
                            trip: tempArr[index].trip,
                            remark: tempArr[index].remark,
                            countUnit:tempArr[index].countUnit,
                          };
                        } else {
                          tempArr[index] = {
                            number: e.target.value,
                            totalHours: "",
                            equipment: "",
                            unit: "",
                            diesel: "",
                            equipmentMaterial: "",
                            trip:"",
                            remark: "",
                            countUnit:"",
                          };
                        }
                        setEquipment(tempArr);
                      }}
                      required
                      id={`equq-${index}`}
                      error={Boolean(state.equQErrors[index])}
                      helperText={state.equQErrors[index] ? "Please enter quantity" : ""}
                    />
                  </Grid>


                  <Grid item xs={6}>
                    <FormControl className="mt-1" fullWidth error={state.equMErrors[index]}>
                      <InputLabel id="material-label" required>
                        Material
                      </InputLabel>
                      <Select
                        labelId="material-label"
                        value={
                          typeof equipment[index] === "object" &&
                          equipment[index] !== null
                            ? equipment[index].equipmentMaterial
                            : ""
                        }
                        onChange={e => {
                          
                          const unit = e.target.value.split(";")[1];
                          let tempArr = [...equipment];
                          if (tempArr[index] && "equipment" in tempArr[index]) {
                            tempArr[index] = {
                              totalHours: tempArr[index].totalHours,
                              equipment: tempArr[index].equipment,
                              unit: unit,
                              start: tempArr[index].start,
                              end: tempArr[index].end,
                              diesel: tempArr[index].diesel,
                              number: tempArr[index].number,
                              equipmentMaterial: e.target.value,
                              trip: tempArr[index].trip,
                              remark:tempArr[index].remark,
                              countUnit:tempArr[index].countUnit,
                            };
                          } else {
                            tempArr[index] = {
                              start: "",
                              equipment: "",
                              unit: "",
                              totalHours: "",
                              end: "",
                              diesel: "",
                              number: "",
                              equipmentMaterial: e.target.value,
                              trip:"",
                              remark: "",
                              countUnit:"",
                            };
                          }
                          setEquipment(tempArr);
                        }}
                        id={`equM-${index}`}
                        error={Boolean(state.equMErrors[index])}
                      >
                        {materialData.map((material, index) => (
                          <MenuItem
                            value={
                              material.materialName +
                              ";" +
                              material.materialUnit
                            }
                          >
                            {material.materialName}
                          </MenuItem>
                        ))}
                      </Select>
                      {state.equMErrors[index] && (<p style={{color:"#f44336", fontSize:"0.75rem", marginTop:"4px"}}>Please select material!</p>)}
                    </FormControl>
                  </Grid>



                  <Grid item xs={6}>
                    <TextField
                      value={
                        typeof equipment[index] === "object" &&
                        equipment[index] !== null
                          ? equipment[index].unit
                          : ""
                      }
                      margin="dense"
                      type="text"
                      variant="standard"
                      label="Units"
                      fullWidth
                      disabled
                      onChange={e => {
                        let tempArr = [...equipment];
                        if (tempArr[index] && "equipment" in tempArr[index]) {
                          tempArr[index] = {
                            unit: e.target.value,
                            equipment: tempArr[index].equipment,
                            number: tempArr[index].number,
                            equipmentMaterial: tempArr[index].equipmentMaterial,
                            trip: tempArr[index].trip,
                            remark: tempArr[index].remark,
                          };
                        } else {
                          tempArr[index] = {
                            unit: e.target.value,
                            equipment: "",
                            number: "",
                            equipmentMaterial: "",
                            trip:"",
                            remark: "",
                          };
                        }
                        setEquipment(tempArr);
                      }}
                      required
                    />
                  </Grid>
            
                  <Grid item xs={6}>
                    <TextField
                      label="Start"
                      value={
                        typeof equipment[index] === "object" &&
                        equipment[index] !== null
                          ? equipment[index].start
                          : ""
                      }
                      margin="dense"
                      type="number"
                      variant="standard"
                      onChange={e => {
                        let tempArr = [...equipment];
                        if (tempArr[index] && "equipment" in tempArr[index]) {
                          tempArr[index] = {
                            totalHours: tempArr[index].totalHours,
                            equipment: tempArr[index].equipment,
                            unit: tempArr[index].unit,
                            start: e.target.value,
                            end: tempArr[index].end,
                            diesel: tempArr[index].diesel,
                            number: tempArr[index].number,
                            equipmentMaterial: tempArr[index].equipmentMaterial,
                            trip: tempArr[index].trip,
                            remark:  tempArr[index].remark,
                            countUnit:tempArr[index].countUnit,
                          };
                        } else {
                          tempArr[index] = {
                            start: e.target.value,
                            equipment: "",
                            unit: "",
                            totalHours: "",
                            end: "",
                            diesel: "",
                            number: "",
                            equipmentMaterial: "",
                            trip:"",
                            remark: "",
                            countUnit:"",
                          };
                        }
                        let equip = tempArr[index];
  
                        let newArr =[...endDateErr]
                        if(parseFloat(equip.end)<parseFloat(equip.start)){
                          newArr[index] = true
                          setEndDateErr(newArr)
                        }else{
                          newArr[index] = false
                          setEndDateErr(newArr)
                        }
                        equip.totalHours =
                        (parseFloat(equip.end) - parseFloat(equip.start)).toFixed(2);
                        tempArr[index] = equip;
                        setEquipment(tempArr);
                      }}
                      id={`equStart-${index}`}
                      error={Boolean(state.equSErrors[index])}
                      helperText={state.equSErrors[index] ? "please enter start!" : ""}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="End"
                      value={
                        typeof equipment[index] === "object" &&
                        equipment[index] !== null
                          ? equipment[index].end
                          : ""
                      }
                      margin="dense"
                      type="number"
                      variant="standard"
                      error={Boolean(state.equEErrors[index]) ? Boolean(state.equEErrors[index]) : endDateErr[index]}
                      helperText={state.equEErrors[index] ? "Please enter end!" : endDateErr[index] ? 'Greater than Start field!' : ' '}
                      onChange={e => {
                        let tempArr = [...equipment];
                        if (tempArr[index] && "equipment" in tempArr[index]) {
                          tempArr[index] = {
                            totalHours: tempArr[index].totalHours,
                            equipment: tempArr[index].equipment,
                            unit: tempArr[index].unit,
                            start: tempArr[index].start,
                            end: e.target.value,
                            diesel: tempArr[index].diesel,
                            number: tempArr[index].number,
                            equipmentMaterial: tempArr[index].equipmentMaterial,
                            trip: tempArr[index].trip,
                            remark: tempArr[index].remark,
                            countUnit:tempArr[index].countUnit,
                          };
                        } else {
                          tempArr[index] = {
                            start: "",
                            equipment: "",
                            unit: "",
                            totalHours: "",
                            end: e.target.value,
                            countUnit:"",
                            diesel: "",
                            number: "",
                            equipmentMaterial: "",
                            trip:"",
                            remark: "",
                          };
                        }
                        let equip = tempArr[index];
  
                        let newArr =[...endDateErr]
                        if(parseFloat(equip.end)<parseFloat(equip.start)){
                          newArr[index] = true
                          setEndDateErr(newArr)
                        }else{
                          newArr[index] = false
                          setEndDateErr(newArr)
                        }
                        equip.totalHours =
                        (parseFloat(equip.end) - parseFloat(equip.start)).toFixed(2);
                        tempArr[index] = equip;
                        setEquipment(tempArr);
                      }}
                      id={`equEnd-${index}`}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Unit"
                      value={
                        typeof equipment[index] === "object" &&
                        equipment[index] !== null
                          ? equipment[index].countUnit
                          : ""
                      }
                      margin="dense"
                      type="text"
                      variant="standard"
                      onChange={e => {
                        let tempArr = [...equipment];
                        if (tempArr[index] && "equipment" in tempArr[index]) {
                          tempArr[index] = {
                            totalHours: tempArr[index].totalHours,
                            equipment: tempArr[index].equipment,
                            unit: tempArr[index].unit,
                            start: tempArr[index].start,
                            end: tempArr[index].end,
                            countUnit: e.target.value,
                            diesel: tempArr[index].diesel,
                            number: tempArr[index].number,
                            equipmentMaterial: tempArr[index].equipmentMaterial,
                            trip: tempArr[index].trip,
                            remark:  tempArr[index].remark,
                          };
                        } else {
                          tempArr[index] = {
                            start: "",
                            end: "",
                            countUnit: e.target.value,
                            equipment: "",
                            unit: "",
                            totalHours: "",
                            diesel: "",
                            number: "",
                            equipmentMaterial: "",
                            trip:"",
                            remark: "",
                          };
                        }
                        let equip = tempArr[index];
  
                        let newArr =[...endDateErr]
                        if(parseFloat(equip.end)<parseFloat(equip.start)){
                          newArr[index] = true
                          setEndDateErr(newArr)
                        }else{
                          newArr[index] = false
                          setEndDateErr(newArr)
                        }
                        equip.totalHours =
                        (parseFloat(equip.end) - parseFloat(equip.start)).toFixed(2);
                        tempArr[index] = equip;
                        setEquipment(tempArr);
                      }}
                      id={`equUnit-${index}`}
                      error={Boolean(state.equUErrors[index])}
                      helperText={state.equUErrors[index] ? "Please enter unit!" : ""}
                    />
                  </Grid>
                  
                  <Grid item xs={6}>
                    <TextField
                      value={
                        typeof equipment[index] === "object" &&
                        equipment[index] !== null
                          ? equipment[index].totalHours
                          : ""
                      }
                      margin="dense"
                      type="number"
                      variant="standard"
                      label="Total"
                      fullWidth
                      disabled
                      onChange={e => {
                        let tempArr = [...equipment];
                        if (tempArr[index] && "equipment" in tempArr[index]) {
                          tempArr[index] = {
                            totalHours: e.target.value,
                            equipment: tempArr[index].equipment,
                            unit: tempArr[index].unit,
                            diesel: tempArr[index].diesel,
                            number: tempArr[index].number,
                            equipmentMaterial: tempArr[index].equipmentMaterial,
                            trip: tempArr[index].trip,
                            remark: tempArr[index].remark,
                          };
                        } else {
                          tempArr[index] = {
                            totalHours: e.target.value,
                            equipment: "",
                            unit: "",
                            diesel: "",
                            number: "",
                            equipmentMaterial: "",
                            trip:"",
                            remark: "",
                          };
                        }
                        setEquipment(tempArr);
                      }}
                      required
                    />
                  </Grid>
             
          
                  <Grid item xs={12}>
                    <TextField
                      value={
                        typeof equipment[index] === "object" &&
                        equipment[index] !== null
                          ? equipment[index].diesel
                          : ""
                      }
                      margin="dense"
                      type="number"
                      variant="standard"
                      label="diesel consumption"
                      fullWidth
                      onChange={e => {
                        let tempArr = [...equipment];
                        console.log("taget value", e.target.value);
                        if (tempArr[index] && "equipment" in tempArr[index]) {
                          tempArr[index] = {
                            totalHours: tempArr[index].totalHours,
                            equipment: tempArr[index].equipment,
                            unit: tempArr[index].unit,
                            end: tempArr[index].end,
                            start: tempArr[index].start,
                            diesel: e.target.value,
                            number: tempArr[index].number,
                            equipmentMaterial: tempArr[index].equipmentMaterial,
                            trip: tempArr[index].trip,
                            remark:tempArr[index].remark,
                            countUnit:tempArr[index].countUnit,
                          };
                        } else {
                          tempArr[index] = {
                            diesel: e.target.value,
                            end: "",
                            equipment: "",
                            unit: "",
                            totalHours: "",
                            start: "",
                            equipmentMaterial: "",
                            trip:"",
                            number: "",
                            remark: "",
                            countUnit:"",
                          };
                        }
                        setEquipment(tempArr);
                      }}
                      required
                      id={`equDiesel-${index}`}
                      error={Boolean(state.equDErrors[index])}
                      helperText={state.equDErrors[index] ? "Please enter diesel consumption" : ""}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      value={"Litres"}
                      margin="dense"
                      type="text"
                      variant="standard"
                      label="diesel in"
                      fullWidth
                      disabled
                      onChange={e => {
                        let tempArr = [...equipment];
                        console.log("taget value", e.target.value);
                        if (tempArr[index] && "equipment" in tempArr[index]) {
                          tempArr[index] = {
                            totalHours: tempArr[index].totalHours,
                            equipment: tempArr[index].equipment,
                            unit: tempArr[index].unit,
                            end: tempArr[index].end,
                            start: tempArr[index].start,
                            diesel: e.target.value,
                            number: tempArr[index].number,
                            equipmentMaterial: tempArr[index].equipmentMaterial,
                            trip: tempArr[index].trip,
                            remark: tempArr[index].remark,
                            countUnit:tempArr[index].countUnit,
                          };
                        } else {
                          tempArr[index] = {
                            diesel: e.target.value,
                            end: "",
                            equipment: "",
                            unit: "",
                            totalHours: "",
                            start: "",
                            equipmentMaterial: "",
                            trip:"",
                            remark: "",
                            number: tempArr[index].number,
                            countUnit:"",
                          };
                        }
                        setEquipment(tempArr);
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      value={
                        typeof equipment[index] === "object" &&
                        equipment[index] !== null
                          ? equipment[index].trip
                          : ""
                      }
                      margin="dense"
                      type="number"
                      variant="standard"
                      label="Trip"
                      fullWidth
                      onChange={e => {
                        let tempArr = [...equipment];
                        if (tempArr[index] && "equipment" in tempArr[index]) {
                          tempArr[index] = {
                            trip: e.target.value,
                            totalHours: tempArr[index].totalHours,
                            equipment: tempArr[index].equipment,
                            unit: tempArr[index].unit,
                            end: tempArr[index].end,
                            start: tempArr[index].start,
                            diesel: tempArr[index].diesel,
                            number: tempArr[index].number,
                            equipmentMaterial: tempArr[index].equipmentMaterial,
                            remark: tempArr[index].remark,
                            countUnit:tempArr[index].countUnit,
                          };
                        } else {
                          tempArr[index] = {
                            remark: "",
                            trip: e.target.value,
                            end: "",
                            equipment: "",
                            unit: "",
                            totalHours: "",
                            start: "",
                            diesel:"",
                            number: "",
                            equipmentMaterial: "",
                            countUnit:"",
                          };
                        }
                        setEquipment(tempArr);
                      }}
                      required
                      id={`equTrip-${index}`}
                      error={Boolean(state.equTErrors[index])}
                      helperText={state.equTErrors[index] ? "Please enter trip!" : ""}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      value={
                        typeof equipment[index] === "object" &&
                        equipment[index] !== null
                          ? equipment[index].remark
                          : ""
                      }
                      margin="dense"
                      type="text"
                      variant="standard"
                      label="Remark"
                      fullWidth
                      onChange={e => {
                        let tempArr = [...equipment];
                        if (tempArr[index] && "equipment" in tempArr[index]) {
                          tempArr[index] = {
                            remark: e.target.value,
                            trip: tempArr[index].trip,
                            totalHours: tempArr[index].totalHours,
                            equipment: tempArr[index].equipment,
                            unit: tempArr[index].unit,
                            end: tempArr[index].end,
                            start: tempArr[index].start,
                            diesel: tempArr[index].diesel,
                            number: tempArr[index].number,
                            equipmentMaterial: tempArr[index].equipmentMaterial,
                            countUnit:tempArr[index].countUnit,
                          };
                        } else {
                          tempArr[index] = {
                            remark: e.target.value,
                            trip: "",
                            end: "",
                            equipment: "",
                            unit: "",
                            totalHours: "",
                            start: "",
                            diesel:"",
                            number: "",
                            equipmentMaterial: "",
                            countUnit:"",
                          };
                        }
                        setEquipment(tempArr);
                      }}
                      required
                      id={`equRemark-${index}`}
                      error={Boolean(state.equRErrors[index])}
                      helperText={state.equRErrors[index] ? "Please enter remark!" : ""}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      color="secondary"
                      className="mt-4"
                      onClick={() =>{
                        if(numberOfEquipment === 1){
                          Swal.fire({
                            title: "Info",
                            icon: "info",
                            text: "Add at least one Equipment!"
                          })
                        }else{
                          Swal.fire({
                            title: 'Are you sure?',
                            text: "You won't be able to revert this!",
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Yes, delete it!'
                          }).then((result) => {
                            if (result.isConfirmed) {
                              Swal.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                              )
                              handleEquipmentDeleteCount(index)
                            }
                          })
                        }
                      }
                      }
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                  {numberOfEquipment - 1 === index ? (
                    <Grid item xs={1}>
                      <IconButton
                        color="primary"
                        className="mt-4"
                        onClick={() => handleEquipmentAddCount(index)}
                      >
                        <AddCircleOutline />
                      </IconButton>
                    </Grid>
                  ) : (
                    <Grid item xs={1}></Grid>
                  )}
                </Grid>
              );
            })}
            <div style={{textAlign:'center'}}>
            <br/>
              <Divider/><h6 style={{padding:'10px 0', margin:'0px'}}>* Labors *</h6><Divider/>
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={applylabval}
                  onChange={handlelabval}
                  color="primary"
                />
              }
              label="Add Labor"
            />
            {_.times(numberOfManpower, index => {
              let indexValue = index + 1;
              return (
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormControl className="mt-1" fullWidth error={state.labLErrors[index]}>
                      <InputLabel id="labor-label" required>
                        Labor
                      </InputLabel>
                      <Select
                        labelId="labor-label"
                        value={
                          typeof manpower[index] === "object" &&
                          manpower[index] !== null
                            ? manpower[index].labor
                            : ""
                        }
                        onChange={e => {
                          const unit = e.target.value.split(";")[1];
                          let tempArr = [...manpower];
                          if (tempArr[index] && "number" in tempArr[index]) {
                            if ("reqnumber" in tempArr[index])
                              tempArr[index] = {
                                labor: e.target.value,
                                number: tempArr[index].number,
                                reqnumber: tempArr[index].reqnumber,
                                unit: unit,
                              };
                            else
                              tempArr[index] = {
                                labor: e.target.value,
                                number: tempArr[index].number,
                                reqnumber: "",
                                unit: unit,
                              };
                          } else {
                            tempArr[index] = {
                              labor: e.target.value,
                              number: "",
                              reqnumber: "",
                              unit: unit,
                            };
                          }
                          setManpower(tempArr);
                        }}
                        id={`labL-${index}`}
                        error={Boolean(state.labLErrors[index])}
                      >
                        {manpowerData.map((Manpower, index) => (
                          <MenuItem
                            value={
                              Manpower.manpowerName +
                              ";" +
                              Manpower.manpowerUnit
                            }
                          >
                            {Manpower.manpowerName}
                          </MenuItem>
                        ))}
                      </Select>
                      {state.labLErrors[index] && (<p style={{color:"#f44336", fontSize:"0.75rem", marginTop:"4px"}}>Please select labour!</p>)}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      value={
                        typeof manpower[index] === "object" &&
                        manpower[index] !== null
                          ? manpower[index].number
                          : ""
                      }
                      margin="dense"
                      type="number"
                      variant="standard"
                      label="Actual Labors"
                      fullWidth
                      onChange={e => {
                        let tempArr = [...manpower];
                        if (tempArr[index] && "labor" in tempArr[index]) {
                          if ("reqnumber" in tempArr[index])
                            tempArr[index] = {
                              number: e.target.value,
                              reqnumber: tempArr[index].reqnumber,
                              labor: tempArr[index].labor,
                              unit: tempArr[index].unit,
                            };
                          else
                            tempArr[index] = {
                              number: e.target.value,
                              reqnumber: "",
                              labor: tempArr[index].labor,
                              unit: tempArr[index].unit,
                              supplier: tempArr[index].supplier,
                            };
                        } else {
                          tempArr[index] = {
                            number: e.target.value,
                            reqnumber: "",
                            labor: "",
                            unit: "",
                            supplier: "",
                          };
                        }
                        setManpower(tempArr);
                      }}
                      required
                      id={`labActual-${index}`}
                      error={Boolean(state.labAErrors[index])}
                      helperText={state.labAErrors[index] ? "Please enter actual labour!" : ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={
                        typeof manpower[index] === "object" &&
                        manpower[index] !== null
                          ? manpower[index].reqnumber
                          : ""
                      }
                      margin="dense"
                      type="number"
                      variant="standard"
                      label="Requried Labors"
                      fullWidth
                      onChange={e => {
                        let tempArr = [...manpower];
                        if (tempArr[index] && "labor" in tempArr[index]) {
                          if ("number" in tempArr[index])
                            tempArr[index] = {
                              number: tempArr[index].number,
                              reqnumber: e.target.value,
                              labor: tempArr[index].labor,
                              unit: tempArr[index].unit,
                            };
                          else
                            tempArr[index] = {
                              reqnumber: e.target.value,
                              number: "",
                              labor: tempArr[index].labor,
                              unit: tempArr[index].unit,
                              supplier: tempArr[index].supplier,
                            };
                        } else {
                          tempArr[index] = {
                            reqnumber: e.target.value,
                            number: "",
                            labor: "",
                            unit: "",
                            supplier: "",
                          };
                        }
                        setManpower(tempArr);
                      }}
                      required
                      id={`labRequired-${index}`}
                      error={Boolean(state.labRErrors[index])}
                      helperText={state.labRErrors[index] ? "Please enter required labour!" : ""}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      value={
                        typeof manpower[index] === "object" &&
                        manpower[index] !== null
                          ? manpower[index].unit
                          : ""
                      }
                      margin="dense"
                      type="text"
                      variant="standard"
                      label="Units"
                      fullWidth
                      disabled
                      onChange={e => {
                        let tempArr = [...manpower];
                        if (tempArr[index] && "labor" in tempArr[index]) {
                          tempArr[index] = {
                            number: tempArr[index].number,
                            labor: tempArr[index].labor,
                            unit: e.target.value,
                          };
                        } else {
                          tempArr[index] = {
                            number: "",
                            labor: "",
                            unit: e.target.value,
                          };
                        }
                        setManpower(tempArr);
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      color="secondary"
                      className="mt-4"
                      onClick={() =>{
                        if(numberOfManpower === 1){
                          Swal.fire({
                            title: "Info",
                            icon: "info",
                            text: "Add at least one Labour!"
                          })
                        }else{
                          Swal.fire({
                            title: 'Are you sure?',
                            text: "You won't be able to revert this!",
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Yes, delete it!'
                          }).then((result) => {
                            if (result.isConfirmed) {
                              Swal.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                              )
                              handleManpowerDeleteCount(index)
                            }
                          })
                        }
                      }
                      }
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                  {numberOfManpower - 1 === index ? (
                    <Grid item xs={1}>
                      <IconButton
                        color="primary"
                        className="mt-4"
                        onClick={() => handleManpowerAddCount(index)}
                      >
                        <AddCircleOutline />
                      </IconButton>
                    </Grid>
                  ) : (
                    <Grid item xs={1}></Grid>
                  )}
                </Grid>
              );
            })}
            <div style={{textAlign:'center'}}>
              <br/>
              <Divider/><h6 style={{padding:'10px 0', margin:'0px'}}>* Miscellaneous *</h6><Divider/>
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={applymisval}
                  onChange={handlemiscval}
                  color="primary"
                />
              }
              label="Add Miscellaneous"
            />
            {_.times(numberOfMiscellaneous, index => {
              let indexValue = index + 1;
              return (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      labelId="miscellaneous-label"
                      value={
                        typeof miscellaneous[index] === "object" &&
                        miscellaneous[index] !== null
                          ? miscellaneous[index].miscellaneous
                          : ""
                      }
                      margin="dense"
                      type="number"
                      variant="standard"
                      label="Miscellaneous Cost"
                      fullWidth
                      onChange={e => {
                        let tempArr = [...miscellaneous];
                        if (tempArr[index] && "reason" in tempArr[index] && "remark" in tempArr[index]) {
                          tempArr[index] = {
                            miscellaneous: e.target.value,
                            reason: tempArr[index].reason,
                            remark: tempArr[index].remark,
                          };
                        } else {
                          tempArr[index] = {
                            miscellaneous: e.target.value,
                            reason: "",
                            remark: "",
                          };
                        }
                        setMiscellaneous(tempArr);
                      }}
                      id={`misCost-${index}`}
                      error={Boolean(state.misCErrors[index])}
                      helperText={state.misCErrors[index] ? "Please enter miscellaneous cost!" : ""}
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      value={
                        typeof miscellaneous[index] === "object" &&
                        miscellaneous[index] !== null
                          ? miscellaneous[index].reason
                          : ""
                      }
                      margin="dense"
                      type="text"
                      variant="standard"
                      label="Reason"
                      fullWidth
                      onChange={e => {
                        let tempArr = [...miscellaneous];
                        if (
                          tempArr[index] &&
                          "miscellaneous" in tempArr[index] && "remark" in tempArr[index]
                        ) {
                          tempArr[index] = {
                            reason: e.target.value,
                            miscellaneous: tempArr[index].miscellaneous,
                            remark: tempArr[index].remark,
                          };
                        } else {
                          tempArr[index] = {
                            reason: e.target.value,
                            miscellaneous: "",
                            remark: "",
                          };
                        }
                        setMiscellaneous(tempArr);
                      }}
                      required
                      id={`misReason-${index}`}
                      error={Boolean(state.misRErrors[index])}
                      helperText={state.misRErrors[index] ? "Please enter reason!" : ""}
                    />
                  </Grid>

                  <Grid item xs={1}>
                    <IconButton
                      color="secondary"
                      className="mt-4"
                      onClick={() =>
                        Swal.fire({
                          title: 'Are you sure?',
                          text: "You won't be able to revert this!",
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonColor: '#3085d6',
                          cancelButtonColor: '#d33',
                          confirmButtonText: 'Yes, delete it!'
                        }).then((result) => {
                          if (result.isConfirmed) {
                            Swal.fire(
                              'Deleted!',
                              'Your file has been deleted.',
                              'success'
                            )
                            handleMiscellaneousDeleteCount(index)
                          }
                        })
                        }
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                  {numberOfMiscellaneous - 1 === index ? (
                    <Grid item xs={1}>
                      <IconButton
                        color="primary"
                        className="mt-4"
                        onClick={() => handleMiscellaneousAddCount(index)}
                      >
                        <AddCircleOutline />
                      </IconButton>
                    </Grid>
                  ) : (
                    <Grid item xs={1}></Grid>
                  )}
                  <TextField
                    value={
                      typeof miscellaneous[index] === "object" &&
                      miscellaneous[index] !== null
                        ? miscellaneous[index].remark
                        : ""
                    }
                    margin="dense"
                    type="text"
                    variant="standard"
                    label="Remark"
                    fullWidth
                    onChange={e => {
                      let tempArr = [...miscellaneous];
                      if (tempArr[index] && "miscellaneous" in tempArr[index] && "reason" in tempArr[index]) {
                        tempArr[index] = {
                          remark: e.target.value,
                          miscellaneous: tempArr[index].miscellaneous,
                          reason: tempArr[index].reason,
                        };
                      } else {
                        tempArr[index] = {
                          remark: e.target.value,
                          miscellaneous: "",
                          reason: "",
                        };
                      }
                      setMiscellaneous(tempArr);
                    }}
                    required
                    id={`misRemark-${index}`}
                    error={Boolean(state.misRRErrors[index])}
                    helperText={state.misRRErrors[index] ? "Please enter remark!" : ""}
                  />
                </Grid>
              );
            })}
          </form>
        </DialogContent>
        <DialogActions style={{ marginBottom: "1.5em", marginRight: "1em" }}>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button color="primary" onClick={addButtonHandler}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {!loading && (
        <>
          {clicked === null ? (
            <div className={classes.root}>
              {Object.keys(dpr).length === 0 ? (
                <>
                  <Grid item container justify="flex-end" xs={12} sm={12} md={12} lg={12}>
                    <Button
                      style={{ margin: "1rem 1rem" }}
                      onClick={handleClick}
                      startIcon={<AddIcon />}
                    >
                      Add DPR
                    </Button>
                  </Grid>

                  <Typography
                    className="mt-5 d-flex justify-content-center align-items-center"
                    variant="h6"
                    color="textSecondary"
                    style={{ textAlign: "center" }}
                  >
                    No DPRs Available!
                  </Typography>
                </>
              ) : (
                <>
                  <Grid item container justify="flex-end" xs={12} sm={12} md={12} lg={12}>
                    <Button
                      style={{ margin: "1rem 1rem" }}
                      onClick={handleClick}
                      startIcon={<AddIcon />}
                    >
                      Add DPR
                    </Button>
                  </Grid>
                  {dates.map((elem, key) => (
                    <Grid container justify="center" key={key}>
                      <Grid item xs={11} sm={11} md={6} lg={6}>
                        <ProgressCard
                          heading={elem}
                          handleCardClick={handleCardClick}
                        />
                      </Grid>
                    </Grid>
                  ))}
                </>
              )}
            </div>
          ) : (
            <DailyProgressCards
              dprs={dpr[clicked]}
              clicked={clicked}
              handleBackButtonClick={handleBackButtonClick}
              handleDelete={handleDelete}
            />
          )}
        </>
      )}
    </>
  );
};
export default DailyProgressReport;