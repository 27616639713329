import React, { Component } from "react";
import { Button, Grid } from "@material-ui/core";
import Swal from 'sweetalert2';
import EditMaterial from "./EditMaterial";
import AddMaterial from "./AddMaterial";
// import { blue } from "@material-ui/core/colors";
import { Add } from "@material-ui/icons";
import Style from "./style.module.scss";
import PropTypes from "prop-types";
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import {
  addMaterial,
  getMaterial,
  updateMaterial,
  deleteMaterial,
  getStore,
} from "../../Redux/materialRedux/materialAction";
import Notifications from "react-notification-system-redux";
import axios from "axios";
import {
  ArrowForwardIos as ArrowForwardIosIcon,
  Add as AddIcon,
  Edit,
  Delete,
} from "@material-ui/icons";
// import Typography from "material-ui/styles/typography";
// import { each } from "lodash";

class Display extends Component {
  constructor(props) {
    super(props);
    const role = global.config.secureStorage.getItem("role");
    const editRoles = ["purchase_manager", "project_manager", "super_admin"];
    const isEditable = editRoles.find(item => item == role) ? true : false;
    this.state = {
      materialName: "",
      materialLimit: "",
      materialQuantity: "",
      materialRate: "",
      materialUnit: "",
      materialAmount: "",
      editMaterialId: "",
      activity: "",
      allMaterials: [],
      storeData: [],
      isCreateNewMaterial: false,
      isModifyMaterial: false,
      isEditable: isEditable,
      libraryData: [],
      isEditMaterial: false,
      editId: "",
      activities: [],
      subAccName: "",
      taskList: [],
      taskName: "",
      activityIndex: -1,
      subAccIndex: -1,
    };
  }

  handleMaterialChange = event => {
    this.setState({ materialName: event.target.value });
  };
  async getLibraryData() {
    let projectId = await global.config.secureStorage.getItem("projectId");
    try {
      const res = await axios.post(
        `${global.config.backendURL}/library/getLibrary`,
        {
          projectId: projectId,
        }
      );
      this.setState({ libraryData: res.data });
      console.log("librryData..", this.state.libraryData);
    } catch (err) {
      console.log(err);
    }
  }
  async componentDidMount() {
    await this.getLibraryData();
    console.log(this.state.libraryData);
    await this.props.getMaterial();
    await this.props.getStore();
    this.getActivities()
    if (!this.props.loading) {
      this.setState({
        allMaterials: this.props.material.data,
        storeData: this.props.material.storeData,
      });
    }
  }

  deleteMaterial = async id => {
    let singleMaterial = [...this.state.libraryData];
    singleMaterial = singleMaterial[id];
    try {
      const res = await axios.post(
        `${global.config.backendURL}/library/deleteMaterial/${singleMaterial._id}`
      );
      let tempData = this.state.libraryData;
      tempData.splice(id, 1);
      console.log(tempData);
      this.setState({
        materialName: "",
        materialQuantity: "",
        materialRate: "",
        materialUnit: "",
        materialAmount: "",
        activity: "",
        libraryData: tempData,
      });
    } catch (err) {
      console.log(err);
    }
  };
  toggleeditmodal=()=>{
    this.setState({
      isEditMaterial: !this.state.isEditMaterial
    })
  }

  toggleEditMaterial = tableMeta => {
    let singleMaterial = [...this.state.libraryData];
    singleMaterial = singleMaterial[tableMeta.rowIndex];
    console.log("rowIndex" + tableMeta.rowIndex);
    console.log(singleMaterial);
    let new_task=[];
    let acc_index;
    let task_index;
    let subacc_index;
    console.log("this state activites is ",this.state.activities,singleMaterial)
    this.state.activities.forEach( async (acc,accIndex) =>{
      console.log("equaliazing them ",acc.title,singleMaterial.activity)
            if(acc.title === singleMaterial.activity)
            {
              console.log("acc ndiex is ",accIndex)
              acc_index=accIndex
              const subprojectId = global.config.secureStorage.getItem("subprojectId");
                 acc.content.forEach(async  (subacc,subIndex)=>{
                  if(subacc.subTitle=== singleMaterial.subactivity)
                  {
                    console.log("indexes are",subIndex)
                    subacc_index=subIndex
                    const res = await axios.get(
                      `${global.config.backendURL}/activities/getActivity?activity=${subacc.link}&subprojectId=${subprojectId}`
                    );
                    let new_taskCost;
                    res.data.forEach((task,taskindex)=>{
                      if(singleMaterial.taskName===task.name)
                      {
                        new_taskCost=task.taskCost
                        task_index=taskindex
                        return
                      }
                    })
                    this.setState({
                      taskList:res.data,
                      taskName:  singleMaterial.taskName + ";" + task_index,
                      taskCost:new_taskCost,
                      activityIndex:acc_index,
                      subAccIndex:subacc_index,
                    })
                  }
                 })
            }
    })
    console.log("single materias is ",singleMaterial,tableMeta.rowIndex)
    if (!this.state.isEditMaterial) {
      this.setState({
        editId: singleMaterial._id,
        materialName: singleMaterial.materialName,
        materialQuantity: singleMaterial.materialQuantity,
        materialRate: singleMaterial.materialRate,
        materialAmount:
          singleMaterial.materialRate * singleMaterial.materialQuantity,
        materialUnit: singleMaterial.materialUnit,
        activity: singleMaterial.activity + ";" + acc_index,
        subAccName: singleMaterial.subactivity + ";" + subacc_index,
        activityIndex:acc_index,
        subAccIndex:subacc_index,
        isEditMaterial: !this.state.isEditMaterial,
      });
    }
  };

  handleChange = (event, value = null) => {
    if (value === null) {
      this.setState({
        [event.target.name]: event.target.value,
      });
    } else {
      this.setState({
        materialName: value,
      });
    }
  };
  handleMaterialChange = event => {
    this.setState({ materialName: event.target.value });
  };
  handleTabChange= () =>{
    this.setState({
         taskName:"",
         taskList:"",
         subAccName:"",
         subAccIndex:""
    })
  }
  handleActivity = event => {
    this.setState({
      activity: event.target.value,
      activityIndex: event.target.value.split(";")[1],
      taskCost:"",
      taskName:"",
      taskList:"",
      subAccName:"",
      subAccIndex:""
    });
  };
  handleSubActivity = async event => {
    let new_task;

    try {
      let index = event.target.value.split(";")[1];
      let subAccLink =
        this.state.activities[this.state.activityIndex].content[index].link;
      const subprojectId = global.config.secureStorage.getItem("subprojectId");
      const res = await axios.get(
        `${global.config.backendURL}/activities/getActivity?activity=${subAccLink}&subprojectId=${subprojectId}`
      );
      new_task = res.data;
    } catch (err) {
      console.log("err is ", err);
    }
    this.setState({
      subAccName: event.target.value,
      subAccIndex: event.target.value.split(";")[1],
      taskList: new_task,
      taskName:"",
      taskCost:""
    });
  };
  handleTaskChange = event => {
    let index = event.target.value.split(";")[1];
    this.setState({
      taskName: event.target.value,
      taskCost: this.state.taskList[index].taskCost,
    });
  };
  getActivities = async () => {
    let projectId = await global.config.secureStorage.getItem("projectId");
    const response = await axios.get(
      `${global.config.backendURL}/activities/getDefaultActivities/${projectId}`
    );
    // if(projectType === "Infrastructure"){
    //   const mainActivities = response.data.data.map((mainAct,index)=>{
    //     console.log(mainAct)
    //     return {
    //       id: index,
    //       title : maisnAct.name,
    //       activities : mainAct.data
    //     };
    //   });
    //   setMainActivities(mainActivities);
    // }
    // else{
    //   setActivities(response.data.data[0].data);
    // }
    let final = [];
    response.data.data.forEach(element => {
      if (element.visibility) final.push(element);
    });
    this.setState({
      activities: final,
    });
  };
  // handleMainActivity = event => {
  //   console.log(event)
  //   this.setState({ mainActivity: event.target.value});
  // };
  toggleCreateNewMaterial = () => {
    this.setState({
      subAccName: "",
      taskList: [],
      taskName: "",
      taskCost: "",
      activityIndex: -1,
      subAccIndex: -1,
      materialName: "",
      materialQuantity: "",
      materialRate: "",
      materialAmount: "",
      materialUnit: "",
      activity: "",
      isCreateNewMaterial: !this.state.isCreateNewMaterial,
    });
  };

  updateMaterial = async event => {
    event.preventDefault();

    let projectId = await global.config.secureStorage.getItem("projectId");

    const finalEquipment = {
      projectId,
      materialName: this.state.materialName,
      materialQuantity: this.state.materialQuantity,
      materialRate: this.state.materialRate,
      materialAmount: this.state.materialRate * this.state.materialQuantity,
      materialUnit: this.state.materialUnit,
      activity: this.state.activity.split(";")[0],
      _id: this.state.editId,
      subactivity: this.state.subAccName.split(";")[0],
      taskName: this.state.taskName.split(";")[0]
    };

    // const num = Number(this.state.libraryData);
    let tempData = [...this.state.libraryData];
    if (this.state.editId != undefined) {
      try {
        const res = await axios.post(
          `${global.config.backendURL}/library/editLibrary/${this.state.editId}`,
          {
            ...finalEquipment,
          }
        );
      } catch (err) {
        console.log(err);
      }
      let objIndex = tempData.findIndex(obj => obj._id == this.state.editId);
      tempData[objIndex] = finalEquipment;

      this.setState({
        editId: "",
        materialName: "",
        materialQuantity: "",
        materialRate: "",
        materialUnit: "",
        materialAmount: "",
        activity: "",
        isEditMaterial: !this.state.isEditMaterial,
        libraryData: tempData,
        subactivity: "",
        taskName: "",
        taskList:"",
      });
    }
  };

  saveMaterial = async event => {
    event.preventDefault();
    let projectId = await global.config.secureStorage.getItem("projectId");
    const finalMaterial = {
      projectId,
      materialName: this.state.materialName,
      materialQuantity: this.state.materialQuantity,
      materialRate: this.state.materialRate,
      materialAmount: this.state.materialRate * this.state.materialQuantity,
      materialUnit: this.state.materialUnit,
      activity: this.state.activity.split(";")[0],
      subactivity: this.state.subAccName.split(";")[0],
      taskName: this.state.taskName.split(";")[0],
      taskCost: this.state.taskCost,
    };
    try {
      const res = await axios.post(
        `${global.config.backendURL}/library/addToLibrary`,
        {
          ...finalMaterial,
        }
      );
    } catch (err) {
      console.log(err);
    }
    let tempData = [...this.state.libraryData, finalMaterial];
    console.log(tempData);
    this.setState({
      materialName: "",
      materialQuantity: "",
      materialRate: "",
      materialUnit: "",
      materialAmount: "",
      activity: "",
      isCreateNewMaterial: !this.state.isCreateNewMaterial,
      libraryData: tempData,
      subactivity: "",
        taskList:"",
        taskName: "",
        taskCost: "",
    });
    window.location.reload();
  };
  render() {

    const columns = [
      {
        name: "Serial Number",
        label: "SN",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return tableMeta.rowIndex + 1;
          }
        },
      },
      {
        name: "materialName",
        label: "Material",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "activity",
        label: "Activity",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "subactivity",
        label: "Sub activity",
        options: {
          filter: true,
          sort: true,
          display: this.state.libraryData.some(item => item.subactivity) ? "true" : "false",
        },
      },
      {
        name: "taskName",
        label: "Task name",
        options: {
          filter: true,
          sort: true,
          display: this.state.libraryData.some(item => item.taskName) ? "true" : "false",
        },
      },
      {
        name: "materialQuantity",
        label: "Quantity",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "materialUnit",
        label: "Unit",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "materialRate",
        label: "Rate",
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "materialAmount",
        label: "Amount",
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "Edit",
        options: {
          customBodyRender: (value, tableMeta) => {
            return (
              <button
                onClick={() => {
                  this.toggleEditMaterial(tableMeta);
                }}
                style={{
                  background:"#345BAE",
                  color:'white',
                  border:"1px solid transparent",
                  borderRadius: "3px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Edit  style={{width: ".65em", height:".65em", marginRight:"2px"}} />
                Edit
              </button>
            );
          },
        },
      },
      {
        name: "Delete",
        options: {
          customBodyRender: (value, tableMeta) => {
            return (
              <button onClick={() => 
                Swal.fire({
                  title: 'Are you sure?',
                  text: "You won't be able to revert this!",
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                  if (result.isConfirmed) {
                    Swal.fire(
                      'Deleted!',
                      'Your file has been deleted.',
                      'success',
                    )
                    this.deleteMaterial(tableMeta.rowIndex)
                  }
                })
                }
                style={{
                  background:"#F24236",
                  color:'white',
                  border:"1px solid transparent",
                  borderRadius: "3px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
              <Delete  style={{width: ".65em", height:".65em", marginRight:"2px"}} />
              Delete
              </button>
            );
          },
        },
      },
    ];

    const { isCreateNewMaterial, isEditMaterial } = this.state;
    if (isCreateNewMaterial) {
      return (
        <AddMaterial
          storeData={this.props.storeData}
          handleChange={this.handleChange}
          materialName={this.state.materialName}
          materialQuantity={this.state.materialQuantity}
          materialRate={this.state.materialRate}
          materialUnit={this.state.materialUnit}
          materialAmount={this.state.materialAmount}
          activity={this.state.activity}
          saveMaterial={this.saveMaterial}
          toggleCreateNewMaterial={this.toggleCreateNewMaterial}
          handleActivity={this.handleActivity}
          handleMaterialChange={this.handleMaterialChange}
          activityIndex={this.state.activityIndex}
          handleSubActivity={this.handleSubActivity}
          activities={this.state.activities}
          taskList={this.state.taskList}
          taskName={this.state.taskName}
          handleTaskChange={this.handleTaskChange}
          subAccName={this.state.subAccName}
          taskCost={this.state.taskCost}
          handleTabChange={this.handleTabChange}
        />
      );
    }
    if (isEditMaterial) {
      return (
        <EditMaterial
          storeData={this.props.storeData}
          handleChange={this.handleChange}
          materialName={this.state.materialName}
          materialQuantity={this.state.materialQuantity}
          materialRate={this.state.materialRate}
          materialUnit={this.state.materialUnit}
          materialAmount={this.state.materialAmount}
          activity={this.state.activity}
          updateMaterial={this.updateMaterial}
          toggleEditMaterial={this.toggleEditMaterial}
          toggleeditmodal={this.toggleeditmodal}
          handleActivity={this.handleActivity}
          handleMaterialChange={this.handleMaterialChange}
          activityIndex={this.state.activityIndex}
          handleSubActivity={this.handleSubActivity}
          activities={this.state.activities}
          taskList={this.state.taskList}
          taskName={this.state.taskName}
          handleTaskChange={this.handleTaskChange}
          subAccName={this.state.subAccName}
          taskCost={this.state.taskCost}
          handleTabChange={this.handleTabChange}
        />
      );
    }
    return (
      <div>
        {this.props.notifications && (
          <Notifications notifications={this.props.notifications} />
        )}
        {this.state.isEditable && (
          <section className={Style.button__display}>
            <Button
              onClick={this.toggleCreateNewMaterial}
              startIcon={<Add />}
              size="large"
            >
              Add Material
            </Button>
          </section>
        )}
        <Grid container justify="center">
          <Grid item style={{padding : "10px"}}>
            <MUIDataTable
              title={`Material`}
              data={this.state.libraryData}
              columns={columns}
              options={{
                selectableRows: "none",
                responsive: "verticle",
                pagination: false,
                download: false,
                viewColumns: false,
                filter: true,
                print: false,
              }}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

Display.propTypes = {
  material: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  //
  return {
    material: state.materialReducer,
    notifications: state.notifications,
    success: state.materialReducer.success,
    loading: state.materialReducer.loading,
    data: state.materialReducer.data,
    storeData: state.materialReducer.storeData,
  };
};

export default connect(mapStateToProps, {
  addMaterial,
  getMaterial,
  updateMaterial,
  deleteMaterial,
  getStore,
})(Display);