import "date-fns";
import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from "@material-ui/core";

import Style from "./style.module.scss";

const MaterialReport = props => {
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);

  return (
    <div className="container">
      <Grid container className={Style.header___top}>
        <Typography
          variant="h4"
          color="textSecondary"
          style={{ marginLeft: "0.5em", color: "#fff" }}
        >
          Enbuild
        </Typography>
      </Grid>
      <Grid container className={Style.container___info}>
        <Grid item xs={4}>
          <Typography
            variant="h6"
            color="textSecondary"
            style={{ marginLeft: "0.5em" }}
          >
            Generated By
          </Typography>
          <Typography variant="h6" style={{ marginLeft: "0.5em" }}>
            {global.config.secureStorage.getItem("user_fullname")}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            variant="h6"
            color="textSecondary"
            style={{ marginLeft: "0.5em" }}
          >
            Generated On
          </Typography>
          <Typography variant="h6" style={{ marginLeft: "0.5em" }}>
            {new Date(new Date()).toISOString().split("T")[0]}
          </Typography>
        </Grid>
      </Grid>

      <div className={Style.table___box}>
        {props.materialDetails.map((elem, id) => {
          if (elem !== null) {
            return (
              <div className={Style.single___box}>
                <Grid container className={Style.single___container}>
                  <Accordion key={id} defaultExpanded={true} style={{width:'100%'}}>
                    <AccordionSummary>
                      <Grid
                        sm={12}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "10px",
                        }}
                      >
                        <Typography variant="p">
                          Material Requision Name:{" - "}
                        </Typography>
                        <Typography variant="h5">
                          <span>{elem.materialReqName}</span>
                        </Typography>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails className={Style.table___detail}>
                      <Grid style={{ margin: "20px 0",overflowX: 'auto'}}>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>Material Requision Date</TableCell>
                              <TableCell>Expected Date</TableCell>
                              <TableCell>PO Date</TableCell>
                              <TableCell>Recevied Date</TableCell>
                              <TableCell>Delivered Date</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <Grid
                                  item
                                  xs={12}
                                  style={{ marginTop: "10px" }}
                                >
                                  {elem.materialReqDate}
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <Grid
                                  item
                                  xs={12}
                                  style={{ marginTop: "10px" }}
                                >
                                  {elem.expectedDate}
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <Grid
                                  item
                                  xs={12}
                                  style={{ marginTop: "10px" }}
                                >
                                  {elem.approvalHistory[0].approvalDate}
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <Grid
                                  item
                                  xs={12}
                                  style={{ marginTop: "10px" }}
                                >
                                  {elem.approvalHistory[1].approvalDate}
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <Grid
                                  item
                                  xs={12}
                                  style={{ marginTop: "10px" }}
                                >
                                  {elem.approvalHistory[2].approvalDate}
                                </Grid>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>
                     
                        <Typography variant="h5">Materials</Typography>
                        {elem.data.map((materials, id2) => {
                          return materials !== null ? (
                            <>
                              <Grid sm={12} style={{marginTop:'20px'}}>
                                <Typography variant="h6">
                                  <span> {materials.materialName}</span>
                                </Typography>
                              </Grid>

                              <TableContainer>
                                <Table size="small">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>material Quantity</TableCell>
                                      <TableCell>material Unit</TableCell>
                                      <TableCell>PO quanity</TableCell>
                                      <TableCell>Recevied quanity</TableCell>
                                      <TableCell>Delivered quanity</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell>
                                        <Grid
                                          item
                                          xs={12}
                                          style={{ marginTop: "10px" }}
                                        >
                                          {materials.materialQuantity}
                                        </Grid>
                                      </TableCell>
                                      <TableCell>
                                        <Grid
                                          item
                                          xs={12}
                                          style={{ marginTop: "10px" }}
                                        >
                                          {materials.materialUnit}
                                        </Grid>
                                      </TableCell>
                                      <TableCell>
                                        <Grid
                                          item
                                          xs={12}
                                          style={{ marginTop: "10px" }}
                                        >
                                          {
                                            elem.approvalHistory[0]
                                            .quantityData[id2] ?
                                            elem.approvalHistory[0]
                                              .quantityData[id2].quantity
                                              :""
                                          }
                                        </Grid>
                                      </TableCell>
                                      <TableCell>
                                        <Grid
                                          item
                                          xs={12}
                                          style={{ marginTop: "10px" }}
                                        >
                                          {
                                            elem.approvalHistory[1]
                                              .quantityData[id2].quantity
                                          }
                                        </Grid>
                                      </TableCell>
                                      <TableCell>
                                        <Grid
                                          item
                                          xs={12}
                                          style={{ marginTop: "10px" }}
                                        >
                                          {
                                            elem.approvalHistory[2]
                                              .quantityData[id2].quantity
                                          }
                                        </Grid>
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </>
                          ) : (
                            ""
                          );
                        })}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};
export default MaterialReport;
