
import React, { Component } from "react";
// import { Redirect } from "react-router-dom";
import Style from "./project.module.scss";
import axios from "axios";
import {
  Card,
  Typography,
  CardContent,
  // CardHeader,
  Button,
} from "@material-ui/core";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import { ExpandMore, ExpandLess, Edit } from "@material-ui/icons";

import * as action from "../../Redux/projectRedux/projectAction";
import Notifications from "react-notification-system-redux";

class QuickList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }
  goToEditProject = () => {
    global.config.secureStorage.setItem("projectId", this.props.project._id);
    this.props.history.push("/editProject");
  };
  cardClicked = async () => {
    this.props.setLoading(true);
    global.config.secureStorage.setItem("projectId", this.props.project._id);
    global.config.secureStorage.setItem(
      "projectName",
      this.props.project.projectName
    );
    global.config.secureStorage.setItem(
      "projectName",
      this.props.project.projectName
    );
    global.config.secureStorage.setItem(
      "projectDescription",
      this.props.project.projectDescription
    );
    // global.config.secureStorage.setItem("subprojectId", this.props.project._id);
    // global.config.secureStorage.setItem(
    //   "subProjectName",
    //   this.props.project.projectName
    // );
    const username = global.config.secureStorage.getItem("username");
    global.config.secureStorage.setItem(
      "projectType",
      this.props.project.projectType
    );
    try {
      const response = await axios.get(
        `${global.config.backendURL}/users/getRoleByProjectId`,
        {
          params: {
            projectId: this.props.project._id,
            username: username,
          },
        }
      );
      this.props.setLoading(false);
      global.config.secureStorage.setItem("role", response.data);
      //

      // this.props.history.push("/subprojectList");
      window.location.reload()

    } catch (err) {
      console.log(err.message);
      this.props.setLoading(false);
    }
  };


  render() {
    return (
      <>
        {this.props.notifications && (
          <>
            <Notifications notifications={this.props.notifications} />
          </>
        )}
        <Card style={{boxShadow: "none"}}>
          <CardContent onClick={this.cardClicked} style={{padding: "10px", display: "flex", alignItems: "center"}}>
            <div className="first-letter">
              {this.props.project.projectName.split(" ").map(word => word.charAt(0).toUpperCase())}
            </div>
            <div>
              <div style={{ display:"flex",flexDirection: "row",justifyContent: "space-between",}}>
                <Typography variant="body1" color="primary" style={{ fontWeight: 700 }}>
                  {this.props.project.projectName}
                </Typography>
              </div>
              <Typography variant="p">
                {this.props.project.projectDescription}
              </Typography>
            </div>
          </CardContent>
        </Card>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    notifications: state.notifications,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteProject: projectId => dispatch(action.deleteProject(projectId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickList);
