import React, { Component } from "react";
import { Typography, Button, Grid } from "@material-ui/core";
import { Update } from "@material-ui/icons";
import { connect } from "react-redux";
import {
  getTaskActivityDetails,
  updateTaskActivityDetails,
  doneUpdating,
  getUpdateDetails,
  updateDetails,
} from "../../Redux/activityRedux/activityAction";
import SubTaskTable from "./SubTaskTable";
import EditSubTask from "./Tasks/EditSubTask";
import Loading from "../../Components/Loading";
import axios from "axios";

class SubTaskData extends Component {

  constructor(props) {
    super(props);
    const role = global.config.secureStorage.getItem("role");
    const roles = ["project_manager", "planning_manager", "senior_engineer", "chief_engineer", "junior_engineer", "super_admin"];
    const isAddVisible = roles.find(item => item == role) ? true : false;

    this.state = {
      modalOpen: false,
      subProject: {},
      loading: true,
      activityDetails: [],
      isUpdated: false,
      isUpdatedDetails: false,
      isAddVisible: isAddVisible,
      subTaskData: [],
      subTaskPanaroma: [],
      subTaskPercentage: 0,
      taskSubName: "",
      taskSubQuantity: "",
      taskSubUnit: "",
      activityUpdateDetails: [],
    };
  }

  async componentDidMount() {
    const id = global.config.secureStorage.getItem("subprojectId");
    const { activity, subactivity, floorNumber, subTaskIndex } =
      this.props.match.params;
    try {
      const subProjectDetails = await axios.get(
        `${global.config.backendURL}/project/getSingleSubProject/${id}`
      );
      // var array = Array(Number(subProjectDetails.data.flatNumber)).fill(false);
      this.setState({
        subProject: subProjectDetails.data,
      });
      if (subactivity === "null") {
        //
        await this.props.getTaskActivityDetails({
          activity: activity,
          subprojectId: id,
        });
      } else {
        await this.props.getTaskActivityDetails({
          activity: subactivity,
          subprojectId: id,
        });
      }
    } catch (error) {
      //
    }
  }

  async componentDidUpdate() {
    try {
      if (this.props.activityDetails != undefined && !this.state.isUpdated) {
        const path = this.props.match.params;
        let subtask =
          this.props.activityDetails[path.floorNumber].subTask[
          path.subTaskIndex
          ];
        await this.props.getUpdateDetails({
          randomid: subtask.randomid,
        });
        // this.setState({
        //   isUpdatedDetails: true,
        // });
        this.setState({
          loading: false,
          activityDetails: this.props.activityDetails,
          subTaskData: subtask,
          subTaskPercentage: subtask.percentage,
          subTaskPanaroma: subtask.panaroma,
          taskSubName: subtask.name,
          taskSubQuantity: subtask.quantity,
          taskSubUnit: subtask.unit,
          randomid: subtask.randomid,
          isUpdated: true,
        });
      }
      // console.log(this.state.activityUpdateDetails);
      if (
        this.state.activityUpdateDetails !== [] &&
        !this.state.isUpdatedDetails
      ) {
        const { floorNumber, subTaskIndex } = this.props.match.params;
        let subtask =
          this.props.activityDetails[floorNumber].subTask[subTaskIndex];
        if (this.props.activityUpdateDetails.randomid == subtask.randomid) {
          this.setState({
            activityUpdateDetails: this.props.activityUpdateDetails.data,
            isUpdatedDetails: true,
          });
        }
      }
    } catch (error) { }
  }

  // componentWillReceiveProps(nextProps) {
  //   if (this.props.activityDetails) {
  //     const { floorNumber, subTaskIndex } = this.props.match.params;
  //     let subtask =
  //       this.props.activityDetails[floorNumber].subTask[subTaskIndex];
  //     if (this.props.activityUpdateDetails) {
  //       if (this.props.activityUpdateDetails.randomid == subtask.randomid) {
  //         this.setState({
  //           activityUpdateDetails: this.props.activityUpdateDetails.data,
  //           // isUpdatedDetails: true,
  //         });
  //       }
  //     }
  //   }
  // }
  updateSubTask = async () => {
    const { activity, subactivity, floorNumber, subTaskIndex } =
      this.props.match.params;
    let arrayAcc = [...this.state.activityDetails];
    if (!this.state.subTaskPanaroma) {
      alert("Panaroma Not Selected OR Is Too Large");
      return false;
    }
    try {
      let subArr = [];
      subArr = arrayAcc[floorNumber].subTask;

      let allPercentages = 0;
      let noOfTasks = 0;
      //Check For taskActualStart
      subArr.forEach(s_task => {
        allPercentages = allPercentages + s_task.percentage;
        noOfTasks = noOfTasks + 1;
      });
      //Check Complete

      // All flat Percentage  + this flat update
      let estPercentage =
        allPercentages -
        arrayAcc[floorNumber].subTask[subTaskIndex].percentage +
        this.state.subTaskPercentage;

      let randomid = arrayAcc[floorNumber].subTask[subTaskIndex].randomid;
      let updatedPercentage =
        this.state.subTaskPercentage -
        arrayAcc[floorNumber].subTask[subTaskIndex].percentage;
      let now = new Date();
      await this.props.updateDetails({
        randomid,
        panaroma: this.state.subTaskPanaroma,
        updatedPercentage,
        user: global.config.secureStorage.getItem("username"),
        activityName: activity,
        date:
          now.getDate() +
          "/" +
          (parseInt(now.getMonth()) + 1).toString() +
          "/" +
          now.getFullYear(),
      });
      if (subArr != undefined) {
        subArr[subTaskIndex] = {
          name: this.state.taskSubName,
          quantity: this.state.taskSubQuantity,
          unit: this.state.taskSubUnit,
          percentage: this.state.subTaskPercentage,
          randomid,
        };
      }
      let taskActualStart = "";
      let taskActualEnd = "";

      if (
        allPercentages == 0 ||
        arrayAcc[floorNumber].taskActualStart == ''
      ) {
        taskActualStart = new Date();
      }
      if (arrayAcc[floorNumber].taskActualStart != '')
        taskActualStart = arrayAcc[floorNumber].taskActualStart;


      if (estPercentage / noOfTasks == 100) {
        taskActualEnd = new Date();
      } else {
        taskActualEnd = ''
      }
      console.log(allPercentages);
      console.log(taskActualEnd);

      arrayAcc[floorNumber].taskActualStart = taskActualStart;
      arrayAcc[floorNumber].taskActualEnd = taskActualEnd;

      arrayAcc[floorNumber].subTask = subArr;


      this.updateCondition(arrayAcc);
    } catch { }
  };

  updateCondition = arrayAcc => {
    const { activity, subactivity, floorNumber, subTaskIndex } =
      this.props.match.params;

    const subprojectId = global.config.secureStorage.getItem("subprojectId");

    if (subactivity === "null") {
      this.props.updateTaskActivityDetails({
        activity: activity,
        checkedArray: arrayAcc,
        subprojectId,
      });
    } else {
      this.props.updateTaskActivityDetails({
        activity: subactivity,
        checkedArray: arrayAcc,
        subprojectId,
      });
    }
    window.location.reload();
    this.setState({
      subTaskPanaroma: "",
      isUpdatedDetails: false,
    });
  };
  async reduce_image_file_size(base64Str, MAX_WIDTH = 500, MAX_HEIGHT = 500) {
    let resized_base64 = await new Promise(resolve => {
      let img = new Image();
      img.src = base64Str;
      img.onload = () => {
        let canvas = document.createElement("canvas");
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL()); // this will return base64 image results after resize
      };
    });
    return resized_base64;
  }
  calc_image_size(image) {
    let y = 1;
    if (image.endsWith("==")) {
      y = 2;
    }
    const x_size = image.length * (3 / 4) - y;
    return Math.round(x_size / 1024);
  }

  handlePanaroma = async file => {
    let resizedFile;
    let oldSize = this.calc_image_size(file);
    // console.log("new", oldSize);
    if (oldSize > 500) {
      resizedFile = await this.reduce_image_file_size(file);
    } else {
      resizedFile = file;
    }
    // console.log("new", this.calc_image_size(resizedFile));
    if (oldSize < 10000) {
      this.setState({
        subTaskPanaroma: resizedFile,
      });
      return false;
    }
  };
  addPercentage = (event, newValue) => {
    // console.log(event, newValue);
    if (newValue <= 100) {
      this.setState({
        subTaskPercentage: newValue,
      });
    }
  };

  goBack = () => {
    // this.props.history.goBack();
  };
  handleModalOpen = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
    });
  };
  handleTaskChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  submitTask = () => {
    const { activity, subactivity, floorNumber, subTaskIndex } =
      this.props.match.params;

    const subprojectId = global.config.secureStorage.getItem("subprojectId");
    let arrayAcc = [...this.state.activityDetails];

    let subArr = [];
    subArr = arrayAcc[floorNumber].subTask;
    if (subArr != undefined) {
      subArr[subTaskIndex] = {
        name: this.state.taskSubName,
        quantity: this.state.taskSubQuantity,
        unit: this.state.taskSubUnit,
        percentage: this.state.subTaskPercentage,
        panaroma: this.state.subTaskPanaroma,
        randomid: this.state.randomid,
      };
    }
    arrayAcc[floorNumber].subTask = subArr;
    this.updateCondition(arrayAcc);
    this.handleModalOpen();
    this.setState({
      isEdit: false,
      taskIndex: 0,
      activityDetails: [],
      isUpdated: false,
      taskSubName: "",
      taskSubQuantity: "",
      taskSubUnit: "",
    });
  };

  render() {
    // console.log(this.state.isUpdated);
    // console.log(this.state.activityUpdateDetails);
    // if (this.state.isUpdated == true) {
    //   if (this.props.isUpdateSuccess) {
    //     this.props.history.goBack();
    //   }
    // }
    // if (this.props.isUpdateSuccess) {
    //   console.log(this.state.isUpdated);
    //   // this.setState({
    //   //   isUpdated: false,
    //   // });
    //   return <></>;
    // }

    const { activity, subactivity, floorNumber, subTaskIndex } =
      this.props.match.params;
    if (this.state.loading) return <Loading loading={this.state.loading} />;
    return (
      <>

        {console.log(this.state.activityDetails)}
        <div className="Abreadcrumb">
          <Typography variant="h6" style={{ padding: "10px 20px" }}>
            Activities/{activity}/{subactivity}
            /{this.state.activityDetails[floorNumber].name}/{this.state.activityDetails[floorNumber].subTask[subTaskIndex].name}
          </Typography>
        </div>
        <div>
          <EditSubTask
            taskSubName={this.state.taskSubName}
            taskSubQuantity={this.state.taskSubQuantity}
            taskSubUnit={this.state.taskSubUnit}
            handleModalOpen={this.handleModalOpen}
            handleTaskChange={this.handleTaskChange}
            submitTask={this.submitTask}
            open={this.state.modalOpen}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "1.5em",
          }}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
            // spacing={2}
            style={{
              margin: "20px 0.3em 20px",
              width: "90%",
            }}
          >
            <Grid item>
              <Typography
                className="justify-content-center d-flex my-2"
                variant="h4"
              >
                {/* Flat List */}
              </Typography>
            </Grid>
            {/* {this.state.isAddVisible && (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Update />}
                  onClick={this.updateActivity}
                >
                  Update
                </Button>
              </Grid>
            )} */}
          </Grid>
          <div className="container">
            <SubTaskTable
              isAddVisible={this.state.isAddVisible}
              addPercentage={this.addPercentage}
              subPercentage={this.subPercentage}
              updateSubTask={this.updateSubTask}
              handleModalOpen={this.handleModalOpen}
              handlePanaroma={this.handlePanaroma}
              subTaskPanaroma={this.state.subTaskPanaroma}
              subTaskData={this.state.subTaskData}
              subTaskPercentage={this.state.subTaskPercentage}
              activityUpdateDetails={this.state.activityUpdateDetails}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    activityDetails: state.activityReducer.activityDetails,
    activityUpdateDetails: state.activityReducer.activityUpdateDetails,
    loading: state.activityReducer.loading,
    isUpdateSuccess: state.activityReducer.isUpdateSuccess,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTaskActivityDetails: data => dispatch(getTaskActivityDetails(data)),
    updateTaskActivityDetails: data =>
      dispatch(updateTaskActivityDetails(data)),
    getUpdateDetails: data => dispatch(getUpdateDetails(data)),
    updateDetails: data => dispatch(updateDetails(data)),
    doneUpdating: () => dispatch(doneUpdating()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubTaskData);
