import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Button,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { PhotoCamera, Check, Delete } from "@material-ui/icons";
import FileBase64 from "react-file-base64";

const useStyles = makeStyles(theme => ({
  innerRoot: {
    margin: "10px",
    marginBottom: "20px",
    padding: "10px",
    width:"31.855%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    // backgroundColor: "#ccc",
  },
  issueCard: {
    padding: "5px !important",
    paddingLeft: "10px !important",
    // padding: "10px !important",
  },
  widthfull: {
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
    },
  }
}));

export default function OurTable(props) {
  const classes = useStyles();
  const [nonApproved, setNonApproved] = useState([]);

  useEffect(() => {
    // console.log("ss");
    setNonApproved(() => {
      var temp = [];

      props.data.forEach(ele => {
        if (ele.status == "Approved") {
          return;
        }
        temp.push({
          ...ele,
          pics: (
            <Button
              style={{width:"100%"}}
              color="primary"
              type="button"
              startIcon={<PhotoCamera />}
              onClick={() => {
                let img =[]
                img.push(ele.image)
                props.setImages(img);
                props.handleOpen();
              }}
            >
              View Pictures
            </Button>
          ),
          action: (
            <>
            <Divider />
            <Grid item style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
              <Button
                style={{ width: "48%" }}
                color="primary"
                // startIcon={<Update />}
              >
                <FileBase64
                  multiple={false}
                  className="btn"
                  value={props.approveImage}
                  onDone={file => {
                    props.handelApproveImg(file.base64);
                    // files.forEach(ele => ele.base64);
                    // props.handlePanaroma(file.base64);
                  }}
                />
              </Button>
              <Button
                color="primary"
                style={{ width: "48%" }}
                startIcon={<Check />}
                disabled={ele.status == "Approved"}
                onClick={e => {
                  e.preventDefault();
                  if (props.approveImage !== "") {
                    const subprojectId =
                      global.config.secureStorage.getItem("subprojectId");
                    props.askApproval(
                      ele.issueId,
                      subprojectId,
                      props.type,
                      props.approveImage
                    );
                  } else {
                    alert("Please add a Image For Approval");
                  }
                  props.handelApproveImg("");
                }}
              >
                Approve
              </Button>
            </Grid>
            </>
          ),
        });
      });
      return temp;
    });
  }, [props.approveImage]);

  const [approved, setApproved] = useState(() => {
    var temp = [];

    props.data.forEach(ele => {
      if (ele.status == "pending") {
        return;
      }
      temp.push({
        ...ele,
        pics: (
          <Button
          style={{width:"100%"}}
            color="primary"
            type="button"
            startIcon={<PhotoCamera />}
            onClick={() => {
              let imagesWithAproval = [];
              imagesWithAproval.push(ele.image);
              imagesWithAproval.push(ele.approveImage);
              props.setImages(imagesWithAproval);
              props.handleOpen();
            }}
          >
            View Pictures
          </Button>
        ),
        action: (
          <Button
          style={{ width: "100%" }}
            color="secondary"
            startIcon={<Delete />}
            onClick={e => {
              const subprojectId =
                global.config.secureStorage.getItem("subprojectId");
              props.removeIssue(ele.issueId, subprojectId, props.type);
            }}
          >
            Delete
          </Button>
        ),
      });
    });
    return temp;
  });
  console.log("nonApproved...", nonApproved);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" style={{ marginLeft: "3%" }}>
          Non-Approved Issues
        </Typography>
        <Divider />
        <CardContent>
          {nonApproved.length == 0 && (
            <Grid>
              <Typography variant="h6" color="textSecondary">
                No issues available
              </Typography>
            </Grid>
          )}
          <Grid container>
            {nonApproved
              ? nonApproved.map((ele, index) => {
                  return (
                    <Card xs={4} key={index} className={classes.innerRoot}>
                      <CardHeader
                        className={classes.issueCard}
                        title={`Issue Id - ${ele.issueId}`}
                        titleTypographyProps={{ variant: "body1" }}
                        subheader={`Created on: ${ele.date}`}
                      />
                      <CardContent className={classes.issueCard}>
                        <Grid container item xs={12} className="mb-2">
                          <Grid item xs={12}>
                            <Typography variant="body2">
                              <strong>Section</strong> - {ele.section}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body2">
                              <strong>Priority</strong> - {ele.priority}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body2">
                              <strong>Location</strong> - {ele.location}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body2" wrap="true">
                            <strong> Description</strong> - {ele.description}
                            </Typography>
                          </Grid>

                         
                          {/* <Grid item xs={12}>
                          
                           {ele.assignUsers.length>0 && (
                            <>
                           <Typography variant="body2">
                           <strong> Assign To:</strong>
                              </Typography>
                              <div style={{display:'flex'}}>

                              {ele.assignUsers.map((eachUser, index) => {
                             return (
                               <Typography variant="body1">
                                {eachUser},
                               </Typography>
                                )
                              })}
                              </div>
                           </>
                          )}
                          </Grid> */}
                          {/* <Grid item xs={12}>
                          {ele.viewUsers.length>0 && (
                            <>
                            <Typography variant="body2">
                            <strong> View Only:</strong>
                               </Typography>
                               <div style={{display:'flex'}}>
                             {ele.viewUsers.map((eachUser, index) => {
                             return (
                               <Typography variant="body1">
                                {eachUser},
                               </Typography>
                                )
                              })}  </div>
                           </>
                          )}
                          </Grid> */}
                        
                        </Grid>

                        <Grid container spacing={2} direction="row" >
                          <Grid item style={{width:"100%"}}>{ele.pics}</Grid>
                          {ele.isEditable && (
                            <Grid style={{ padding: "8px" }} container>{ele.action}</Grid>
                          )}
                        </Grid>
                      </CardContent>
                    </Card>
                  );
                })
              : ""}
          </Grid>
        </CardContent>
        <Typography variant="h5" style={{ marginLeft: "3%" }}>
          Approved Issues
        </Typography>
        <Divider />
        <CardContent>
          {approved.length == 0 && (
            <Grid>
              <Typography variant="h6" color="textSecondary">
                No approved issues available
              </Typography>
            </Grid>
          )}

          <Grid container>
            {approved.map((ele, index) => {
              return (
                <Card key={index} className={classes.innerRoot} elevation={3}>
                  <CardHeader
                    className={classes.issueCard}
                    title={`Issue Id - ${ele.issueId}`}
                    titleTypographyProps={{ variant: "body1" }}
                    subheader={`Created on: ${ele.date}`}
                  />
                  <CardContent className={classes.issueCard}>
                    <Grid item xs={12}>
                      <Typography variant="body2">
                        <strong>Section</strong> - {ele.section}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">
                        <strong>Priority</strong> - {ele.priority}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">
                        <strong>Location</strong> - {ele.location}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" wrap="true">
                        <strong>Description</strong> - {ele.description}
                      </Typography>
                    </Grid>

                    <Grid container spacing={2} direction="row" style={{justifyContent:"space-between", alignItems:"center"}}>
                      <Grid className={classes.widthfull} item style={{width:"49%", padding:"6px 0px"}}>{ele.pics}</Grid>
                      {<Grid className={classes.widthfull} item style={{ width: "49%", padding:"6px 0px" }}>{ele.action}</Grid>}
                    </Grid>
                  </CardContent>
                </Card>
              );
            })}
          </Grid>
        </CardContent>
      </Grid>
    </>
  );
}